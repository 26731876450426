import React,{useState,useEffect} from "react"
import style from "./homePageBottomSlider.module.css"
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch,useSelector } from "react-redux";
import {getHomePageBottomSliderData,homePageBottomSliderDataDelete,sigleHomePageBottomSliderDataAction,blankDataAction,editBlankData} from "../../store/slices/homePageBottomSliderSlice"
import { Space, Tag } from 'antd';
import BigLoader from "../../components/bigLoader/BigLoader"
import AddImg from "../../assets/icons8-add-24.png"
import EditImg from "../../assets/editNew.svg"
import DeleteImg from "../../assets/icons8-delete-24 .png"
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import banner1 from "../../assets/images3.png"
import {mainUrl} from "../../utils/common"

const HomePageBottomSlider=()=>{
    const navigate=useNavigate()
    const dispatch=useDispatch();
    const data=useSelector((state)=>state.homePageBottomSlider.data)
    const loading=useSelector((state)=>state.homePageBottomSlider.loading)
    const deleteLoad=useSelector((state)=>state.homePageBottomSlider.deleteLoad)
    const [state,setState]=useState();
    const [deleteId,setDeleteId]=useState()

    useEffect(()=>{
        dispatch(getHomePageBottomSliderData())
    },[])
    useEffect(()=>{
       setState(data)
    },[data])
    
      const addHandler=()=>{
        dispatch(blankDataAction())
        dispatch(editBlankData())
        navigate('/home/home-page-bottom-slider/add-home-page-bottom-slider')
      }
      const editHandler=(record)=>{
        dispatch(sigleHomePageBottomSliderDataAction(record._id))
        navigate(`/home/home-page-bottom-slider/edit-home-page-bottom-slider/${record._id}`)
      }
      const deleteHandler=(record)=>{
          setDeleteId(record._id)
          dispatch(homePageBottomSliderDataDelete(record._id))
      }
    return(
        <div className={style.home_page_header_section}>
        <PageHeader name='home page bottom slider details' count={state?state.length:''} total={true} btnName={data?.length == 6?'':'add home page bottom slider'} img={AddImg} addHandler={addHandler}/>
        {loading?<BigLoader/>:
        <div className={style.home_page_header_container}>
          {state && state.map((item,index)=>{
            return <div className={style.home_page_header_data} style={{width:data?.length===1?'100%':data?.length===2?'45':'32%',height:data?.length===1 || data?.length===2 || data?.length===3?'450px':'220px'}}>
                   <div className={style.home_page_header_title}>title : <span>{item.title}</span></div>
                   <div className={style.home_page_header_image}>
                    <span>image</span><br/>
                    <img crossOrigin="anonymous" src={item ? `${mainUrl()}/${item.image}` : "-"} alt="image" style={{height:data?.length===1 || data?.length===2 || data?.length===3?'300px':'90px'}}/>
                   </div>
                   <div className={style.home_page_header_btn}>
                   <div className={style.delete_edit_hand_1} onClick={()=>editHandler(item)}>
                   <img src={EditImg} alt='edit image'/>
                   <div>edit</div>
                   </div>
                  <div className={style.delete_edit_hand} onClick={()=>deleteHandler(item)}>
                  {deleteLoad && item._id=== deleteId ? <ColorLoader/>:
                  <>
                  <img src={DeleteImg} alt='edit image'/>
                  <div>delete</div>
                   </>
                   }
                  </div>
                   </div>
                   </div>
          })}
        </div>
        }
        </div>
    )
}
export default HomePageBottomSlider;