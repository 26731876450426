import React from "react"
import style from "./revertLoader.module.css"

const RevertLoader=()=>{
    return(
        <>
        <div className={style.revert_custom_loader}></div>
        </>
    )
}
export default RevertLoader;