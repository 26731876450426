import { Action, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import thunk, { ThunkAction, ThunkMiddleware } from "redux-thunk";
import authSlice from "./slices/authSlice";
import usersSlice from "./slices/usersSlice";
import blogsSlice from "./slices/blogsSlice";
import dashboardSlice from "./slices/dashboardSlice";
import appointmentsSlice from "./slices/appointmentsSlice";
import testimonialsSlice from "./slices/testimonialsSlice"
import teamSlice from "./slices/teamSlice"
import designersSlice from "./slices/designersSlice"
import enquiresSlice from "./slices/enquiresSlice";
import collectionsSlice from "./slices/collectionsSlice"
import categorysSlice from "./slices/categorysSlice"
import productsSlice from "./slices/productsSlice"
import newslettersSlice from "./slices/newslettersSlice"
import discountsSlice from "./slices/discountsSlice"
import uspSlice from "./slices/uspSlice"
import settingsSlice from "./slices/settingsSlice"
import topHeaderSliderSlice from "./slices/topHeaderSliderSlice"
import homePageBottomSliderSlice from "./slices/homePageBottomSliderSlice"
import projectSliderSlice from "./slices/projectSliderSlice"
import homePageSlice from "./slices/homePageSlice"
import ordersSlice from "./slices/ordersSlice"
import uploadSlice from "./slices/uploadSlice"
import instoreSlice from "./slices/instoreSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user:usersSlice,
    blog:blogsSlice,
    dashboard:dashboardSlice,
    appointments:appointmentsSlice,
    testimonials:testimonialsSlice,
    team:teamSlice,
    designers:designersSlice,
    enquires:enquiresSlice,
    collections:collectionsSlice,
    categorys:categorysSlice,
    products:productsSlice,
    newsletters:newslettersSlice,
    discounts:discountsSlice,
    usp:uspSlice,
    settings:settingsSlice,
    topHeaderSlider:topHeaderSliderSlice,
    homePageBottomSlider:homePageBottomSliderSlice,
    projectSlider:projectSliderSlice,
    homePage:homePageSlice,
    orders:ordersSlice,
    upload:uploadSlice,
    instore:instoreSlice,
  },
  middleware: [...getDefaultMiddleware(), thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;