import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {collectionsList,collectionsUpdate}=API

const initialState = { 
    data:[],
    error: null, 
    loading:false,
    message:'',
    deleteLoad:false
}

const collectionsSlice = createSlice({
    name: "collections",
    initialState,
    reducers: {
      setData(state,action){
          state.data=action.payload;
      },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
    },
      setDeleteLoad(state,action){
          state.deleteLoad=action.payload
      }
    },
  });
  
  export const { setLoading, setMessage,setError,setData,setDeleteLoad} = collectionsSlice.actions;
  export default collectionsSlice.reducer;

  export const getCollectionsData = () => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await collectionsList()
      if (res.data.success) {
          dispatch(setData(res.data.data))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };
  
export const collectionsDataUpdate=(id,data) => async (dispatch)=>{
  dispatch(setDeleteLoad(true));
  try{
    const res= await collectionsUpdate(id,data)
    if(res.data.success) {
       dispatch(setDeleteLoad(false))
       dispatch(setMessage(res.data.message))
       message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
  }
  } catch(e){
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
}
