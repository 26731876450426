import React, { useState, useEffect } from "react";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  designersDataAdded,
  designersDataUpdate,
  designersEditAction,
} from "../../store/slices/designersSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import style from "./designersChange.module.css";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
  Typography,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;
const { Text } = Typography;

const DesignersChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.designers.deleteLoad);
  const message = useSelector((state) => state.designers.message);
  const singleDesigners = useSelector(
    (state) => state.designers.singleDesigners
  );
  const editData = useSelector((state) => state.designers.editData);
  const [form] = Form.useForm();
  const urls = "/designers/add-designers";
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [images, setImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [addNewImage, setAddNewImage] = useState([]);
  const [addNewImg, setAddNewImg] = useState("");
  const [descriptionText, setDescriptionText] = useState(""); // for Character limit
  const [nameNew, setNameNew] = useState(""); // for Character limit
  const [qualificationNew, setQualificationNew] = useState(""); // for Character limit
  const [profileNew, setProfileNew] = useState(""); // for Character limit
  const [error, setError] = useState("");

  useEffect(() => {
    setAddNewImage([]);
    setAddNewImg("");
  }, []);

  // for Character limit
  useEffect(() => {
    if (editData && editData.name) {
      setNameNew(editData.name);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.qualification) {
      setQualificationNew(editData.qualification);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.profile) {
      setProfileNew(editData.profile);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.description) {
      setDescriptionText(editData.description);
    }
  }, [editData]);

  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(info?.file?.response?.data?.image.toString());
        setNewImage(info?.file?.response?.data?.image.toString());
        setAddNewImg(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const beforeUpload = (file) => {
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please select an image.");
        return;
      } else {
        setError("");
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            const minWidth = 1300; // Example minimum width
            const minHeight = 1300; // Example minimum height

            if (width >= minWidth && height >= minHeight) {
              setError("");
            } else {
              setError(
                "Invalid dimensions for image. Please select an image with a width of 1300 pixels and a height of 1300 pixels."
              );
            }
          };
        };

        reader.readAsDataURL(file);
      }

      // yha set images
    }
  };
  const propsUploads = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        const updateImages = [
          ...images,
          {
            image: info?.file?.response?.data?.image?.toString(),
          },
        ];
        const updateImagesNew = [
          ...newImages,
          {
            image: info?.file?.response?.data?.image?.toString(),
          },
        ];
        const updateAdd = [
          ...addNewImage,
          {
            image: info?.file?.response?.data?.image?.toString(),
          },
        ];
        setImages(error ? "" : updateImages);
        setNewImages(error ? "" : updateImagesNew);
        setAddNewImage(error ? "" : updateAdd);
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const deleteImgHandlerAdd = (image) => {
    let images = [...addNewImage];
    let updateImages = images.filter((item) => item.image !== image);
    setAddNewImage(updateImages);
  };
  const deleteBannerImgHandlerAdd = (image) => {
    setAddNewImg("");
  };
  const backHandler = () => {
    navigate("/designers");
  };
  const onFinish = (values) => {
    let data = {
      name: values.name,
      profile: values.profile,
      qualification: values.qualification,
      description: values.description,
      bannerImage: newImage ? newImage : image,
      images: newImages ? newImages : images,
    };
    let datas = {
      name: values.name,
      profile: values.profile,
      qualification: values.qualification,
      description: values.description,
      bannerImage: addNewImg,
      images: addNewImage,
    };
    if (path === urls) {
      dispatch(designersDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(
        designersDataUpdate(
          singleDesigners[0]?._id ? singleDesigners[0]?._id : id,
          data
        )
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "New Designer added successfully!" ||
      message === "Designer updated successfully!"
    ) {
      navigate("/designers");
    }
  }, [message]);
  useEffect(() => {
    let data = singleDesigners[0];
    // form.setFieldValue("name", data?.name ? data?.name : '')
    // form.setFieldValue("qualification", data?.qualification ? data?.qualification : '')
    // form.setFieldValue("profile", data?.profile ? data?.profile : '')
    // form.setFieldValue("description",data?.description?data?.description:'')
    setNewImage(data?.bannerImage.toString());
    setImages(data?.images ? data?.images : "");
    setNewImages(data?.images ? data?.images : "");
  }, [singleDesigners]);

  // for Character limit
  useEffect(() => {
    form.setFieldValue("name", nameNew);
  }, [nameNew]);
  useEffect(() => {
    form.setFieldValue("qualification", qualificationNew);
  }, [qualificationNew]);
  useEffect(() => {
    form.setFieldValue("profile", profileNew);
  }, [profileNew]);
  useEffect(() => {
    form.setFieldValue("description", descriptionText);
  }, [descriptionText]);
  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(designersEditAction(id));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    // form.setFieldValue("name", data?.name ? data?.name : '')
    // form.setFieldValue("qualification", data?.qualification ? data?.qualification : '')
    // form.setFieldValue("profile", data?.profile ? data?.profile : '')
    // form.setFieldValue("description",data?.description?data?.description:'')
    setNewImage(data?.bannerImage);
    setImages(data?.images ? data?.images : "");
    setNewImages(data?.images ? data?.images : "");
  }, [editData]);
  const deleteImgHandler = () => {
    setNewImage("");
  };
  const deleteImgHandlers = (image) => {
    let images = [...newImages];
    let updateImages = images.filter((item) => item.image !== image);
    setNewImages(updateImages);
  };

  // for name limit
  // const maxNCharacters = 30;
  const handleNameLimitChange = (e) => {
    const newName = e.target.value;
    setNameNew(newName);
    // if (newName.length <= maxNCharacters) {
    // }
  };
  // for qualification limit
  // const maxQCharacters = 30;
  const handleQualificationLimitChange = (e) => {
    const newQ = e.target.value;
    setQualificationNew(newQ);
    // if (newQ.length <= maxQCharacters) {
    // }
  };
  // for profile limit
  // const maxPCharacters = 30;
  const handleProfileLimitChange = (e) => {
    const newProfile = e.target.value;
    setProfileNew(newProfile);
    // if (newProfile.length <= maxPCharacters) {
    // }
  };
  // for description limit
  // const maxCharacters = 500;
  const handleDescriptionLimitChange = (e) => {
    const newDescription = e.target.value;
    setDescriptionText(newDescription);
    // if (newDescription.length <= maxCharacters) {
    // }
  };
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            name={path === urls ? "designers add" : "designers update"}
            backButtonHandler={backHandler}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input
                value={nameNew}
                onChange={handleNameLimitChange}
                placeholder="Enter your name"
              />
              <Text
                // type={nameNew.length > maxPCharacters ? "danger" : "secondary"}
              >
                {/* {`${nameNew.length} / ${maxNCharacters} characters`} */}
              </Text>
              {/* <Input placeholder="Enter your name" /> */}
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Qualification"
              name="qualification"
              rules={[
                {
                  required: true,
                  message: "Please input your qualification!",
                },
              ]}
            >
              <Input
                value={qualificationNew}
                onChange={handleQualificationLimitChange}
                placeholder="Enter your qualification"
              />
              <Text
                // type={
                //   qualificationNew.length > maxPCharacters
                //     ? "danger"
                //     : "secondary"
                // }
              >
                {/* {`${qualificationNew.length} / ${maxQCharacters} characters`} */}
              </Text>
              {/* <Input placeholder="Enter your qualification" /> */}
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Profile"
              name="profile"
              rules={[
                {
                  required: true,
                  message: "Please input your profile!",
                },
              ]}
            >
              <Input
                value={profileNew}
                onChange={handleProfileLimitChange}
                placeholder="Enter your profile"
              />
              <Text
                // type={
                //   profileNew.length > maxPCharacters ? "danger" : "secondary"
                // }
              >
                {/* {`${profileNew.length} / ${maxPCharacters} characters`} */}
              </Text>
            </Form.Item>
            <div className={style.images_dimensions}>
              <p>the Designer image dimensions should be (1024px)x(1024px).</p>
            </div>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "47%" }}
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input your description!",
                },
              ]}
              // value={descriptionText}
            >
              <TextArea
                value={descriptionText}
                onChange={handleDescriptionLimitChange}
                rows={5}
                placeholder="description here...."
              />
              <Text
                // type={
                //   descriptionText.length > maxCharacters
                //     ? "danger"
                //     : "secondary"
                // }
              >
                {/* {`${descriptionText.length} / ${maxCharacters} characters`} */}
              </Text>
              {/* <Text type={descriptionText.length === maxCharacters ? 'danger' : 'secondary'}>
                {`${descriptionText.length} / ${maxCharacters} characters`}
              </Text> */}
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                width: "47%",
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
                marginTop: "22px",
              }}
              label="Designer Picture"
              name="bannerImage"
            >
              {path === urls ? (
                <>
                  {addNewImg ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={addNewImg ? `${mainUrl()}/${addNewImg}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteBannerImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImg ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImage ? `${mainUrl()}/${newImage}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {newImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      {newImage?.length < 1 && "+ Upload"}
                    </Upload>
                  )}
                </>
              )}
            </Form.Item>
            <div className={style.images_dimensions_next}>
              the Portfolio images dimensions should be (1300px)x(1300px).
            </div>
            <Form.Item
              className={style.appointments_form_item}
              style={{
                width: "100%",
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
              }}
              label="Portfolio Images"
              name="Images"
            >
              {path === urls ? (
                <>
                  <div className={style.common_edit_image_antds}>
                    {addNewImage?.length > 0
                      ? addNewImage &&
                        addNewImage.map((item, index) => {
                          return (
                            <div
                              className={
                                style.common_edit_image_antd_containers
                              }
                              key={index}
                            >
                              <img
                                src={
                                  addNewImage
                                    ? `${mainUrl()}/${item.image}`
                                    : "-"
                                }
                                alt="edit image"
                              />
                              <div className={style.common_edit_delt_antds}>
                                <img
                                  src={DeleteImg}
                                  alt="delete image"
                                  onClick={() =>
                                    deleteImgHandlerAdd(item.image)
                                  }
                                />
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <Upload
                    beforeUpload={beforeUpload}
                    action={trelifImageUpload}
                    onPreview={() => {}}
                    listType="picture-card"
                    showUploadList={false}
                    {...propsUploads}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {images.length < 10 && "+ Upload"}
                  </Upload>
                </>
              ) : (
                <>
                  <div className={style.common_edit_image_antds}>
                    {newImages?.length > 0
                      ? newImages &&
                        newImages.map((item, index) => {
                          return (
                            <div
                              className={
                                style.common_edit_image_antd_containers
                              }
                              key={index}
                            >
                              <img
                                src={
                                  newImages ? `${mainUrl()}/${item.image}` : "-"
                                }
                                alt="edit image"
                              />
                              <div className={style.common_edit_delt_antds}>
                                <img
                                  src={DeleteImg}
                                  alt="delete image"
                                  onClick={() => deleteImgHandlers(item.image)}
                                />
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                  <div>
                    <Upload
                      beforeUpload={beforeUpload}
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUploads}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      {newImages.length < 10 && "+ Upload"}
                    </Upload>
                  </div>
                </>
              )}
            </Form.Item>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add designers"
                ) : (
                  "update designers"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default DesignersChange;
