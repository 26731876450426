import React, { useEffect, useState } from "react";
import style from "./addCustomer.module.css";
import Close from "../../assets/icons8-close-24.png";
import SmallLoader from "../smallLoader/SmallLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { customerAddAction } from "../../store/slices/usersSlice";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
  Checkbox,
} from "antd";

const AddCustomer = ({ closeHandler }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [err, setErr] = useState();
  const [value, setValue] = useState(""); // for zipCode
  const loading = useSelector((state) => state.user.statusLoader);
  const message = useSelector((state) => state.user.message);
  const error = useSelector((state) => state.user.error);
  const [sameAsBilling, setSameAsBilling] = useState(true);

  const onFinish = (values) => {
    let addresses = [
      {
        streetAddress: values.streetAddress,
        city: values.city,
        zipCode: values.zipCode,
        country: values.country,
        state: values.state,
        selectedOption: values.selectedOption,
        addressType: "billing",
      },
    ];

    if (!sameAsBilling) {
      addresses.push({
        streetAddress: values.shippingStreetAddress,
        city: values.shippingCity,
        zipCode: values.shippingZipCode,
        country: values.shippingCountry,
        state: values.shippingState,
        selectedOption: values.shippingOption,
        addressType: "shipping",
      });
    } else {
      addresses.push({
        streetAddress: values.streetAddress,
        city: values.city,
        zipCode: values.zipCode,
        country: values.country,
        state: values.state,
        selectedOption: values.selectedOption,
        addressType: "shipping",
      });
    }

    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      phone: values.phone,
      mail: values.email,
      status: 1,
      addresses: addresses,
    };

    dispatch(customerAddAction(data));
  };



  useEffect(() => {
    if (message === "Your account has been created!") {
      closeHandler();
      navigate("/customers");
    }
  }, [message, closeHandler, navigate]);

  const onFinishFailed = (errorInfo) => { };

  useEffect(() => {
    setErr(error);
  }, [error]);

  const handleZipCodeChange = (e) => {
    let sanitizedValue = e.target.value.replace(/[^0-9]/g, "");

    if (sanitizedValue.length > 0 && sanitizedValue[0] === "0") {
      sanitizedValue = sanitizedValue.slice(1);
    }
    sanitizedValue = sanitizedValue.slice(0, 6);
    form.setFieldsValue({ zipCode: sanitizedValue });
  };

  const handleShippingZipCodeChange = (e) => {
    let sanitizedValue = e.target.value.replace(/[^0-9]/g, "");

    if (sanitizedValue.length > 0 && sanitizedValue[0] === "0") {
      sanitizedValue = sanitizedValue.slice(1);
    }
    sanitizedValue = sanitizedValue.slice(0, 6);
    form.setFieldsValue({ shippingZipCode: sanitizedValue });
  }

  const handlePhoneNumberChange = (e) => {
    setErr("");
    let sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
    sanitizedValue = sanitizedValue.slice(0, 10);
    form.setFieldsValue({ phone: sanitizedValue });
  };

  const handleKeyDown = (e) =>
    !/^[0-9]$/.test(e.key) &&
    !["Backspace", "ArrowLeft", "ArrowRight", "Tab", "Delete"].includes(e.key) &&
    e.preventDefault();

  const handleFirstNameChange = (e) => {
    const filteredValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ firstName: filteredValue });
  };

  const handleLastNameChange = (e) => {
    const filteredValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ lastName: filteredValue });
  };

  const handleCountryChange = (e) => {
    let sanitizedValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ country: sanitizedValue });
  };

  const handleShippingCountryChange = (e) => {
    let sanitizedValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ shippingCountry: sanitizedValue });
  }

  const handleStateChange = (e) => {
    let sanitizedValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ state: sanitizedValue });
  };

  const handleShippingStateChange = (e) => {
    let sanitizedValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ shippingState: sanitizedValue });
  }

  const handleCityChange = (e) => {
    let sanitizedValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ city: sanitizedValue });
  };

  const handleShippingCityChange = (e) => {
    let sanitizedValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ shippingCity: sanitizedValue });
  }

  const filterNonAlphabetical = (inputValue) => {
    return inputValue.replace(/[^a-zA-Z]/g, "");
  };

  const validateZipCode = (_, value, callback) => {
    if (value && value.trim() !== "") {
      const zipCodeRegex = /^[0-9]{6}$/;

      if (!zipCodeRegex.test(value)) {
        callback("Zip code must have exactly 6 digits!");
      } else {
        callback();
      }
    } else {
      callback();
    }
  };

  const handleCheckboxChange = (e) => {
    setSameAsBilling(e.target.checked);
  };

  return (
    <div className={style.addcustomer_section}>
      <div className={style.addcustomer_container}>
        <div className={style.userPopup_close_image}>
          <div className={style.userPopup_error}>{err}</div>
          <img src={Close} alt="close image" onClick={closeHandler} />
        </div>
        <div className={style.userPopup_head}>Add New Customer</div>
        <Form
          className={style.appointments_form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
          form={form}
          style={{
            maxWidth: "100%",
          }}
        >
          <Form.Item
            className={style.appointments_form_item}
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
              },
            ]}
          >
            <Input
              placeholder="Enter your first name"
              onChange={handleFirstNameChange}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Last Name"
            name="lastName"
          >
            <Input
              placeholder="Enter your last name"
              onChange={handleLastNameChange}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              className={style.password_input}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Phone Number"
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input
              placeholder="Enter your phone number"
              onChange={handlePhoneNumberChange}
            />
          </Form.Item>
          <div style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}>
            <h6>Add Customer's Billing Address</h6>
          </div>
          <Form.Item
            className={style.appointments_form_item}
            label="Street Address"
            name="streetAddress"
            rules={[
              {
                required: true,
                message: "Please input your street address!",
              },
            ]}
          >
            <Input placeholder="Enter your street address" />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="City"
            name="city"
            rules={[
              {
                required: true,
                message: "Please input your city!",
              },
            ]}
          >
            <Input
              placeholder="Enter your city"
              onChange={handleCityChange}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Zip Code"
            name="zipCode"
            rules={[
              {
                required: true,
                message: "Please input your zip code!",
              },
              { validator: validateZipCode },
            ]}
          >
            <Input
              onKeyDown={handleKeyDown}
              onChange={handleZipCodeChange}
              value={value}
              placeholder="Enter your zip code"
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="State"
            name="state"
            rules={[
              {
                required: true,
                message: "Please input your state name!",
              },
            ]}
          >
            <Input
              placeholder="Enter your state name"
              onChange={handleStateChange}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                message: "Please input your country name!",
              },
            ]}
          >
            <Input
              placeholder="Enter your country name"
              onChange={handleCountryChange}
            />
          </Form.Item>

          <Form.Item
            className={style.appointments_form_item}
            label="Select Option"
            name="selectedOption"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input your select option!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="home">Home</Radio>
              <Radio value="work">Work</Radio>
              <Radio value="other">Other</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            className={style.appointments_form_item}
            valuePropName="checked"
            style={{ marginBottom: "10px" }}
          >
            <Checkbox
              checked={sameAsBilling}
              onChange={handleCheckboxChange}
            >
              Shipping address same as billing address
            </Checkbox>
          </Form.Item>

          {!sameAsBilling && (
            <>
              <div style={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}>
                <h6>Add Customer's Shipping Address</h6>
              </div>
              <Form.Item
                className={style.appointments_form_item}
                label="Street Address"
                name="shippingStreetAddress"
                rules={[
                  {
                    required: true,
                    message: "Please input your shipping street address!",
                  },
                ]}
              >
                <Input placeholder="Enter your shipping street address" />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="City"
                name="shippingCity"
                rules={[
                  {
                    required: true,
                    message: "Please input your shipping city!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your shipping city"
                  onChange={handleShippingCityChange}
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Zip Code"
                name="shippingZipCode"
                rules={[
                  {
                    required: true,
                    message: "Please input your shipping zip code!",
                  },
                  { validator: validateZipCode },
                ]}
              >
                <Input
                  onKeyDown={handleKeyDown}
                  onChange={handleShippingZipCodeChange}
                  value={value}
                  placeholder="Enter your shipping zip code"
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="State"
                name="shippingState"
                rules={[
                  {
                    required: true,
                    message: "Please input your shipping state name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your shipping state name"
                  onChange={handleShippingStateChange}
                />
              </Form.Item>
              <Form.Item
                className={style.appointments_form_item}
                label="Country"
                name="shippingCountry"
                rules={[
                  {
                    required: true,
                    message: "Please input your shipping country name!",
                  },
                ]}
              >
                <Input
                  placeholder="Enter your shipping country name"
                  onChange={handleShippingCountryChange}
                />
              </Form.Item>

              <Form.Item
                className={style.appointments_form_item}
                label="Select Option"
                name="shippingOption"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "Please input your select option!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="home">Home</Radio>
                  <Radio value="work">Work</Radio>
                  <Radio value="other">Other</Radio>
                </Radio.Group>
              </Form.Item>
            </>
          )}

          <Form.Item className={style.appointments_form_submit_all}>
            <Button
              className={style.appointments_form_submit}
              htmlType="submit"
              style={{ color: "#fff" }}
            >
              {loading ? <SmallLoader /> : "Add Customer"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddCustomer;
