import { message } from "antd";
import axios from "axios";
import { baseURL } from "../utils/common";

let apiClient = null;

class SammyOpsClient {
  constructor() {
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.patch = this.patch.bind(this);
    this.delete = this.delete.bind(this);
  }

  _getClient() {
    apiClient = axios.create({
      baseURL: baseURL(),
    });

    apiClient.interceptors.request.use(
      async (config) => {
        const token = await localStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (err) => {
        return Promise.reject(err);
      }
    );

    apiClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // Redirect to login page
          message.info("Session Expired");
          localStorage.clear();
          window.location.reload();
        }

        return Promise.reject(error);
      }
    );

    return apiClient;
  }

  _config() {
    return {};
    //return { headers: { Authorization: access_token } };
  }
  _authConfig(token) {
    return {
      headers: {
        Authorization: `Bearer ${JSON.stringify(token)}`,
      },
    };
  }

  get(url) {
    return this._getClient().get(url, this._config());
  }

  post(url, data) {
    return this._getClient().post(url, data, this._config());
  }
  put(url, data) {
    return this._getClient().put(url, data, this._config());
  }
  patch(url, data) {
    return this._getClient().patch(url, data, this._config());
  }
  delete(url, data) {
    return this._getClient().delete(url, data, this._config());
  }
}

const SOpClient = new SammyOpsClient();
const get = SOpClient.get;
const post = SOpClient.post;
const put = SOpClient.put;
const patch = SOpClient.patch;
const _delete = SOpClient.delete;
const token = localStorage.getItem("token");
const clientId = localStorage.getItem("userId");

const formData = new FormData();
formData.append("clientId", clientId);

export default {
  SammyOpsClient,
};
export { token, formData, clientId, get, post, put, patch, _delete };
