import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api";
const {
  ordersList,
  ordersStatusChange,
  productStatusChange,
  ordersDelete,
} = API;

const initialState = {
  data: [],
  singleData: [],
  productStatusData: [],
  singleProductData: [],
  status: "",
  error: null,
  loading: false,
  message: "",
  deleteLoad: false,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setProductStatusData(state, action) {
      state.productStatusData = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDeleteLoad(state, action) {
      state.deleteLoad = action.payload;
    },
    setAfterDeleteOrders(state, action) {
      state.data = state.data.filter((item) => item._id !== action.payload);
    },
    setstatus(state, action) {
      state.status = action.payload;
    },
    setChnageStatus(state, action) {
      state.singleData = state.data.filter(
        (item) => item._id == action.payload
      );
      state.singleData[0].status = state.status;
    },
    setChnageProductStatus(state, action) {
      state.singleProductData = state.productStatusData.filter(
        (item) => item.productId == action.payload
      );
      state.singleProductData[0].status = state.status;
    },
  },
});

export const {
  setProductStatusData,
  setChnageProductStatus,
  setLoading,
  setMessage,
  setError,
  setData,
  setDeleteLoad,
  setAfterDeleteOrders,
  setChnageStatus,
  setstatus,
} = ordersSlice.actions;
export default ordersSlice.reducer;

export const orederedProductDataAction = (data) => (dispatch) => {
  dispatch(setProductStatusData(data));
};

export const messageBlankAction = () => (dispatch) => {
  dispatch(setMessage());
};

export const getOrdersData = (msg) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await ordersList();
    if (res.data.success) {
      dispatch(setData(res.data.data));
      dispatch(setLoading(false));
      dispatch(setMessage(res.data.message));
      if (msg === "msg") {
      } else {
        // message.success(res.data?.message);
      }
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.message));
    message.error(e.message);
  }
};

export const ordersDataDelete = (id) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await ordersDelete(id);
    if (res.data.success) {
      dispatch(setAfterDeleteOrders(id));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const ordersStatusChangeAction = (id, data) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await ordersStatusChange(id, data);
    if (res.data.success) {
      dispatch(setstatus(data.updatedStatus));
      dispatch(setChnageStatus(id));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const productStatusChangeAction = (orderId, productId, data) => async (
  dispatch
) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await productStatusChange(orderId, productId, data);
    if (res.data.success) {
      dispatch(setstatus(data.updatedStatus));
      dispatch(setChnageProductStatus(productId));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
      dispatch(getOrdersData());
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};
