import  {post,get,_delete,put,formData, clientId, patch}  from '../TrelifAdmin';

const categorysList = () => {
  return get('/category');
};
const categorysAdd = (data) => {
  return post('/category',data);
};
const categorysUpdate = (id,data) => {
  return put(`/category/${id}`,data);
};
const categorysDelete=(id)=>{
  return _delete(`/category/${id}`)
}
const categorysStatus=(id)=>{
  return patch(`/category/${id}`)
}
const categorysEdit=(id)=>{
  return get(`/category/specific-category/${id}`)
}

export default {
    categorysList,
    categorysAdd,
    categorysUpdate,
    categorysDelete,
    categorysStatus,
    categorysEdit
};