import React, { useState, useEffect } from "react";
import style from "./team.module.css";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Space, Tag, Switch } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/editNew.svg";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import {
  getTeamData,
  teamDataDelete,
  sigleTeamDataAction,
  blankDataAction,
  statusChangeAction,
  editBlankData,
  adminStatusChangeAction,
} from "../../store/slices/teamSlice";
import { messageBlankAction } from "../../store/slices/usersSlice";
import { mainUrl } from "../../utils/common";
import RevertLoader from "../../components/revertLoader/RevertLoader";
import UserPopup from "../../components/userPopup/UserPopup";
import ChangePasswordModal from "../../components/changePasswordModal/ChangePasswordModal";

const Team = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.team.data);
  const adminList = useSelector((state) => state.team.adminList);
  const loading = useSelector((state) => state.team.loading);
  const deleteLoad = useSelector((state) => state.team.deleteLoad);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [ids, setIds] = useState();
  const [toggledAdminId, setToggledAdminId] = useState("");
  const [pop, setPop] = useState(false);
  const [openModal, setOpenModal] = useState({
    modalInfo: {},
    open: false,
  });

  useEffect(() => {
    dispatch(getTeamData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const columns = [
    // {
    //     title: 'Id',
    //     dataIndex: '_id',
    //     id: '_id',
    //     render: (id) => <a>{id}</a>,
    //   },
    {
      title: "Name",
      dataIndex: "name",
      id: "name",
      width: 200,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Image",
      dataIndex: "image",
      id: "image",
      width: 150,
      render: (image) => (
        <div className={style.common_style_image}>
          <img
            crossOrigin="anonymous"
            src={image ? `${mainUrl()}/${image}` : "-"}
            alt="image"
          />
        </div>
      ),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      id: "designation",
      width: 200,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Action",
      id: "action",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandler(record)}
          >
            <img src={EditImg} alt="edit image" />
            <div>edit</div>
          </div>
          {/* <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
              {deleteLoad && record._id=== deleteId ? <ColorLoader/>:
              <>
              <img src={DeleteImg} alt='edit image'/>
               <div>delete</div>
               </>
               }
              </div> */}
        </Space>
      ),
    },
  ];
  const adminTableColumns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      id: "firstName",
      width: 200,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      id: "lastName",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      id: "email",
      width: 300,
    },
    {
      title: "Designation",
      dataIndex: "role_id",
      id: "designation",
      width: 150,
      render: (text) => {
        const role = text === 1 ? "admin" : "admin support";
        const color = text === 1 ? "red" : "purple";
        return (
          <Tag color={color} className={style.tag}>
            {role}
          </Tag>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record) => {
        const onToggle = (toggledId) => {
          setToggledAdminId(toggledId);
          dispatch(adminStatusChangeAction(toggledId));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && toggledAdminId === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && toggledAdminId === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Action",
      id: "changePassword",
      width: 150,
      render: (_, record) => {
        return (
          <Space size="middle">
            <div
              className={style.delete_edit_hand_1}
              onClick={() => {
                setOpenModal({
                  ...openModal,
                  modalInfo: { ...record },
                  open: true,
                });
              }}
            >
              <img src={EditImg} alt="edit image" />
              <div>edit</div>
            </div>
          </Space>
        );
      },
    },
  ];
  const addHandler = () => {
    dispatch(blankDataAction());
    dispatch(editBlankData());
    navigate("/team/add-team");
  };
  const editHandler = (record) => {
    dispatch(sigleTeamDataAction(record._id));
    navigate(`/team/edit-team/${record._id}`);
  };
  const deleteHandler = (record) => {
    setDeleteId(record._id);
    dispatch(teamDataDelete(record._id));
  };
  const addAdminHandler = () => {
    setPop(true);
  };
  const closeHandler = () => {
    setPop(false);
    dispatch(messageBlankAction());
  };
  return (
    <>
      <ChangePasswordModal openModal={openModal} setOpenModal={setOpenModal} />
      <Layout>
        {loading ? (
          <BigLoader />
        ) : (
          <>
            <div className={style.team_section}>
              <PageHeader
                name="team details"
                btnName="add team"
                img={AddImg}
                addHandler={addHandler}
                addcustomerHandler={addAdminHandler}
                image={AddImg}
                nameBtn="add admin support"
                nextButton="true"
              />

              <>
                <Tables
                  datas={state}
                  columns={columns}
                  showPagination={false}
                  verticalScrollVal={310}
                />
                {/* <div style={{textTransform:'capitalize'}}>total team{" "}-{" "+state?state?.length:''}</div> */}
                {pop ? <UserPopup closeHandler={closeHandler} /> : ""}
              </>
            </div>
            <div className={style.team_section}>
              <PageHeader name="admin details" />

              <>
                <Tables
                  datas={adminList}
                  columns={adminTableColumns}
                  showPagination={false}
                  verticalScrollVal={310}
                />
              </>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};
export default Team;
