
import AuthManager from './authManager';
import UsersManager from './usersManager';
import BlogsManager from "./blogsManager"
import DashboardManager from "./dashboardManager"
import AppointmentsManager from "./appointmentsManager"
import TestimonialsManager from "./testimonialsManager"
import TeamManager from './teamManager';
import DesignersManager from './designersManager';
import EnquiresManager from "./enquiresManager"
import CollectionsManager from "./collectionsManager"
import CategorysManager from './categorysManager';
import ProductsManager from "./productsManager"
import NewslettersManager from './newslettersManager';
import DiscountsManager from './discountsManager';
import UspManager from "./uspManager"
import TopHeaderSliderManager from './topHeaderSliderManager';
import HomePageBottomSliderManager from './homePageBottomSliderManager';
import ProjectSliderManager from './projectSliderManager';
import HomePageManager from "./homePageManager"
import OrdersManager from "./ordersManager"
import UploadManager from './uploadManager';
import InstoreManager from './instoreManager'

export default{
  ...AuthManager,
  ...UsersManager,
  ...BlogsManager,
  ...DashboardManager,
  ...AppointmentsManager,
  ...TestimonialsManager,
  ...TeamManager,
  ...DesignersManager,
  ...EnquiresManager,
  ...CollectionsManager,
  ...CategorysManager,
  ...ProductsManager,
  ...NewslettersManager,
  ...DiscountsManager,
  ...UspManager,
  ...TopHeaderSliderManager,
  ...HomePageBottomSliderManager,
  ...ProjectSliderManager,
  ...HomePageManager,
  ...OrdersManager,
  ...UploadManager,
  ...InstoreManager,
};
