import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import style from "./categorys.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategorysData,
  categorysDataDelete,
  sigleCategorysDataAction,
  blankDataAction,
  statusChangeAction,
  editBlankData,
} from "../../store/slices/categorysSlice";
import { Space, Tag, Switch } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/editNew.svg";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import { mainUrl } from "../../utils/common";
import RevertLoader from "../../components/revertLoader/RevertLoader";

const Categorys = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.categorys.data);
  const loading = useSelector((state) => state.categorys.loading);
  const deleteLoad = useSelector((state) => state.categorys.deleteLoad);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [ids, setIds] = useState();

  useEffect(() => {
    dispatch(getCategorysData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      id: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Collections",
      dataIndex: "collections",
      id: "collections",
      render: (text) => (
        <div className={style.categorys_collection_des}>{text}</div>
      ),
    },
    {
      title: "Heading",
      dataIndex: "heading",
      id: "heading",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      id: "description",
      // render: (text) => <a>{text}</a>,
      render: (text) => (
        <div
          style={{
            maxHeight: "4vh",
            maxWidth: "18ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text ? text : "NA"}
        </div>
      ),
    },
    {
      title: "Image",
      dataIndex: "image",
      id: "image",
      render: (image) => (
        <div className={style.common_style_image}>
          <img
            crossOrigin="anonymous"
            src={image ? `${mainUrl()}/${image}` : "-"}
            alt="image"
          />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Action",
      id: "action",
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandler(record)}
          >
            <img src={EditImg} alt="edit image" />
            <div>edit</div>
          </div>
          {/* <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
              {deleteLoad && record._id=== deleteId ? <ColorLoader/>:
              <>
              <img src={DeleteImg} alt='edit image'/>
               <div>delete</div>
               </>
               }
              </div> */}
        </Space>
      ),
    },
  ];
  const addHandler = () => {
    dispatch(blankDataAction());
    dispatch(editBlankData());
    navigate("/categories/add-categories");
  };
  const editHandler = (record) => {
    dispatch(sigleCategorysDataAction(record._id));
    navigate(`/categories/edit-categories/${record._id}`);
  };
  const deleteHandler = (record) => {
    setDeleteId(record._id);
    dispatch(categorysDataDelete(record._id));
  };
  return (
    <Layout>
      <div className={style.category_section}>
        <PageHeader
          name="categories details"
          count={state ? state.length : ""}
          total={true}
          btnName="add categories"
          img={AddImg}
          addHandler={addHandler}
        />
        {loading ? (
          <BigLoader />
        ) : (
          <>
            <Tables datas={state} columns={columns} />
            <div style={{ textTransform: "capitalize" }}>
              total categories -{" " + state ? state?.length : ""}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default Categorys;
