import React, { useState, useEffect } from "react";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  teamDataAdded,
  teamDataUpdate,
  teamEditAction,
} from "../../store/slices/teamSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import style from "./teamChange.module.css";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;

const TeamChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.team.deleteLoad);
  const message = useSelector((state) => state.team.message);
  const singleTeam = useSelector((state) => state.team.singleTeam);
  const editData = useSelector((state) => state.team.editData);
  const [form] = Form.useForm();
  const urls = "/team/add-team";
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [addNewImage, setAddNewImage] = useState("");
  const [nameNew, setNameNew] = useState(""); // for Character limit
  const [designationNew, setDesignationNew] = useState(""); // for Character limit


  // for character limit
  const maxCharacters = 50;

  const handleChange = (event) => {
    const inputMessage = event.target.value;

    if (inputMessage.length <= maxCharacters) {
      setNameNew(inputMessage);
    }
  };

  // for character limit
  const maxDCharacters = 50;

  const handleDesignationChange = (event) => {
    const inputMessage = event.target.value;

    if (inputMessage.length <= maxDCharacters) {
      setDesignationNew(inputMessage);
    }
  };

  useEffect(() => {
    setAddNewImage("");
  }, []);

  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(info?.file?.response?.data?.image.toString());
        setNewImage(info?.file?.response?.data?.image.toString());
        setAddNewImage(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };

  const backHandler = () => {
    navigate("/team");
  };
  const onFinish = (values) => {
    let data = { ...values, image: newImage ? newImage : image };
    let datas = { ...values, image: addNewImage };
    if (path === urls) {
      dispatch(teamDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(
        teamDataUpdate(singleTeam[0]?._id ? singleTeam[0]?._id : id, data)
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "New team member added successfully!" ||
      message === "Team member updated successfully!"
    ) {
      navigate("/team");
    }
  }, [message]);
  useEffect(() => {
    let data = singleTeam[0];
    // form.setFieldValue("name", data?.name ? data?.name : '')
    // form.setFieldValue("message",data?.message?data?.message:'')
    // form.setFieldValue("designation", data?.designation ? data?.designation : '')
    setNewImage(data?.image.toString());
  }, [singleTeam]);

  //for character limit
  useEffect(() => {
    form.setFieldValue("name", nameNew);
  }, [nameNew]);
  useEffect(() => {
    form.setFieldValue("designation", designationNew);
  }, [designationNew]);

  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(teamEditAction(id));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    form.setFieldValue("name", data?.name ? data?.name : "");
    // form.setFieldValue("message",data?.message?data?.message:'')
    form.setFieldValue(
      "designation",
      data?.designation ? data?.designation : ""
    );
    setNewImage(data?.image);
  }, [editData]);
  const deleteImgHandler = () => {
    setNewImage("");
  };
  const deleteImgHandlerAdd = () => {
    setAddNewImage("");
  };

  // for character limit
  useEffect(() => {
    if (editData && editData.name) {
      setNameNew(editData.name);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.designation) {
      setDesignationNew(editData.designation);
    }
  }, [editData]);

  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            name={path === urls ? "team add" : "team update"}
            backButtonHandler={backHandler}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input
                value={nameNew}
                onChange={handleChange}
                placeholder="Enter your name"
              />
              <p>{`${nameNew.length}/${maxCharacters} characters`}</p>
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Designation"
              name="designation"
              rules={[
                {
                  required: true,
                  message: "Please input your designation!",
                },
              ]}
            >
              <Input
                value={designationNew}
                onChange={handleDesignationChange}
                placeholder="Enter your designation"
              />
              <p>{`${designationNew.length}/${maxDCharacters} characters`}</p>
            </Form.Item>
            <div className={style.images_dimensions}>
              the image dimensions should be (1024px)x(1024px).
            </div>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
              }}
              label="Image"
              name="image"
            >
              {path === urls ? (
                <>
                  {addNewImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={
                            addNewImage ? `${mainUrl()}/${addNewImage}` : "-"
                          }
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <Form.Item>
                  {newImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImage ? `${mainUrl()}/${newImage}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Upload
                    action={trelifImageUpload}
                    listType="picture-card"
                    showUploadList={false}
                    onPreview={() => {}}
                    {...propsUpload}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newImage?.length < 1 && "+ Upload"}
                  </Upload>
                </Form.Item>
              )}
            </Form.Item>
            {/* <Form.Item
              className={style.appointments_form_item}
              style={{marginTop:"-25px"}}
              label="Message"
              name="message"
            >
            <TextArea rows={5} placeholder="message here...."/>
            </Form.Item> */}
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add team"
                ) : (
                  "update team"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default TeamChange;
