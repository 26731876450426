import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api";
const {
  teamList,
  teamAdd,
  teamUpdate,
  teamDelete,
  teamStatus,
  teamEdit,
  usersStatusChange,
  adminPasswordChange,
} = API;

const initialState = {
  data: [],
  singleData: [],
  singleTeam: [],
  error: null,
  loading: false,
  message: "",
  deleteLoad: false,
  editData: [],
  adminList: [],
  modalLoading: false,
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDeleteLoad(state, action) {
      state.deleteLoad = action.payload;
    },
    setAfterDeleteTeam(state, action) {
      state.data = state.data.filter((item) => item._id !== action.payload);
    },
    setTeamAdd(state, action) {
      state.data = [action.payload, ...state.data];
    },
    setEditTeam(state, action) {
      state.singleTeam = state.data.filter(
        (item) => item._id === action.payload
      );
    },
    setBlankSingleData(state, action) {
      state.singleTeam = [];
    },
    setChnageStatus(state, action) {
      state.singleData = state.data.filter(
        (item) => item._id == action.payload
      );
      state.singleData[0].status =
        state.singleData[0].status === 1
          ? 0
          : state.singleData[0].status === 0
          ? 1
          : "";
    },
    setEditData(state, action) {
      state.editData = action.payload;
    },
    setAdminList(state, action) {
      state.adminList = action.payload;
    },
    setAdminChangeStatus(state, action) {
      state.singleData = state.adminList.filter(
        (item) => item._id === action.payload
      );
      state.singleData[0].status =
        state.singleData[0].status === 1
          ? 0
          : state.singleData[0].status === 0
          ? 1
          : "";
    },
    setModalLoading(state, action) {
      state.modalLoading = action.payload;
    },
  },
});

export const {
  setEditData,
  setChnageStatus,
  setLoading,
  setMessage,
  setError,
  setData,
  setDeleteLoad,
  setTeamAdd,
  setAfterDeleteTeam,
  setEditTeam,
  setBlankSingleData,
  setAdminList,
  setAdminChangeStatus,
  setModalLoading,
} = teamSlice.actions;
export default teamSlice.reducer;

export const getTeamData = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await teamList();
    if (res.data.success) {
      dispatch(setData(res.data.data));
      dispatch(setAdminList(res.data.admin));
      dispatch(setLoading(false));
      dispatch(setMessage(res.data.message));
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.message));
    message.error(e.message);
  }
};
export const teamDataAdded = (data) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await teamAdd(data);
    if (res.data.success) {
      dispatch(setTeamAdd(data));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const sigleTeamDataAction = (id) => (dispatch) => {
  dispatch(setEditTeam(id));
};
export const blankDataAction = () => (dispatch) => {
  dispatch(setBlankSingleData());
};

export const teamDataUpdate = (id, data) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await teamUpdate(id, data);
    if (res.data.success) {
      dispatch(setBlankSingleData());
      dispatch(setEditData());
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};
export const teamEditAction = (id) => async (dispatch) => {
  try {
    const res = await teamEdit(id);
    if (res.data.success) {
      dispatch(setEditData(res.data.data));
    } else {
      message.error(res.data.message);
    }
  } catch (e) {
    message.error(e.response.data.message);
  }
};
export const editBlankData = () => (dispatch) => {
  dispatch(setEditData());
};
export const teamDataDelete = (id) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await teamDelete(id);
    if (res.data.success) {
      dispatch(setAfterDeleteTeam(id));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const statusChangeAction = (id) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await teamStatus(id);
    if (res.data.success) {
      dispatch(setChnageStatus(id));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const adminStatusChangeAction = (id) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await usersStatusChange(id);
    if (res.data.success) {
      dispatch(setAdminChangeStatus(id));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const adminPasswordChangeAction = (data) => async (dispatch) => {
  dispatch(setModalLoading(true));
  try {
    const res = await adminPasswordChange(data);
    if (res.data.success) {
      message.success(res.data?.message);
      dispatch(setModalLoading(false));
    } else {
      message.error(res.data?.message);
    }
  } catch (e) {
    dispatch(setModalLoading(false));
    message.error(e.response.data.message);
  }
};
