import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const topHeaderSliderList = () => {
  return get('/settings/topheader');
};
const topHeaderSliderAdd = (data) => {
  return post('/settings/topheader',data);
};
const topHeaderSliderUpdate = (id,data) => {
  return put(`/settings/topheader/${id}`,data);
};
const topHeaderSliderDelete=(id)=>{
  return _delete(`/settings/topheader/${id}`)
}
const topHeaderSliderEdit=(id,field)=>{
  return get(`/settings/specific-settings/?id=${id}&fieldName=${field}`)
}


export default {
    topHeaderSliderList,
    topHeaderSliderAdd,
    topHeaderSliderUpdate,
    topHeaderSliderDelete,
    topHeaderSliderEdit
};