import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {appointmentsList,appointmentsRevert,appointmentsReply}=API

const initialState = { 
    data:[],
    error: null, 
    loading:false,
    message:'',
    revertLoad:false
}

const appointmentsSlice = createSlice({
    name: "appointments",
    initialState,
    reducers: {
      setData(state,action){
          state.data=action.payload;
      },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
    },
    setRevertLoad(state,action){
        state.revertLoad=action.payload
    },
    setAfterRevertAppointments(state,action){
        state.data=state.data.filter((item)=>item._id !== action.payload)
    }
    },
  });
  
  export const { setLoading, setMessage,setError,setData,setRevertLoad,setAfterRevertAppointments} = appointmentsSlice.actions;
  export default appointmentsSlice.reducer;

  export const getAppointmentsData = (msg) => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await appointmentsList()
      if (res.data.success) {
          dispatch(setData(res.data.data))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
          if(msg==='msg'){

          }else{
            // message.success(res.data?.message);
          }
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };

  export const appointmentsDataRevert=(id) => async (dispatch)=>{
    dispatch(setRevertLoad(true));
    try{
      const res= await appointmentsRevert(id)
      if(res.data.success) {
         dispatch(setAfterRevertAppointments(id))
         dispatch(setRevertLoad(false))
         dispatch(setMessage(res.data.message))
         message.success(res.data?.message);
      } else {
        dispatch(setRevertLoad(false));
        dispatch(setError(res.data.message));
        message.error(res.data.message);
    }
    } catch(e){
      dispatch(setRevertLoad(false));
      dispatch(setError(e.response.data.message));
      message.error(e.response.data.message);
    }
}

export const appointmentsReplySend=(id,data) => async (dispatch)=>{
         dispatch(setRevertLoad(true));
  try{
    const res= await appointmentsReply(id,data)
    if(res.data.success) {
       dispatch(setRevertLoad(false))
       dispatch(setMessage(res.data.message))
       message.success(res.data?.message);
    } else {
      dispatch(setRevertLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
  }
  } catch(e){
    dispatch(setRevertLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
}