import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const appointmentsList = () => {
  return get('/appointments');
};
const appointmentsAdd = (data) => {
  return post('/appointments',data);
};
const appointmentsRevert=(id)=>{
  return post(`appointments/revert/${id}`)
}
const appointmentsReply=(id,data)=>{
  return post(`/appointments/appointmentReply/${id}`,data)
}


export default {
    appointmentsList,
    appointmentsRevert,
    appointmentsAdd,
    appointmentsReply,
};