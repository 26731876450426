import  {post,get,_delete,put,patch,formData, clientId}  from '../TrelifAdmin';

const ordersList = () => {
  return get('/orders');
};
const ordersStatusChange = (id,data) => {
  return patch(`/orders/order-status/${id}`,data);
};
const productStatusChange = (orderId,productId,data) => {
  return patch(`/orders/order-product-status/${orderId}/${productId}`,data);
};
const ordersDelete=(id)=>{
  return _delete(`/orders/${id}`)
}


export default {
    ordersList,
    ordersStatusChange,
    productStatusChange,
    ordersDelete,
};