import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const homePageList = () => {
  return get('/settings/homepage');
};
const homePageAdd = (data) => {
  return post('/settings/homepage',data);
};
const homePageUpdate = (id,data) => {
  return put(`/settings/homepage/${id}`,data);
};
const homePageDelete=(id)=>{
  return _delete(`/settings/homepage/${id}`)
}
const homePageEdit=(id,field)=>{
  return get(`/settings/specific-settings/?id=${id}&fieldName=${field}`)
}

export default {
    homePageList,
    homePageAdd,
    homePageUpdate,
    homePageDelete,
    homePageEdit
};