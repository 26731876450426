import { Button, Modal, Tag, Table } from "antd";

function isObject(data) {
  return typeof data === "object" && !Array.isArray(data) && data !== null;
}

const columns = [
  {
    title: "First Name",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Street Address",
    dataIndex: "streetAddress",
    key: "streetAddress",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Zip Code",
    dataIndex: "zipCode",
    key: "zipCode",
  },
  {
    title: "Country",
    dataIndex: "country",
    key: "country",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "Selected Option",
    dataIndex: "selectedOption",
    key: "selectedOption",
    render: (text) => {
      let color = "";
      if (text === "home") {
        color = "green";
      } else if (text === "work") {
        color = "blue";
      } else if (text === "other") {
        color = "purple";
      }

      return (
        <Tag color={color} key={text}>
          {text.toUpperCase()}
        </Tag>
      );
    },
  },
  {
    title: "Default Address",
    dataIndex: "defaultAddress",
    key: "defaultAddress",
    render: (text) => (
      <>{Boolean(text) ? <Tag color="red">DEFAULT</Tag> : null}</>
    ),
  },
  // {
  //   title: "ID",
  //   dataIndex: "_id",
  //   key: "_id",
  // },
];

function ModalTable({ dataSource, columns }) {
  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: 300, x: 1500 }}
      />
    </>
  );
}

function RenderTable({ data }) {
  let billingData = data.filter((item) => item.addressType === "billing");
  let shippingData = data.filter((item) => item.addressType === "shipping");
  
  //@ToDo - quick fix 
  if(billingData.length === 0) billingData = shippingData;
  if(shippingData.length === 0) shippingData = billingData;

  const otherData = data.filter(
    (item) =>
      item?.addressType !== "billing" && item?.addressType !== "shipping"
  );
  return (
    <>
      {billingData.length > 0 && (
        <h6 style={{ marginTop: "2rem", fontWeight: 600 }}>Billing Address</h6>
      )}
      {billingData.length > 0 && (
        <ModalTable dataSource={billingData} columns={columns} />
      )}
      {shippingData.length > 0 && (
        <h6 style={{ marginTop: "2rem", fontWeight: 600 }}>Shipping Address</h6>
      )}
      {shippingData.length > 0 && (
        <ModalTable dataSource={shippingData} columns={columns} />
      )}
      {otherData.length > 0 && (
        <h6 style={{ marginTop: "2rem", fontWeight: 600 }}>Other Address</h6>
      )}
      {otherData.length > 0 && (
        <ModalTable dataSource={otherData} columns={columns} />
      )}
    </>
  );
}

function ModalContent({ data }) {
  const renderedContent = () => {
    if (Array.isArray(data)) {
      if (data.length) {
        if (isObject(data[0])) {
          return <RenderTable data={data} />;
        }
      }
    }
  };

  return <div>{renderedContent()}</div>;
}

export default function AddressModal({ openModal, setOpenModal }) {
  return (
    <>
      <Modal
        title="Addresses"
        centered
        open={openModal.open}
        width={1000}
        style={{ maxHeight: "80vh" }}
        closeIcon={null}
        footer={[
          <div key="footer" style={{ textAlign: "center" }}>
            <Button
              key="ok"
              type="primary"
              onClick={() =>
                setOpenModal({ ...openModal, data: [], open: false })
              }
              style={{
                color: "white",
                background: "rgb(0, 160, 153)",
                fontWeight: "bold",
                fontFamily: "inherit",
              }}
            >
              OK
            </Button>
          </div>
        ]}
      >
        <ModalContent data={openModal.data} />
      </Modal>
    </>
  );
}
