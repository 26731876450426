import React, { useState, useEffect } from "react";
import style from "./appointmentsPopup.module.css";
import { useDispatch, useSelector } from "react-redux";
import BackImg from "../../assets/icons8-back-24.png";
import { appointmentsReplySend } from "../../store/slices/appointmentsSlice";
import SmallLoader from "../../components/smallLoader/SmallLoader";
import BackButton from "../../components/backButton/BackButton";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
const { TextArea } = Input;

const AppointmentsPopup = ({ label, backHandler, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.appointments.revertLoad);

  useEffect(() => {
    form.setFieldValue("email", data?.email ? data?.email : "");
    form.setFieldValue("reply", data?.reply ? data?.reply : "");
  }, []);
  const onFinish = (values) => {
    let value = { customerEmail: values.email, reply: values.reply };
    dispatch(appointmentsReplySend(data._id, value));
  };
  const onFinishFailed = (errorInfo) => {};
  return (
    <>
      <div className={style.product_details_popup_section}>
        <BackButton backButtonHandler={backHandler} name={label} />
        {/* <div className={style.product_details_popup_head}>
            <div className={style.product_details_popup_head_first}>{label}</div>
            <div className={style.product_details_popup_head_second} onClick={backHandler}>
                <img src={BackImg} alt='back page image'/>
                <div>back appointments list</div>
            </div>
          </div> */}
        {data ? (
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="Email To"
              name="email"
              style={{ width: "100%" }}
            >
              <Input placeholder="Enter your email" readOnly />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Reply"
              name="reply"
              rules={[
                {
                  required: true,
                  message: "Please input your reply message!",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="reply here...."
                readOnly={data?.reply ? "readOnly" : ""}
              />
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                htmlType="submit"
                style={{ color: "#fff" }}
                disabled={data?.reply ? "disabled" : ""}
              >
                {loading ? <SmallLoader /> : "send"}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <div className={style.product_details_popup_data_not_found}>
            data not found
          </div>
        )}
      </div>
    </>
  );
};
export default AppointmentsPopup;
