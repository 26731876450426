import React, { useState, useEffect } from "react";
import style from "./projectSliderChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  projectSliderDataAdded,
  projectSliderDataUpdate,
  projectSliderEditAction,
} from "../../store/slices/projectSliderSlice";
import { activeManageAction } from "../../store/slices/settingsSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;

const ProjectSliderChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.projectSlider.deleteLoad);
  const message = useSelector((state) => state.projectSlider.message);
  const singleProjectSlider = useSelector(
    (state) => state.projectSlider.singleProjectSlider
  );
  const editData = useSelector((state) => state.projectSlider.editData);
  const [form] = Form.useForm();
  const urls = "/home/project-slider/add-project-slider";
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [addNewImage, setAddNewImage] = useState("");

  useEffect(() => {
    setAddNewImage("");
  }, []);

  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(info?.file?.response?.data?.image.toString());
        setNewImage(info?.file?.response?.data?.image.toString());
        setAddNewImage(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const backHandler = () => {
    dispatch(activeManageAction(4));
    navigate("/home");
  };
  const onFinish = (values) => {
    let data = { title: values.title, image: newImage ? newImage : image };
    let datas = { title: values.title, image: addNewImage };
    if (path === urls) {
      dispatch(projectSliderDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(
        projectSliderDataUpdate(
          singleProjectSlider[0]?._id ? singleProjectSlider[0]?._id : id,
          data
        )
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "Project slider added successfully!" ||
      message === "Project slider updated successfully!"
    ) {
      dispatch(activeManageAction(4));
      navigate("/home");
    }
  }, [message]);
  useEffect(() => {
    let data = singleProjectSlider[0];
    form.setFieldValue("title", data?.title ? data?.title : "");
    // form.setFieldValue("image",data?.image?data?.image:'')
    setNewImage(data?.image.toString());
  }, [singleProjectSlider]);
  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(projectSliderEditAction(id, "ProjectSlider"));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    form.setFieldValue("title", data?.title ? data?.title : "");
    // form.setFieldValue("image",data?.image?data?.image:'')
    setNewImage(data?.image);
  }, [editData]);
  const deleteImgHandler = () => {
    setNewImage("");
  };
  const deleteImgHandlerAdd = () => {
    setAddNewImage("");
  };
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            name={
              path === urls ? "project slider add" : "project slider update"
            }
            backButtonHandler={backHandler}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your title!",
                },
              ]}
            >
              <Input placeholder="Enter your title" />
            </Form.Item>
            <div className={style.images_dimensions}>
              the images dimensions should be (1600px)x(1024px).
            </div>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
                width: "100%",
              }}
              label="Project Slider Image"
              name="image"
            >
              {path === urls ? (
                <>
                  {addNewImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={
                            addNewImage ? `${mainUrl()}/${addNewImage}` : "-"
                          }
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImage ? `${mainUrl()}/${newImage}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Upload
                    action={trelifImageUpload}
                    listType="picture-card"
                    showUploadList={false}
                    onPreview={() => {}}
                    {...propsUpload}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newImage?.length < 1 && "+ Upload"}
                  </Upload>
                </>
              )}
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add project slider"
                ) : (
                  "update project slider"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default ProjectSliderChange;
