import  {post,get,_delete,put,formData, clientId, patch}  from '../TrelifAdmin';

const designersList = () => {
  return get('/designers');
};
const designersAdd = (data) => {
  return post('/designers',data);
};
const designersUpdate = (id,data) => {
  return put(`/designers/${id}`,data);
};
const designersDelete=(id)=>{
  return _delete(`/designers/${id}`)
}
const designersStatus=(id)=>{
  return patch(`/designers/${id}`)
}
const designersEdit=(id)=>{
  return get(`designers/specific-designer/${id}`)
}


export default {
    designersList,
    designersAdd,
    designersUpdate,
    designersDelete,
    designersStatus,
    designersEdit
};