import {
  post,
  get,
  _delete,
  put,
  formData,
  clientId,
  patch,
} from "../TrelifAdmin";

const productsList = () => {
  return get("/products");
};
const productsAdd = (data) => {
  return post("/products", data);
};
const productsUpdate = (id, data) => {
  return put(`/products/${id}`, data);
};
const productsDelete = (id) => {
  return _delete(`/products/${id}`);
};
const categoryList = () => {
  return get("/category/list");
};
const categoryStatus = (id) => {
  return patch(`/products/${id}`);
};
const productsEdit = (id) => {
  return get(`/products/unique-product/${id}`);
};

const editAddVariant = (data) => {
  return post("/products/add-varient", data);
};

export default {
  productsList,
  productsAdd,
  productsUpdate,
  productsDelete,
  categoryList,
  categoryStatus,
  productsEdit,
  editAddVariant,
};
