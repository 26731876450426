import React,{useState,useEffect} from "react"
import style from "./productDetailsPopup.module.css"
import BackImg from "../../assets/back.svg"
import Tables from "../../components/tables/Tables"
import moment from "moment";
import { Space, Tag } from 'antd';
import StatusPopup from "../../components/statusPopup/StatusPopup";
import orderStatus from "../../assets/statusBlack.png"
import { useDispatch,useSelector } from "react-redux";
import {productStatusChangeAction,messageBlankAction} from "../../store/slices/ordersSlice"
import {mainUrl} from "../../utils/common"

const ProductDetailsPopup=({label,backHandler,data})=>{
    const dispatch=useDispatch();
    const productStatusData=useSelector((state)=>state.orders.productStatusData)
    const loading=useSelector((state)=>state.orders.loading)
    const message =useSelector((state)=>state.orders.message)
    const [show,setShow]=useState(false)
    const [pop,setPop]=useState()
    const [state,setState]=useState([])
    const statusData=[
        {id:1,value:"Pending"},
        {id:2,value:"Order Confirmed"},
        {id:3,value:"Shipped"},
        {id:4,value:"Out For Delivery"},
        {id:5,value:"Delivered"},
        {id:6,value:"Cancelled"},
    ]
    useEffect(()=>{
        setState(productStatusData)
    },[productStatusData])
    const columns = [
        {
          title: 'Product Name',
          dataIndex: 'productName',
          id: 'productName',
          render: (text) => <a>{text?text:'NA'}</a>,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            id: 'quantity',
            render: (text) => <a>{text?text:'NA'}</a>,
          },
        {
          title: 'Total Price',
          dataIndex: 'price',
          id: 'price',
          render: (text) => <a>{text?text:'NA'}</a>,
        },
        {
            title: 'Estimate Date',
            dataIndex: 'estimateDate',
            id: 'estimateDate',
            render: (date) => <a>{date?moment(date).format('DD/MM/YYYY'):'NA'}</a>,
          },
          {
            title: 'Image',
            dataIndex: 'image',
            id: 'image',
            width: 200,
            render: (_, { images }) => (
                <div className={style.designers_images_pa}>
                {images && images.map((img,index) => {
                 return (
                     <div key={index} className={style.designers_images_st}>
                       <img
                         crossOrigin="anonymous"
                         src={img.image ? `${mainUrl()}/${img.image}` : "-"}
                         alt="image"
                       /></div>
               );
             })}
           </div>
         )
          },
          {
            title: 'Status',
            dataIndex: 'status',
            id: 'status',
            render: (status) => <a>{status?status:'NA'}</a>,
          },
          {
            title: 'Product Status Change',
            id: 'changeStatusProduct',
            fixed: "right",
            width: 250,
            render: (_, record) => (
              <Space size="middle">
                  <div className={style.delete_edit_hand_1} onClick={()=>editHandler(record)} style={{marginBottom:data?.length == 5?'5px':'0px',width:data?.length == 5?'100%':''}}>
                  <img src={orderStatus} alt='edit image'/>
                  <div>Order status change</div>
                 </div>
                {show?<StatusPopup statusData={statusData} onFinish={onFinish} closeHandler={closeHandler} label='product status change'/>:''}
              </Space>
            ),
          },
      ];
      const closeHandler=()=>{
        setShow(false)
        setPop()
    }
      const editHandler=(record)=>{
        setShow(true)
        setPop(record)
        dispatch(messageBlankAction())
    }
    const onFinish = values => {
        dispatch(productStatusChangeAction(data._id,pop.productId,values))
           }
           useEffect(()=>{
            if(message==='Product delivery status has been changed successfully!'){
              closeHandler()
            }
          },[message])
    return(
        <>
        <div className={style.product_details_popup_section}>
          <div className={style.product_details_popup_head}>
            <div className={style.product_details_popup_head_first}>{label}</div>
            <div className={style.product_details_popup_head_second} onClick={backHandler}>
                <img src={BackImg} alt='back page image'/>
                <div>back</div>
            </div>
          </div>
          {state?
        //   <div className={style.product_details_popup_data}>
        //     {state && state.map((items)=>{
        //         return <div className={style.product_details_popup_single_data}>
        //                {items.productName}
        //               </div>
        //     })}
        //   </div>
        <Tables datas={state} columns={columns} scrollVal={1100}/>
          :
          <div className={style.product_details_popup_data_not_found}>data not found</div>
          }
        </div>
        </>
    )
}
export default ProductDetailsPopup;