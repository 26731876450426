import React, { useState, useEffect } from "react";
import style from "./collectionPopup.module.css";
import Close from "../../assets/icons8-close-24.png";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import { collectionsDataUpdate } from "../../store/slices/collectionsSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifVideoUpload, mainUrl } from "../../utils/common";
import Video from "../video/Video";
import { uploadVideoDataAction } from "../../store/slices/uploadSlice";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { message as videoMessage } from "antd";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import VideoLoader from "../videoLoader/VideoLoader";
const { TextArea } = Input;

// In MegaBytes
const MAX_VIDEO_SIZE = 100;

// In seconds
const MAX_VIDEO_DURATION = 35;
const MIN_VIDEO_DURATION = 2;

// In pixels
const MAX_VIDEO_WIDTH = 3840;
const MAX_VIDEO_HEIGHT = 2160;

const MIN_VIDEO_WIDTH = 240;
const MIN_VIDEO_HEIGHT = 240;

const videoValidator = (videoInfo) => {
  const errors = [];
  if (videoInfo.size / (1024 * 1024) > MAX_VIDEO_SIZE) {
    errors.push({
      code: "size-too-large",
      message: `Video file is larger than ${MAX_VIDEO_SIZE} MB`,
    });
  }

  if (videoInfo.duration > MAX_VIDEO_DURATION) {
    errors.push({
      code: "duration-too-long",
      message: `Video duration is longer than ${MAX_VIDEO_DURATION} seconds`,
    });
  }

  if (videoInfo.duration < MIN_VIDEO_DURATION) {
    errors.push({
      code: "duration-too-short",
      message: `Video duration is shorter than ${MIN_VIDEO_DURATION} seconds`,
    });
  }

  if (videoInfo.dimensions.width > MAX_VIDEO_WIDTH) {
    errors.push({
      code: "width-too-large",
      message: `Video width is larger than ${MAX_VIDEO_WIDTH} pixels`,
    });
  }

  if (videoInfo.dimensions.width < MIN_VIDEO_WIDTH) {
    errors.push({
      code: "width-too-small",
      message: `Video width is smaller than ${MIN_VIDEO_WIDTH} pixels`,
    });
  }

  if (videoInfo.dimensions.height > MAX_VIDEO_HEIGHT) {
    errors.push({
      code: "height-too-large",
      message: `Video height is larger than ${MAX_VIDEO_HEIGHT} pixels`,
    });
  }

  if (videoInfo.dimensions.height < MIN_VIDEO_HEIGHT) {
    errors.push({
      code: "height-too-small",
      message: `Video height is smaller than ${MIN_VIDEO_HEIGHT} pixels`,
    });
  }
  return errors;
};

const CollectionPopup = ({ label, data, closeHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.collections.deleteLoad);
  const message = useSelector((state) => state.collections.message);
  const videoData = useSelector((state) => state.upload.data);
  const [video, setVideo] = useState([]);
  const [newVideo, setNewVideo] = useState();
  const [addNewVideo, setAddNewVideo] = useState();
  const [image, setImage] = useState();
  const [form] = Form.useForm();
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoInfo, setVideoInfo] = useState({
    type: "",
    size: 0,
    dimensions: {
      width: 0,
      height: 0,
    },
    duration: 0,
  });

  const [videoFile, setVideoFile] = useState(null);
  const [isValidVideo, setIsValidVideo] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  function calcVideoInfo(videoFile) {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);

    video.onloadedmetadata = () => {
      setVideoInfo({
        ...videoInfo,
        type: videoFile.type,
        size: videoFile.size,
        dimensions: {
          ...videoInfo.dimensions,
          width: video.videoWidth,
          height: video.videoHeight,
        },
        duration: video.duration,
      });
    };
  }

  useEffect(() => {
    setAddNewVideo("");
  }, []);

  useEffect(() => {
    if (videoFile && !videoFile.type.startsWith("video/")) {
      const typeError = {
        code: "invalid-file-type",
        message: `Video files cannot be of type ${videoFile.type}`,
      };
      setValidationErrors([typeError]);
    } else {
      if (videoFile) {
        calcVideoInfo(videoFile);
      }
    }
  }, [videoFile]);

  useEffect(() => {
    if (videoInfo.type.length) {
      const errors = videoValidator(videoInfo);
      if (!errors.length) {
        setIsValidVideo(true);
      } else {
        setValidationErrors(errors);
        setIsValidVideo(false);
      }
    }
  }, [videoInfo]);

  useEffect(() => {
    if (validationErrors.length) {
      for (const error of validationErrors) {
        videoMessage.error(error.message);
      }
    }
  }, [validationErrors]);
  const propsUpload = {
    name: "video",
    onChange(info) {
      setVideoFile(info.file.originFileObj);

      if (isValidVideo) {
        if (info.file.status === "uploading") {
          setVideoLoading(true);
        }
        if (info.file.status !== "uploading") {
        }
        if (info.file.status === "done") {
          setImage(info?.file?.response?.data?.video);
          setVideo(info?.file?.response?.data?.video);
          setNewVideo(info?.file?.response?.data?.video);
          setAddNewVideo(info?.file?.response?.data?.video);
          //message.success(`${info.file.name} file uploaded successfully`)
          setVideoLoading(false);
        } else if (info.file.status === "error") {
          //message.error(`${info.file.name} file upload failed.`)
        }
      } else {
        // for (const error of validationErrors) {
        //   videoMessage.error(error.message);
        // }
      }
    },
  };
  useEffect(() => {
    if (message === "Collection updated successfully!") {
      closeHandler();
    }
  }, [message]);
  const onFinish = (values) => {
    let value = {
      title: values.title,
      description: values.description,
      media: newVideo ? newVideo[0] : "",
    };
    dispatch(collectionsDataUpdate(data._id, value));
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    form.setFieldValue("title", data?.title ? data?.title : "");
    form.setFieldValue(
      "description",
      data?.description ? data?.description : ""
    );
    let newAry = data?.media;
    setNewVideo([newAry]);
  }, []);
  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    const url = URL.createObjectURL(file);
    let data = new FormData();
    data.append("video", file.name);
    dispatch(uploadVideoDataAction(data));
  };
  const deleteImgHandlerAdd = () => {
    setAddNewVideo("");
  };
  const deleteImgHandler = () => {
    setNewVideo("");
  };
  return (
    <>
      <div className={style.collectionPopup_section}>
        <div className={style.collectionPopup_section_container}>
          <div className={style.collectionPopup_section_head}>
            <img src={Close} alt="clode image" onClick={closeHandler} />
          </div>
          <div className={style.collectionPopup_section_label}>{label}</div>
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="Title"
              name="title"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input your title!",
                },
              ]}
            >
              <Input
                style={{ textTransform: "capitalize" }}
                placeholder="Enter your title"
                disabled
              />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input your description!",
                },
              ]}
            >
              <TextArea rows={4} placeholder="description here...." />
            </Form.Item>

            <Form.Item
              className={style.appointments_form_item}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
              }}
              label="Video"
              name="video"
            >
              {/* <Upload
                action={trelifVideoUpload}
                listType="picture-card"
                {...propsUpload}
                headers={{
                  Authorization: `Bearer ${getToken()}`,
                }}
              > 
               {video.length <1 && '+ Upload'}
              </Upload> */}
              {newVideo ? (
                <div className={style.common_edit_image_antd}>
                  <div className={style.common_edit_image_antd_container}>
                    {/* <img  src={newVideo?`${mainUrl()}/${newVideo}` : "-"} alt='edit image'/> */}
                    <video autoPlay muted loop>
                      <source
                        src={newVideo ? `${mainUrl()}/${newVideo}` : "-"}
                        type="video/mp4"
                      />
                    </video>
                    <div className={style.common_edit_delt_antd}>
                      <img
                        src={DeleteImg}
                        alt="delete image"
                        onClick={deleteImgHandler}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <Upload
                action={trelifVideoUpload}
                listType="picture-card"
                showUploadList={false}
                onPreview={() => {}}
                {...propsUpload}
                headers={{
                  Authorization: `Bearer ${getToken()}`,
                }}
              >
                {newVideo?.length < 1 && "+ Upload"}
              </Upload>
            </Form.Item>

            {/* <Video handleFileChange={handleFileChange}/> */}
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? <SmallLoader /> : "update collection"}
              </Button>
            </Form.Item>
            <div
              style={{
                position: "absolute",
              }}
            >
              {videoLoading ? <VideoLoader /> : null}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default CollectionPopup;
