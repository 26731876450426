import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api";
const { enquiresList, enquiresRevert, enquiresStatusChange } = API;

const initialState = {
  data: [],
  singleData: [],
  error: null,
  loading: false,
  message: "",
  revertLoad: false,
  status: "",
};

const enquiresSlice = createSlice({
  name: "enquires",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setRevertLoad(state, action) {
      state.revertLoad = action.payload;
    },
    setAfterRevertEnquires(state, action) {
      state.data = state.data.filter((item) => item._id !== action.payload);
    },
    setstatus(state, action) {
      state.status = action.payload;
    },
    setChnageStatus(state, action) {
      state.singleData = state.data.filter(
        (item) => item._id == action.payload
      );
      state.singleData[0].enquiryStatus = state.status;
    },
  },
});

export const {
  setstatus,
  setChnageStatus,
  setLoading,
  setMessage,
  setError,
  setData,
  setRevertLoad,
  setAfterRevertEnquires,
} = enquiresSlice.actions;
export default enquiresSlice.reducer;

export const getEnquiresData = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await enquiresList();
    if (res.data.success) {
      dispatch(setData(res.data.data));
      dispatch(setLoading(false));
      dispatch(setMessage(res.data.message));
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.message));
    message.error(e.message);
  }
};

export const enquiresDataRevert = (id) => async (dispatch) => {
  dispatch(setRevertLoad(true));
  try {
    const res = await enquiresRevert(id);
    if (res.data.success) {
      dispatch(setAfterRevertEnquires(id));
      dispatch(setRevertLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setRevertLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setRevertLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const enquiresStatusChangeAction = (enquiresId, data) => async (
  dispatch
) => {
  dispatch(setRevertLoad(true));
  try {
    const res = await enquiresStatusChange(enquiresId, data);
    if (res.data.success) {
      dispatch(setstatus(data.enquiryStatus));
      dispatch(setChnageStatus(enquiresId));
      dispatch(setRevertLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setRevertLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setRevertLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};
export const messageBlankAction = () => (dispatch) => {
  dispatch(setMessage());
};
