import React from "react"
import "./bigLoader.css"

const BigLoader=()=>{
    return (
        <>
        <div className="container">
        <div className="boxes">
        <div className="box_1"></div>
        <div className="box b_1"></div>
        <div className="box b_2"></div>
        <div className="box b_3"></div>
        <div className="box b_4"></div>
        </div>
        </div>
        </>
    )
}
export default BigLoader;