import React, { useState, useEffect } from "react";
import style from "./designers.module.css";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Space, Tag, Switch } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/editNew.svg";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import {
  getDesignersData,
  designersDataDelete,
  sigleDesignersDataAction,
  blankDataAction,
  statusChangeAction,
  editBlankData,
} from "../../store/slices/designersSlice";
import { mainUrl } from "../../utils/common";
import RevertLoader from "../../components/revertLoader/RevertLoader";

const Designers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.designers.data);
  const loading = useSelector((state) => state.designers.loading);
  const deleteLoad = useSelector((state) => state.designers.deleteLoad);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [ids, setIds] = useState();

  useEffect(() => {
    dispatch(getDesignersData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      id: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Designer Picture",
      dataIndex: "bannerImage",
      id: "bannerImage",
      render: (image) => (
        <div className={style.common_style_image}>
          <img
            crossOrigin="anonymous"
            src={image ? `${mainUrl()}/${image}` : "-"}
            alt="image"
          />
        </div>
      ),
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
      id: "qualification",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      id: "description",
      // render: (text) => (
      //   <p
      //     style={{
      //       maxHeight: "50vh",
      //       overflow: "hidden",
      //       textOverflow: "ellipsis",
      //       cursor: "pointer",
      //     }}
      //   >
      //     {text}
      //   </p>
      // ),
      render: (text) => (
        <div
          style={{
            maxHeight: "4vh",
            maxWidth: "18ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {text ? text : "NA"}
        </div>
      ),
    },
    {
      title: "Profile",
      dataIndex: "profile",
      id: "profile",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Portfolio Images",
      id: "images",
      dataIndex: "images",
      width: 200,
      render: (_, { images }) => (
        <div className={style.designers_images_pa}>
          {images.map((img, index) => {
            return (
              <div key={index} className={style.designers_images_st}>
                <img
                  crossOrigin="anonymous"
                  src={img.image ? `${mainUrl()}/${img.image}` : "-"}
                  alt="image"
                />
              </div>
            );
          })}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Action",
      id: "action",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandler(record)}
          >
            <img src={EditImg} alt="edit image" />
            <div>edit</div>
          </div>
          {/* <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
              {deleteLoad && record._id=== deleteId ? <ColorLoader/>:
              <>
              <img src={DeleteImg} alt='edit image'/>
               <div>delete</div>
               </>
               }
              </div> */}
        </Space>
      ),
    },
  ];
  const addHandler = () => {
    dispatch(blankDataAction());
    dispatch(editBlankData());
    navigate("/designers/add-designers");
  };
  const editHandler = (record) => {
    dispatch(sigleDesignersDataAction(record._id));
    navigate(`/designers/edit-designers/${record._id}`);
  };
  const deleteHandler = (record) => {
    setDeleteId(record._id);
    dispatch(designersDataDelete(record._id));
  };
  return (
    <>
      <Layout>
        <div className={style.designers_section}>
          <PageHeader
            name="designers details"
            count={state ? state.length : ""}
            total={true}
            btnName="add designers"
            img={AddImg}
            addHandler={addHandler}
          />
          {loading ? (
            <BigLoader />
          ) : (
            <>
              <Tables datas={state} columns={columns} scrollVal={1300} />
              <div style={{ textTransform: "capitalize" }}>
                total designers -{" " + state ? state?.length : ""}
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Designers;
