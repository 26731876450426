import React, { useEffect, useState } from "react";
import style from "./userPopup.module.css";
import Close from "../../assets/icons8-close-24.png";
import SmallLoader from "../smallLoader/SmallLoader";
import { useDispatch, useSelector } from "react-redux";
import { userAddAction } from "../../store/slices/usersSlice";
import { useNavigate } from "react-router";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
const { TextArea } = Input;

const UserPopup = ({ closeHandler }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.user.statusLoader);
  const message = useSelector((state) => state.user.message);

  useEffect(() => {
    if (message === "Your Trelif admin login user has been created!") {
      closeHandler();
      navigate("/team");
    }
  }, [message]);
  const onFinish = (values) => {
    let data = { ...values, status: 1 };
    dispatch(userAddAction(data));
  };
  const onFinishFailed = (errorInfo) => {};

  const validateConfirmPassword = (_, value) => {
    const { password } = form.getFieldsValue(["password"]);

    if (value && value !== password) {
      return Promise.reject("Passwords do not match");
    } else {
      return Promise.resolve();
    }
  };

  const filterNonAlphabetical = (inputValue) => {
    return inputValue.replace(/[^a-zA-Z]/g, "");
  };

  const handleFirstNameChange = (e) => {
    const filteredValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ firstName: filteredValue });
  };

  const handleLastNameChange = (e) => {
    const filteredValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ lastName: filteredValue });
  };
  return (
    <>
      <div className={style.userPopup_section}>
        <div className={style.userPopup_container}>
          <div className={style.userPopup_close_image}>
            <img src={Close} alt="close image" onClick={closeHandler} />
          </div>
          <div className={style.userPopup_head}>add new admin support</div>
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="First Name"
              name="firstName"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
            >
              <Input
                placeholder="Enter your first name"
                onChange={handleFirstNameChange}
              />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Last Name"
              name="lastName"
              style={{ width: "100%" }}
            >
              <Input
                placeholder="Enter your last name"
                onChange={handleLastNameChange}
              />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Email"
              name="mail"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item
              // className={style.appointments_form_item}
              label="Password"
              name="password"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password
                placeholder="Enter your password"
                className={style.password_input}
                style={{ minHeight: "50px" }}
              />
            </Form.Item>
            <Form.Item
              // className={style.appointments_form_item}
              label="Confirm Password"
              name="confirm_password"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please input your confirm password!",
                },
                { validator: validateConfirmPassword },
              ]}
            >
              <Input.Password
                placeholder="Enter your confirm password"
                className={style.password_input}
                style={{ minHeight: "50px" }}
              />
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? <SmallLoader /> : "add admin support"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};
export default UserPopup;
