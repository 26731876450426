import  {post,get,_delete,put,formData,patch, clientId}  from '../TrelifAdmin';

const enquiresList = () => {
  return get('/enquires');
};
const enquiresRevert=(id)=>{
  return post(`enquires/revert/${id}`)
}
const enquiresStatusChange = (enquiresId,data) => {
  return patch(`/enquires/${enquiresId}`,data);
};

export default {
    enquiresList,
    enquiresRevert,
    enquiresStatusChange
};