import { Button, Modal } from "antd";

export default function PeekModal({ openModal, setOpenModal }) {
  return (
    <>
      <Modal
        title="Peek"
        centered
        open={openModal.open}
        onOk={() => setOpenModal({ ...openModal, text: "", open: false })}
        // onCancel={() => setOpenModal({ ...openModal, text: "", open: false })}
        width={1000}
        closeIcon={null}
        footer={[
          <div key="footer" style={{ textAlign: "center" }}>
            <Button
              key="ok"
              type="primary"
              onClick={() =>
                setOpenModal({ ...openModal, text: "", open: false })
              }
              style={{
                color: "white",
                background: "rgb(0, 160, 153)",
                fontWeight: "bold",
                fontFamily: "inherit",
              }}
            >
              OK
            </Button>
          </div>,
        ]}
      >
        <pre>{openModal.text}</pre>
      </Modal>
    </>
  );
}
