import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api";
const { userSignup, userLogin, userVerify } = API;

const initialState = {
  userId: "",
  error: null,
  loading: false,
  message: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setUserId(state, action) {
      state.userId = action.payload;
    },
  },
});

export const {
  setLoading,
  setMessage,
  setError,
  setUserId,
} = authSlice.actions;
export default authSlice.reducer;

export const authSignup = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await userSignup(data);
    if (res.data.success) {
      dispatch(setLoading(false));
      dispatch(setUserId(res?.data?.data.userId));
      dispatch(setMessage(res.data.message));
      // after check
      // dispatch(setError(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
      message.success(res.data?.message);
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const authUserVerify = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await userVerify(data);
    if (res.data.success) {
      dispatch(setLoading(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
      message.success(res.data?.message);
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const authLogin = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await userLogin(data);
    if (res.data.success) {
      dispatch(setLoading(false));
      dispatch(setMessage(res.data.message));
      // after check
      // dispatch(setError(res.data.message));
      message.success(res.data?.message);
      localStorage.setItem("token", res.data.data.access_token);
      localStorage.setItem("role_id", res.data.data.role_id);
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
      message.error(res.data?.message);
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.message));
    message.error(e.message);
  }
};

export const authLogout = () => async (dispatch) => {
  dispatch(setLoading(true));
  let token = localStorage.getItem("token");
  let role = localStorage.getItem("role_id");
  if (token && role) {
    return setTimeout(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("role_id");
      message.success("You have successfully logged out!");
      dispatch(setLoading(false));
    }, 1000);
  }
};
