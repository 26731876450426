import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const collectionsList = () => {
  return get('/collections');
};
const collectionsUpdate = (id,data) => {
  return put(`/collections/${id}`,data);
}


export default {
  collectionsList,
  collectionsUpdate
};