// NotFound.js
import React from 'react';
import style from "./notFound.module.css";
import Layout from '../../components/layout/Layout';
import { Button, Result } from 'antd';
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const errorPageHandler=()=>{
    navigate('/dashboard')
  }
  return (
    <Layout>
    <div className={style.error_page_section}>
   <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={errorPageHandler}>Back Dashboard</Button>}
  />
  </div>
    </Layout>

  );
};

export default NotFound;
