import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import style from "./newsletters.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewslettersData,
  newslettersDataDelete,
  statusChangeAction,
  newslettersMailSendAction,
  messageBlankAction,
} from "../../store/slices/newslettersSlice";
import { Space, Switch, Tag } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import DeleteImg from "../../assets/email.png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import RevertLoader from "../../components/revertLoader/RevertLoader";
import BlackLoader from "../../components/blackLoader/BloackLoader";
import NewLetterEmailPopup from "../../components/newLetterEmailPopup/NewLetterEmailPopup";

const Newsletters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newsletters.data);
  const loading = useSelector((state) => state.newsletters.loading);
  const deleteLoad = useSelector((state) => state.newsletters.deleteLoad);
  const statusLoader = useSelector((state) => state.newsletters.statusLoader);
  const message = useSelector((state) => state.newsletters.message);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [ids, setIds] = useState();
  const [show, setShow] = useState(false);
  const [pop, setPop] = useState();
  const [totalId, setTotalId] = useState();
  const [contentText, setContentText] = useState();

  useEffect(() => {
    dispatch(getNewslettersData());
  }, []);
  useEffect(() => {
    setState(data);
    let newData = data.map((item) => {
      return item._id;
    });
    setTotalId(newData);
  }, [data]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      id: "name",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Email",
      dataIndex: "email",
      id: "email",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                statusLoader && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : statusLoader && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Action",
      id: "action",
      render: (text, record) => (
        <Space size="middle">
          <button
            className={
              text.status === 1
                ? style.delete_edit_hand
                : style.delete_edit_hand_disabled
            }
            onClick={() => emailPopupHandler(record)}
            disabled={text.status !== 1 ? true : false}
          >
            <img src={DeleteImg} alt="edit image" />
            <div>send newsletter</div>
          </button>
          {show ? (
            <NewLetterEmailPopup
              callBack={contentHandler}
              onFinish={onFinish}
              closeHandler={closeHandler}
              label="Newsletter Send"
            />
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];
  const contentHandler = (text) => {
    setContentText(text);
  };
  const addHandler = () => {
    navigate("/newsletters/add-newsletters");
  };
  const emailPopupHandler = (record) => {
    setShow(true);
    setPop([record._id]);
    dispatch(messageBlankAction());
  };
  const allMailSendHandler = () => {
    // Ids of users that are active
    const listOfIdsToSend = state
      .filter((user) => user.status === 1)
      .map((user) => user._id);
    setShow(true);
    setPop(listOfIdsToSend);
    dispatch(messageBlankAction());
  };
  const onFinish = (values) => {
    //  const data={...values,userId:pop}
    const data = { title: values.title, content: contentText, userId: pop };
    dispatch(newslettersMailSendAction(data));
  };
  const closeHandler = () => {
    setShow(false);
    setPop();
  };
  useEffect(() => {
    if (message === "Newsletters send successfully!") {
      closeHandler();
    }
  }, [message]);
  return (
    <Layout>
      <div className={style.newsletters_section}>
        <PageHeader
          name="News letters details"
          btnName="subscribe newsletter"
          img={AddImg}
          addHandler={addHandler}
          addcustomerHandler={allMailSendHandler}
          image={DeleteImg}
          nameBtn="send newsletter to all"
          nextButton="true"
        />
        {loading ? (
          <BigLoader />
        ) : (
          <>
            <Tables datas={state} columns={columns} />
            <div style={{ textTransform: "capitalize" }}>
              total News letters -{" " + state ? state?.length : ""}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default Newsletters;
