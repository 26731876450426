import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout/Layout";
import Table from "../../components/tables/Tables";
import style from "./users.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import {
  getUsersData,
  statusChangeAction,
  messageBlankAction,
  getCustomerData,
  productsDetailBlank,
  errorBlankAction,
} from "../../store/slices/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import BigLoader from "../../components/bigLoader/BigLoader";
import moment from "moment";
import { Space, Switch, Tag } from "antd";
import RevertLoader from "../../components/revertLoader/RevertLoader";
import AddImg from "../../assets/icons8-add-24.png";
import UserPopup from "../../components/userPopup/UserPopup";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import ActionImg from "../../assets/actionImg.png";
import CustomerOrderPopup from "../../components/customerOrderPopup/CustomerOrderPopup";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import AddCustomer from "../../components/addCustomer/AddCustomer";
import AddressModal from "../../components/addressModal/AddressModal";
const { TextArea } = Input;
const Users = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.data);
  const loading = useSelector((state) => state.user.loading);
  const statusLoader = useSelector((state) => state.user.statusLoader);
  const [state, setState] = useState();
  const [ids, setIds] = useState();
  const [pop, setPop] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [openModal, setOpenModal] = useState({ data: [], open: false });
  const [shows, setShows] = useState(false);
  const [pops, setPops] = useState();
  const [cusPop, setCusPop] = useState(false);
  const searchInput = useRef(null);
  useEffect(() => {
    dispatch(getUsersData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    confirm({
      closeDropdown: false,
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, selectedKeys, confirm, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      align: "center",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      id: "firstName",
      ...getColumnSearchProps("firstName"),
      render: (firstName, data, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <div>
              {data.firstName} {data.lastName}
            </div>
            {data.role_id === 1 ? (
              <div>
                <Tag color="red">Admin</Tag>
              </div>
            ) : data.role_id === 2 ? (
              <div>
                <Tag color="purple">Admin Support</Tag>
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      id: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Type",
      dataIndex: "loginFrom",
      id: "loginFrom",
      align: "center",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      id: "createdAt",
      align: "center",
      render: (date) => <a>{moment(date).format("DD/MM/YYYY")}</a>,
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      id: "phone",
      align: "center",
      render: (firstName, data, index) => {
        return <div>{data.phone ? data.phone : "NA"}</div>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      id: "address",
      align: "center",
      render: (_, record) => {
        return (
          <Space>
            <div className={style.orders_section_product_pp}>
              <img
                src={ActionImg}
                onClick={() =>
                  setOpenModal({
                    ...openModal,
                    data: record.address,
                    open: true,
                  })
                }
                alt=""
              />
            </div>
          </Space>
        );
      },
    },
    // {
    //   title: 'DOB',
    //   dataIndex: 'dob',
    //   id: 'dob',
    //   render: (date) => <a>{moment(date).format('DD/MM/YYYY')}</a>,
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   id: "status",
    //   align: "center",
    //   render: (status, record, index) => {
    //     const onToggle = (checked) => {
    //       setIds(checked);
    //       dispatch(statusChangeAction(checked));
    //     };
    //     return (
    //       <Space>
    //         <Switch
    //           style={{ backgroundColor: status === 1 ? "#168C98" : "" }}
    //           checked={status === 1 ? true : false}
    //           onChange={() => onToggle(record._id)}
    //         />
    //         <div
    //           className={style.users_switch_btn}
    //           style={{
    //             border:
    //               status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
    //           }}
    //         >
    //           {status === 1 ? (
    //             statusLoader && ids === record._id ? (
    //               <RevertLoader />
    //             ) : (
    //               "active"
    //             )
    //           ) : statusLoader && ids === record._id ? (
    //             <RevertLoader />
    //           ) : (
    //             "inactive"
    //           )}
    //         </div>
    //       </Space>
    //     );
    //   },
    // },
    {
      title: "Action",
      id: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <div className={style.orders_section_product_pp}>
            {" "}
            <img
              src={ActionImg}
              onClick={() => productHandlerPop(record)}
              alt="image"
            />
          </div>
          {shows ? (
            <CustomerOrderPopup
              backHandler={closeHandlers}
              label="customer orders details"
            />
          ) : (
            ""
          )}
        </Space>
      ),
    },
    // {
    //   title: 'Tags',
    //   id: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'loser') {
    //           color = 'volcano';
    //         }
    //         return (
    //           <Tag color={color} id={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: 'Action',
    //   id: 'action',
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <a onClick={()=>actionHandler(record)}>Action</a>
    //     </Space>
    //   ),
    // },
  ];
  const addHandler = (record) => {
    setPop(true);
  };
  const addcustomerHandler = () => {
    setCusPop(true);
    dispatch(errorBlankAction());
  };
  const closeHandler = () => {
    setPop(false);
    dispatch(messageBlankAction());
  };
  const closeHandlerCustomer = () => {
    setCusPop(false);
    dispatch(messageBlankAction());
  };
  const productHandlerPop = (record) => {
    setShows(true);
    setPops(record._id);
    dispatch(getCustomerData(record._id));
  };
  const closeHandlers = () => {
    setShows(false);
    setPops();
    dispatch(productsDetailBlank());
  };
  return (
    <>
      <AddressModal openModal={openModal} setOpenModal={setOpenModal} />
      <Layout>
        <div className={style.users_section}>
          <PageHeader
            name="customers details"
            // btnName="add admin"
            // img={AddImg}
            // addHandler={addHandler}
            btnName="add customer"
            img={AddImg}
            addHandler={addcustomerHandler}
            // addcustomerHandler={addcustomerHandler}
            // image={AddImg}
            // nameBtn="add customer"
            // nextButton="true"
          />
          {/* <div className={style.customer_add_new} onClick={addcustomerHandler}>
          <img src={AddImg} alt="customer add image"/>
          <div>add customer</div>
        </div> */}
          {loading ? (
            <BigLoader />
          ) : (
            <>
              <Table datas={state} columns={columns} scrollVal={1200} />
              <div style={{ textTransform: "capitalize" }}>
                total users -{" " + state ? state?.length : ""}
              </div>
              {pop ? <UserPopup closeHandler={closeHandler} /> : ""}
              {cusPop ? (
                <AddCustomer closeHandler={closeHandlerCustomer} />
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Users;
