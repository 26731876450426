import  {post,get,_delete,put,formData, clientId, patch}  from '../TrelifAdmin';

const testimonialsList = () => {
  return get('/testimonials');
};
const testimonialsAdd = (data) => {
  return post('/testimonials',data);
};
const testimonialsUpdate = (id,data) => {
  return put(`/testimonials/${id}`,data);
};
const testimonialsDelete=(id)=>{
  return _delete(`/testimonials/${id}`)
}
const testimonialsStatus=(id)=>{
  return patch(`/testimonials/${id}`)
}
const testimonialsEdit=(id)=>{
  return get(`testimonials/specific-testimonial/${id}`)
}

export default {
    testimonialsList,
    testimonialsAdd,
    testimonialsUpdate,
    testimonialsDelete,
    testimonialsStatus,
    testimonialsEdit
};