import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const newslettersList = () => {
  return get('/newsletters');
};
const newslettersAdd = (data) => {
  return post('/newsletters',data);
};
const newslettersDelete=(id)=>{
  return _delete(`/newsletters/${id}`)
}
const newslettersStatusChange = (id) => {
  return post(`/newsletters/status/${id}`);
};
const newslettersSendMail = (data) => {
  return post('/newsletters/send-newsletter',data);
};


export default {
    newslettersList,
    newslettersAdd,
    newslettersDelete,
    newslettersStatusChange,
    newslettersSendMail
};