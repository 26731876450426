import React,{useState} from 'react'
import style from "./layout.module.css"
import Header from '../header/Header';
import Navbar from '../navbar/Navbar';

const Layout =({children})=>{
    const [child,setChild]=useState(false)
    const [search,setSearch]=useState('')
    const hammerHnadler=()=>{
        setChild(!child)
    }
    const changeHandler=(event)=>{
        setSearch(event.target.value)
    }
    return(
        <>
        <div className={style.layout_section}>
            <div className={style.layout_header}>
                <Header hammerHnadler={hammerHnadler} changeHandler={changeHandler}/>
            </div>
            <div className={style.layout_nav}>
                <Navbar active={child} value={search}/>
            </div>
            <div className={child?style.layout_child_inactive:style.layout_child}>{children}</div>
        </div>
        </>
    )
}
export default Layout;
