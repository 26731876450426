import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const homePageBottomSliderList = () => {
  return get('/settings/bottomslider');
};
const homePageBottomSliderAdd = (data) => {
  return post('/settings/bottomslider',data);
};
const homePageBottomSliderUpdate = (id,data) => {
  return put(`/settings/bottomslider/${id}`,data);
};
const homePageBottomSliderDelete=(id)=>{
  return _delete(`/settings/bottomslider/${id}`)
}
const homePageBottomSliderEdit=(id,field)=>{
  return get(`/settings/specific-settings/?id=${id}&fieldName=${field}`)
}


export default {
    homePageBottomSliderList,
    homePageBottomSliderAdd,
    homePageBottomSliderUpdate,
    homePageBottomSliderDelete,
    homePageBottomSliderEdit
};