import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {instoreList,instoreCustomerList,instoreDiscountList,instoreDiscountAdd,instoreStatusChange,instoreSearchList}=API

const initialState = { 
    instoreData:[],
    instoreCustomer:[],
    instoreDiscount:[],
    error: null, 
    loading:false,
    message:'',
    load:false
}

const instoreSlice = createSlice({
    name: "instore",
    initialState,
    reducers: {
      setInstoreData(state,action){
          state.instoreData=action.payload;
      },
      setInstoreCustomer(state,action){
        state.instoreCustomer=action.payload;
      },
      setInstoreDiscount(state,action){
        state.instoreDiscount=action.payload;
      },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
     },
     setRevertLoad(state,action){
          state.load=action.payload
     }
    },
  });
  
  export const {setRevertLoad, setLoading, setMessage,setError,setInstoreData,setInstoreCustomer,setInstoreDiscount} = instoreSlice.actions;
  export default instoreSlice.reducer;

  export const getInstoreData = () => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await instoreList()
      if (res.data.success) {
          dispatch(setInstoreData(res.data.data))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };

export const getInstoreSearchData = (name) => async (dispatch) => {
try {
 const res= await instoreSearchList(name)
 if (res.data.success) {
     dispatch(setInstoreData(res.data.data))
 } else {
     dispatch(setError(res.data.message));
 }
} catch (e){
 message.error(e.message);
}
};

export const getInstoreCustomer = () => async (dispatch) => {
try {
 const res= await instoreCustomerList()
 if (res.data.success) {
     dispatch(setInstoreCustomer(res.data.data))
     dispatch(setMessage(res.data.message))
     message.success(res.data?.message);
 } else {
     dispatch(setError(res.data.message));
 }
} catch (e){
 dispatch(setError(e.message));
 message.error(e.message);
}
};

export const getInstoreDiscount = () => async (dispatch) => {
  try {
   const res= await instoreDiscountList()
   if (res.data.success) {
       dispatch(setInstoreDiscount(res.data.data))
       dispatch(setMessage(res.data.message))
       message.success(res.data?.message);
   } else {
       dispatch(setError(res.data.message));
   }
  } catch (e){
   dispatch(setError(e.message));
   message.error(e.message);
  }
  };

  export const instoreDataAddAction = (data) => async (dispatch) => {
    dispatch(setLoading(true));
try {
 const res= await instoreDiscountAdd(data)
 if (res.data.success) {
    //  dispatch(setInstoreData(res.data.data))
     dispatch(setLoading(false));
     dispatch(setMessage(res.data.message))
     message.success(res.data?.message);
 } else {
     dispatch(setLoading(false));
     dispatch(setError(res.data.message));
 }
} catch (e){
 dispatch(setLoading(false));
 dispatch(setError(e.message));
 message.error(e.message);
}
};


export const instoreStatusChangeAction=(instoreId,data)=> async (dispatch)=>{
  dispatch(setRevertLoad(true));
try{
const res= await instoreStatusChange(instoreId,data)
if(res.data.success) {
  // dispatch(setstatus(data.enquiryStatus))
  // dispatch(setChnageStatus(enquiresId))
  dispatch(setRevertLoad(false))
  dispatch(setMessage(res.data.message))
  message.success(res.data?.message);
} else {
 dispatch(setRevertLoad(false));
 dispatch(setError(res.data.message));
 message.error(res.data.message);
}
} catch(e){
dispatch(setRevertLoad(false));
dispatch(setError(e.response.data.message));
message.error(e.response.data.message);
}
}

export const messageBlankAction=()=>(dispatch)=>{
       dispatch(setMessage())
}