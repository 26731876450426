import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import style from "./blogs.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogsData,
  blogDataDelete,
  sigleBlogDataAction,
  blankDataAction,
  statusChangeAction,
  editBlankData,
} from "../../store/slices/blogsSlice";
import { Space, Tag, Switch } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/editNew.svg";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import { mainUrl } from "../../utils/common";
import RevertLoader from "../../components/revertLoader/RevertLoader";
import parse from "html-react-parser";

const Blogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.blog.data);
  const loading = useSelector((state) => state.blog.loading);
  const deleteLoad = useSelector((state) => state.blog.deleteLoad);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [ids, setIds] = useState();

  useEffect(() => {
    dispatch(getBlogsData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      id: "title",
      width: 150,
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Content",
      dataIndex: "content",
      id: "content",
      width: 300,
      // render: (text) => (
      //   <p
      //     style={{
      //       maxHeight: "50vh",
      //       overflow: "hidden",
      //       textOverflow: "ellipsis",
      //       cursor: "pointer",
      //     }}
      //     dangerouslySetInnerHTML={{ __html: text }}
      //   >
      //     {/* {text} */}
      //   </p>
      // ),
      render: (text) => (
        <div
          style={{
            maxHeight: "4vh",
            maxWidth: "18ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        >
          {/* {text ? text : "NA"} */}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Display Image",
      dataIndex: "image",
      id: "image",
      render: (image) => (
        <div className={style.common_style_image}>
          <img
            crossOrigin="anonymous"
            src={image ? `${mainUrl()}/${image}` : "-"}
            alt="image"
          />
        </div>
      ),
    },
    {
      title: "Content Image",
      dataIndex: "optionalImage",
      id: "optionalImage",
      render: (image) => (
        <div className={style.common_style_image}>
          {image ? (
            <img
              crossOrigin="anonymous"
              src={image ? `${mainUrl()}/${image}` : "-"}
              alt="image"
            />
          ) : (
            <div style={{ marginTop: "20px", marginLeft: "20px" }}>N/A</div>
          )}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      id: "date",
      render: (date) => <a>{moment(date).format("DD/MM/YYYY")}</a>,
    },
    {
      title: "Action",
      id: "action",
      fixed: "right",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandler(record)}
          >
            <img src={EditImg} alt="edit image" />
            <div>edit</div>
          </div>
          {/* <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
              {deleteLoad && record._id=== deleteId ? <ColorLoader/>:
              <>
              <img src={DeleteImg} alt='edit image'/>
               <div>delete</div>
               </>
               }
              </div> */}
        </Space>
      ),
    },
  ];
  const addHandler = () => {
    dispatch(blankDataAction());
    dispatch(editBlankData());
    navigate("/blogs/add-blog");
  };
  const editHandler = (record) => {
    dispatch(sigleBlogDataAction(record._id));
    navigate(`/blogs/edit-blog/${record._id}`);
  };
  const deleteHandler = (record) => {
    setDeleteId(record._id);
    dispatch(blogDataDelete(record._id));
  };
  return (
    <Layout>
      <div className={style.blog_section}>
        <PageHeader
          name="blogs details"
          btnName="add blog"
          img={AddImg}
          addHandler={addHandler}
        />
        {loading ? (
          <BigLoader />
        ) : (
          <>
            <Tables datas={state} columns={columns} scrollVal={1200} />
            <div style={{ textTransform: "capitalize" }}>
              total blogs -{" " + state ? state?.length : ""}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default Blogs;
