import  {post,get,formData, clientId}  from '../TrelifAdmin';

const usersList = () => {
  return get('/users');
};
const usersStatusChange = (id) => {
  return post(`/users/status/${id}`);
};
const userAdd = (data) => {
  return post('/users/addUser',data);
};
const customerDetails = (id) => {
  return get(`/users/UserOrder/${id}`);
};
const customerAdd = (data) => {
  return post('/users/offline-signup',data);
};


export default {
    usersList,
    usersStatusChange,
    userAdd,
    customerDetails,
    customerAdd,
};