import React from 'react'
import style from "./button.module.css"
import Google from "../../assets/flat-color-icons_google.svg"
const Button = ({id,submitHandler,name,img}) => {
  return (
    <>
    <div className={style.button_section} id={id} onClick={submitHandler}>
      {img?<img src={Google} alt='google logo image'/>:""}
      <div>{name}</div>
    </div>
    </>
  )
}

export default Button