import React, { useState, useEffect } from "react";
import style from "./uspChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  uspDataAdded,
  uspDataUpdate,
  uspEditAction,
} from "../../store/slices/uspSlice";
import { activeManageAction } from "../../store/slices/settingsSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;

const UspChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.usp.deleteLoad);
  const message = useSelector((state) => state.usp.message);
  const singleUsp = useSelector((state) => state.usp.singleUsp);
  const editData = useSelector((state) => state.usp.editData);
  const [form] = Form.useForm();
  const urls = "/home/usp/add-usp";
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [addNewImg, setAddNewImg] = useState("");
  const [images, setImages] = useState("");
  const [newImages, setNewImages] = useState("");
  const [addNewImage, setAddNewImage] = useState("");
  const [messageNew, setMessageNew] = useState(""); // for Character limit

  useEffect(() => {
    setAddNewImage("");
    setAddNewImg("");
  }, []);
  const backHandler = () => {
    dispatch(activeManageAction(1));
    navigate("/home");
  };
  const onFinish = (values) => {
    let data = {
      title: values.title,
      message: values.message,
      heading: values.heading,
      icon: newImages ? newImages : images,
      contentImage: newImage ? newImage : image,
    };
    let datas = {
      title: values.title,
      message: values.message,
      heading: values.heading,
      icon: addNewImage,
      contentImage: addNewImg,
    };
    if (path === urls) {
      dispatch(uspDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(uspDataUpdate(singleUsp[0]?._id ? singleUsp[0]?._id : id, data));
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "USP added successfully!" ||
      message === "USP updated successfully!"
    ) {
      dispatch(activeManageAction(1));
      navigate("/home");
    }
  }, [message]);
  useEffect(() => {
    let data = singleUsp[0];
    form.setFieldValue("title", data?.title ? data?.title : "");
    form.setFieldValue("message", data?.message ? data?.message : "");
    form.setFieldValue("heading", data?.heading ? data?.heading : "");
    setNewImage(data?.contentImage.toString());
    setNewImages(data?.icon.toString());
  }, [singleUsp]);
  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(uspEditAction(id, "USP"));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    form.setFieldValue("title", data?.title ? data?.title : "");
    form.setFieldValue("message", data?.message ? data?.message : "");
    form.setFieldValue("heading", data?.heading ? data?.heading : "");
    setNewImage(data?.contentImage);
    setNewImages(data?.icon);
  }, [editData]);
  const deleteImgHandler = () => {
    setNewImage("");
  };
  const deleteImgHandlers = () => {
    setNewImages("");
  };

  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(info?.file?.response?.data?.image.toString());
        setNewImage(info?.file?.response?.data?.image.toString());
        setAddNewImg(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const propsUploads = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImages(info?.file?.response?.data?.image.toString());
        setAddNewImage(info?.file?.response?.data?.image.toString());
        setNewImages(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const deleteImgHandlerAdd = (image) => {
    setAddNewImg("");
  };
  const deleteImageHandlerAdd = (image) => {
    setAddNewImage("");
  };

  // for character limit
  const maxCharacters = 70;

  const handleChange = (event) => {
    const inputMessage = event.target.value;

    if (inputMessage.length <= maxCharacters) {
      setMessageNew(inputMessage);
    }
  };
  useEffect(() => {
    if (editData && editData.message) {
      setMessageNew(editData.message);
    }
  }, [editData]);
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            name={path === urls ? "usp add" : "usp update"}
            backButtonHandler={backHandler}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your title!",
                },
              ]}
            >
              <Input placeholder="Enter your title" />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Heading"
              name="heading"
              rules={[
                {
                  required: true,
                  message: "Please input heading!",
                },
              ]}
            >
              <TextArea rows={4} placeholder="heading here...." />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Message"
              name="message"
              rules={[
                {
                  required: true,
                  message: "Please input message!",
                },
              ]}
            >
              {/* <TextArea rows={4} placeholder="message here...."/> */}
              <div>
                <TextArea
                  rows={4}
                  placeholder="Message here..."
                  value={messageNew}
                  onChange={handleChange}
                />
                <p>{`${messageNew.length}/${maxCharacters} characters`}</p>
              </div>
            </Form.Item>
            <div className={style.images_dimensions}>
              the Content image dimensions should be (1560px)x(690px).
            </div>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
                width: "100%",
              }}
              label="Content Image"
              name="contentImage"
            >
              {path === urls ? (
                <>
                  {addNewImg ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={addNewImg ? `${mainUrl()}/${addNewImg}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImg ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImage ? `${mainUrl()}/${newImage}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {newImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      {newImage?.length < 1 && "+ Upload"}
                    </Upload>
                  )}
                </>
              )}
            </Form.Item>
            <div className={style.images_dimensions}>
              the Icon image dimensions should be (150px)x(150px).
            </div>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
                width: "100%",
              }}
              label="Icon Image"
              name="icon"
            >
              {path === urls ? (
                <>
                  {addNewImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={
                            addNewImage ? `${mainUrl()}/${addNewImage}` : "-"
                          }
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImageHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUploads}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImages ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImages ? `${mainUrl()}/${newImages}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlers}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Upload
                    action={trelifImageUpload}
                    listType="picture-card"
                    onPreview={() => {}}
                    showUploadList={false}
                    {...propsUploads}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newImages?.length < 1 && "+ Upload"}
                  </Upload>
                </>
              )}
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add usp"
                ) : (
                  "update usp"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default UspChange;
