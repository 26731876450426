import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {usersList,usersStatusChange,userAdd,customerDetails,customerAdd}=API

const initialState = { 
    data:[],
    customer:[],
    singleData:[],
    error: null, 
    loading:false,
    message:'',
    statusLoader:false
}

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
      setData(state,action){
          state.data=action.payload;
      },
      setCustomer(state,action){
        state.customer=action.payload;
    },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setMessageBlank(state,action){
        state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
    },
    setErrorBlank(state,action){
         state.error=action.payload;
    },
     setStatusLoading(state,action){
          state.statusLoader=action.payload
      },
      setUserData(state,action){
        state.data=[action.payload,...state.data]
      },
      setChnageStatus(state,action){
        state.singleData=state.data.filter((item)=>item._id == action.payload)
        state.singleData[0].status=state.singleData[0].status===1?0:state.singleData[0].status===0?1:''
      }
    },
  });
  
  export const {setCustomer,setUserData,setErrorBlank, setMessageBlank,setLoading, setMessage,setError,setData,setStatusLoading,setChnageStatus} = usersSlice.actions;
  export default usersSlice.reducer;
 export const messageBlankAction=()=> async (dispatch)=>{
          dispatch(setMessageBlank())
 }
 export const errorBlankAction=()=> async (dispatch)=>{
          dispatch(setErrorBlank())
}
  export const getUsersData = () => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await usersList()
      if (res.data.success) {
          dispatch(setData(res.data.data))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };

  export const getCustomerData = (id) => async (dispatch) => {
    dispatch(setStatusLoading(true));
try {
 const res= await customerDetails(id)
 if (res.data.success) {
     dispatch(setCustomer(res.data.data))
     dispatch(setStatusLoading(false));
     dispatch(setMessage(res.data.message))
     message.success(res.data?.message);
 } else {
     dispatch(setStatusLoading(false));
     dispatch(setError(res.data.message));
 }
} catch (e){
 dispatch(setStatusLoading(false));
 dispatch(setError(e.response.data.message));
 message.error(e.response.data.message);
}
};
export const productsDetailBlank = () => (dispatch) => {
       dispatch(setCustomer())
}
export const statusChangeAction = (id) => async (dispatch) => {
      dispatch(setStatusLoading(true));
     try {
     const res= await usersStatusChange(id)
      if (res.data.success) {
       dispatch(setChnageStatus(id))
       dispatch(setStatusLoading(false));
       dispatch(setMessage(res.data.message))
       message.success(res.data?.message);
 } else {
     dispatch(setStatusLoading(false));
     dispatch(setError(res.data.message));
 }
} catch (e){
 dispatch(setStatusLoading(false));
 dispatch(setError(e.response.data.message));
 message.error(e.response.data.message);
}
};

export const userAddAction = (data) => async (dispatch) => {
  dispatch(setStatusLoading(true));
 try {
 const res= await userAdd(data)
  if (res.data.success) {
   dispatch(setUserData(data))
   dispatch(setStatusLoading(false));
   dispatch(setMessage(res.data.message))
   message.success(res.data?.message);
} else {
 dispatch(setStatusLoading(false));
 dispatch(setError(res.data.message));
 message.success(res.data?.message);
}
} catch (e){
dispatch(setStatusLoading(false));
dispatch(setError(e.response.data.message));
message.error(e.response.data.message);
}
};

export const customerAddAction = (data) => async (dispatch) => {
  dispatch(setStatusLoading(true));
 try {
 const res= await customerAdd(data)
 const { mail: email, ...rest } = data; 
 const updatedObject = { email, ...rest };
  if (res.data.success) {
   dispatch(setUserData(updatedObject))
   dispatch(setStatusLoading(false));
   dispatch(setMessage(res.data.message))
   message.success(res.data?.message);
} else {
 dispatch(setStatusLoading(false));
 dispatch(setError(res.data.message));
 message.success(res.data?.message);
}
} catch (e){
dispatch(setStatusLoading(false));
dispatch(setError(e.response.data.message));
message.error(e.response.data.message);
}
};