import  {post,get,_delete,put,patch,formData, clientId}  from '../TrelifAdmin';

const blogsList = () => {
  return get('/blogs');
};
const blogsAdd = (data) => {
  return post('/blogs',data);
};
const blogsUpdate = (id,data) => {
  return put(`/blogs/${id}`,data);
};
const blogDelete=(id)=>{
  return _delete(`/blogs/${id}`)
}
const blogStatus=(id)=>{
  return patch(`/blogs/${id}`)
}
const blogEdit=(id)=>{
  return get(`blogs/specific-blog/${id}`)
}



export default {
    blogsList,
    blogsAdd,
    blogsUpdate,
    blogDelete,
    blogStatus,
    blogEdit
};