import React,{useState,useEffect} from "react"
import style from "./statusPopup.module.css"
import Close from "../../assets/icons8-close-24.png"
import {useSelector } from "react-redux"
import SmallLoader from "../smallLoader/SmallLoader"
import {
    Form,
    Input,
    Radio,
    Select,
    DatePicker,
    Row,
    Spin,
    Upload,
    Col,
    Button,
    AutoComplete,
    InputNumber,
    message,
    Divider,
    TimePicker
  } from "antd"
  
const StatusPopup=({closeHandler,label,onFinish,statusData})=>{
    const loading =useSelector((state)=>state.orders.deleteLoad)
    const [form] = Form.useForm()
    const onChange = (value) => {};
      const onSearch = (value) => {};
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const onFinishFailed = errorInfo => {}
    return (
        <>
        <div className={style.statusPopup_section}>
        <div className={style.statusPopup_section_container}>
         <div className={style.statusPopup_section_close}>
            <img src={Close} alt='close icon' onClick={closeHandler}/>
         </div>
         <div className={style.statusPopup_section_head}>{label}</div>
         <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              style={{width:"100%"}}
              label="status"
              name="updatedStatus"
              rules={[
                {
                  required: true,
                  message: "Please input your status!",
                },
              ]}
            >
            <Select
            className={style.appointments_form_select}
            showSearch
            placeholder="Select Order Status"
            optionFilterProp="children"
            onChange={onChange}
            onSearch={onSearch}
            filterOption={filterOption}
            options={statusData}
            />
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
                  <Button
                    className={style.appointments_form_submit}
                    // type="primary"
                    htmlType="submit"
                    style={{color:"#fff"}}
                  >
                  {loading?<SmallLoader/>:'status update'}
                  </Button>
                </Form.Item>
          </Form>
        </div>
        </div>
        </>
    )
}
export default StatusPopup;