import React,{useState,useEffect} from "react"
import style from "./homePage.module.css"
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch,useSelector } from "react-redux";
import {getHomePageData,homePageDataDelete,sigleHomePageDataAction,editBlankData} from "../../store/slices/homePageSlice"
import { Space, Tag } from 'antd';
import BigLoader from "../../components/bigLoader/BigLoader"
import AddImg from "../../assets/icons8-add-24.png"
import EditImg from "../../assets/editNew.svg"
import DeleteImg from "../../assets/icons8-delete-24 .png"
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import banner1 from "../../assets/images3.png"
import {mainUrl} from "../../utils/common"

const HomePage=()=>{
    const navigate=useNavigate()
    const dispatch=useDispatch();
    const data=useSelector((state)=>state.homePage.data)
    const loading=useSelector((state)=>state.homePage.loading)
    const deleteLoad=useSelector((state)=>state.homePage.deleteLoad)
    const [state,setState]=useState();
    const [deleteId,setDeleteId]=useState()

    useEffect(()=>{
        dispatch(getHomePageData())
    },[])
    useEffect(()=>{
       setState(data)
    },[data])
      const addHandler=()=>{
        dispatch(editBlankData())
        navigate('/home/home-page/add-home-page')
      }
      const editHandler=(record)=>{
        dispatch(sigleHomePageDataAction(record._id))
        navigate(`/home/home-page/edit-home-page/${record._id}`)
      }
      const deleteHandler=(record)=>{
          setDeleteId(record._id)
          dispatch(homePageDataDelete(record._id))
      }
    return(
        <div className={style.home_page_section}>
        <PageHeader name='home page details' count={state?state.length:''} total={true} btnName={data?.length == 1?'':'add home page'} img={AddImg} addHandler={addHandler}/>
        {loading?<BigLoader/>:
        state && state?.map((item,index)=>{
          return <div key={index} className={style.home_page_container}>
                 <div className={style.home_page_title}>title<br/><span>{item.title}</span></div>
                 <div className={style.home_page_video}>video<br/>
                    <video autoPlay muted loop>
                      <source src={item.video ? `${mainUrl()}/${item.video}` : "-"} type="video/mp4" />
                     </video>
                 </div>
                 <div className={style.home_page_btnn}>
                 <div className={style.delete_edit_hand_1} onClick={()=>editHandler(item)}>
                 <img src={EditImg} alt='edit image'/>
                 <div>edit</div>
                 </div>
                 <div className={style.delete_edit_hand} onClick={()=>deleteHandler(item)}>
                 {deleteLoad && item._id=== deleteId ? <ColorLoader/>:
                 <>
                 <img src={DeleteImg} alt='edit image'/>
                 <div>delete</div>
                 </>
                 }
                 </div>
                 </div>
                 </div>
        })
        }
        </div>
    )
}
export default HomePage;