import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/layout/Layout";
import style from "./orders.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons"; // for search
import Highlighter from "react-highlight-words"; // for search
import { useDispatch, useSelector } from "react-redux";
import {
  getOrdersData,
  ordersDataDelete,
  ordersStatusChangeAction,
  messageBlankAction,
  orederedProductDataAction,
} from "../../store/slices/ordersSlice";
import { Space, Tag, Input, Button } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import orderStatus from "../../assets/orderStatus.png";
import StatusPopup from "../../components/statusPopup/StatusPopup";
import ActionImg from "../../assets/actionImg.png";
import ProductDetailsPopup from "../../components/productDetailsPopup/ProductDetailsPopup";
import StatusBlack from "../../assets/statusBlack.png";
import AddressModal from "../../components/addressModal/AddressModal";

const Orders = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.orders.data);
  const loading = useSelector((state) => state.orders.loading);
  const deleteLoad = useSelector((state) => state.orders.deleteLoad);
  const message = useSelector((state) => state.orders.message);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();
  const [show, setShow] = useState(false);
  const [pop, setPop] = useState();
  const [shows, setShows] = useState(false);
  const [openModal, setOpenModal] = useState({ data: [], open: false });
  const [pops, setPops] = useState();
  const searchInput = useRef(null); //  for search
  const [searchText, setSearchText] = useState(""); // for search
  const [searchedColumn, setSearchedColumn] = useState(""); // for search

  //for search
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    confirm({
      closeDropdown: false,
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, selectedKeys, confirm, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  useEffect(() => {
    dispatch(getOrdersData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const statusData = [
    { id: 1, value: "Pending" },
    { id: 2, value: "Order Confirmed" },
    { id: 3, value: "Shipped" },
    { id: 4, value: "Out For Delivery" },
    { id: 5, value: "Delivered" },
    { id: 6, value: "Cancelled" },
  ];
  const columns = [
    {
      title: "OrderId",
      dataIndex: "orderId",
      id: "orderId",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => a.orderId.localeCompare(b.orderId),
      render: (text) => <a>{text ? text : "NA"}</a>,
      ...getColumnSearchProps("orderId"),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      id: "orderDate",
      render: (date) => (
        <a>{date ? moment(date).format("DD/MM/YYYY") : "NA"}</a>
      ),
    },
    // {
    //   title: "Estimate Date",
    //   dataIndex: "estimateDate",
    //   id: "estimateDate",
    //   render: (date) => (
    //     <a>{date ? moment(date).format("DD/MM/YYYY") : "NA"}</a>
    //   ),
    // },
    {
      title: "Email",
      dataIndex: "email",
      id: "email",
      render: (text) => <a>{text ? text : "NA"}</a>
    },
    {
      title: "Address",
      dataIndex: "address",
      id: "address",
      width: 150,
      align: "center",
      render: (_, record) => {

        // const addressId = record.addressId;
        const billingAddressId = record.billingAddressId;
        const shippingAddressId = record.shippingAddressId;
        const addressList = record.userId.address;

        // const addressObj = addressList.find((ele) => ele._id === addressId);
        const billingAddressObj = addressList.find((ele) => ele._id === billingAddressId);
        const shippingAddressObj = addressList.find((ele) => ele._id === shippingAddressId);
        const address = [];

        // if (typeof addressObj !== "undefined") {
        //   address.push(addressObj);
        // } else 

        // if ((typeof billingAddressObj !== "undefined"
        //   && typeof shippingAddressObj !== "undefined")
        //   && (billingAddressId === shippingAddressId)) {

        //   if (billingAddressObj.addressType === 'billing') {
        //     address.push(billingAddressObj)
        //   }

        //   if (shippingAddressObj.addressType === 'shipping') {
        //     address.push(shippingAddressObj)
        //   }

        // } else {
        //   if (typeof billingAddressObj !== "undefined") {
        //     address.push(billingAddressObj);
        //   }
        //   if (typeof shippingAddressObj !== "undefined") {
        //     address.push(shippingAddressObj);
        //   }
        // }

        if (billingAddressId === shippingAddressId) {
          if (billingAddressObj && billingAddressObj.addressType === 'billing') {
            address.push(billingAddressObj);
          }
          if (shippingAddressObj && shippingAddressObj.addressType === 'shipping') {
            address.push(shippingAddressObj);
          }
        } else {
          if (billingAddressObj) {
            address.push(billingAddressObj);
          }
          if (shippingAddressObj) {
            address.push(shippingAddressObj);
          }
        }

        return (
          <Space>
            <div className={style.orders_section_product_pp}>
              <img
                src={ActionImg}
                onClick={() =>
                  setOpenModal({
                    ...openModal,
                    data: address,
                    open: true,
                  })
                }
                alt=""
              />
            </div>
          </Space>
        );
      },
    },
    //   {
    //   title: 'Order Status Change',
    //   id: 'changeStatusOrder',
    //   width: 250,
    //   render: (_, record) => (
    //     <Space size="middle">
    //         <div className={style.delete_edit_hand_1} onClick={()=>editHandler(record)} style={{marginBottom:data?.length == 5?'5px':'0px',width:data?.length == 5?'100%':''}}>
    //         <img src={StatusBlack} alt='edit image'/>
    //         <div>Order status change</div>
    //        </div>
    //       {show?<StatusPopup statusData={statusData} onFinish={onFinish} closeHandler={closeHandler} label='order status change'/>:''}
    //     </Space>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      render: (status) => <a>{status ? status : "NA"}</a>,
    },
    {
      title: "Action",
      id: "action",
      fixed: "right",
      width: 100,
      render: (_, record) => (
        <Space size="middle">
          <div className={style.orders_section_product_pp}>
            {" "}
            <img
              src={ActionImg}
              onClick={() => productHandlerPop(record)}
              alt="image"
            />
          </div>
          {shows ? (
            <ProductDetailsPopup
              data={pops}
              backHandler={closeHandlers}
              label="ordered product details"
            />
          ) : (
            ""
          )}
        </Space>
      ),
    },
  ];

  const editHandler = (record) => {
    setShow(true);
    setPop(record);
    dispatch(messageBlankAction());
  };
  const closeHandler = () => {
    setShow(false);
    setPop();
  };

  const onFinish = (values) => {
    dispatch(ordersStatusChangeAction(pop._id, values));
  };

  const productHandlerPop = (record) => {
    setShows(true);
    setPops(record);
    dispatch(orederedProductDataAction(record.products));
  };
  const closeHandlers = () => {
    setShows(false);
    setPops();
  };

  useEffect(() => {
    if (message === "Product delivery status has been changed successfully!") {
      closeHandler();
    }
  }, [message]);
  return (
    <>
      <AddressModal openModal={openModal} setOpenModal={setOpenModal} />
      <Layout>
        <div className={style.blog_section}>
          <PageHeader name="orders details" />
          {loading ? (
            <BigLoader />
          ) : (
            <>
              <Tables datas={state} columns={columns} scrollVal={1000} />
              <div style={{ textTransform: "capitalize" }}>
                total orders -{" " + state ? state?.length : ""}
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Orders;
