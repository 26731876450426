import { Col, Form, Input, Modal, Row, Tag } from "antd";
import { useState } from "react";
import styles from "./ChangePasswordModal.module.css";
import { useForm } from "antd/es/form/Form";
import { adminPasswordChangeAction } from "../../store/slices/teamSlice";
import { useDispatch, useSelector } from "react-redux";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Paragraph";

export default function ChangePasswordModal({ openModal, setOpenModal }) {
  const [isNewPasswordEmpty, setIsNewPasswordEmpty] = useState(true);
  const [changePasswordForm] = useForm();
  const dispatch = useDispatch();
  const modalLoading = useSelector((state) => state.team.modalLoading);

  function handlePasswordChange(e) {
    const passwordValue = e.target.value;

    if (passwordValue.length) {
      setIsNewPasswordEmpty(false);
    } else {
      setIsNewPasswordEmpty(true);
    }
  }
  function handleCancel() {
    changePasswordForm.resetFields();
    setOpenModal({
      ...openModal,
      modalInfo: {},
      open: false,
    });
    setIsNewPasswordEmpty(true);
  }

  function handleFormSubmit() {
    const adminId = openModal.modalInfo._id;
    const newPassword = changePasswordForm.getFieldValue("newPassword");
    const confirmNewPassword = changePasswordForm.getFieldValue(
      "confirmNewPassword"
    );
    dispatch(
      adminPasswordChangeAction({
        idUser: adminId,
        new_password: newPassword,
        confirm_password: confirmNewPassword,
      })
    );
    changePasswordForm.resetFields();
  }
  return (
    <>
      <Modal
        title={
          <Row justify="center" align="middle">
            <Col>
              <Title level={4}>Change Password</Title>
            </Col>
          </Row>
        }
        centered
        open={openModal.open}
        onOk={handleFormSubmit}
        onCancel={handleCancel}
        okText={modalLoading ? "Changing" : "Change Password"}
        width={500}
        closeIcon={null}
        okButtonProps={{ loading: modalLoading }}
      >
        <Row
          className={styles.adminInfo}
          justify="center"
          align="middle"
          gutter={20}
        >
          <Col>
            <Text strong italic className={styles.adminName}>
              {openModal.modalInfo.firstName +
                " " +
                openModal.modalInfo.lastName}
            </Text>
          </Col>
          <Col>
            {openModal.modalInfo.role_id === 1 ? (
              <Tag color="red" className={styles.tag}>
                admin
              </Tag>
            ) : (
              <Tag color="purple" className={styles.tag}>
                admin support
              </Tag>
            )}
          </Col>
        </Row>
        <Form
          initialValues={{ newPassword: "", confirmNewPassword: "" }}
          form={changePasswordForm}
          name="changePassword"
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please input your new password!" },
            ]}
          >
            <Input.Password
              placeholder="Enter your new password"
              onChange={handlePasswordChange}
            />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirmNewPassword"
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm your new password"
              disabled={isNewPasswordEmpty}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
