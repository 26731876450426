import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const dashboardTotalDetails = () => {
  return get('/dashboards');
};



export default {
    dashboardTotalDetails,
};