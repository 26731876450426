import  {post,get,formData, clientId}  from '../TrelifAdmin';

const uploadImage = (data) => {
  return post('/media/add-image',data);
};

const uploadVideo = (data) => {
  return post('/media/add-video',data);
};


export default {
    uploadImage,
    uploadVideo,
};