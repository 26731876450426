import React, { useState, useEffect } from "react";
import style from "./homePageChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  homePageDataAdded,
  homePageDataUpdate,
  homePageEditAction,
} from "../../store/slices/homePageSlice";
import { activeManageAction } from "../../store/slices/settingsSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifVideoUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;

const HomePageChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.homePage.deleteLoad);
  const message = useSelector((state) => state.homePage.message);
  const singleHomePage = useSelector((state) => state.homePage.singleHomePage);
  const editData = useSelector((state) => state.homePage.editData);
  const [form] = Form.useForm();
  const urls = "/home/home-page/add-home-page";
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [video, setVideo] = useState([]);
  const [newVideo, setNewVideo] = useState();
  const [addNewVideo, setAddNewVideo] = useState();
  const [image, setImage] = useState();
  useEffect(() => {
    setAddNewVideo("");
  }, []);
  const propsUpload = {
    name: "video",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(info?.file?.response?.data?.video);
        setVideo(info?.file?.response?.data?.video);
        setNewVideo(info?.file?.response?.data?.video);
        setAddNewVideo(info?.file?.response?.data?.video);
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const backHandler = () => {
    dispatch(activeManageAction(5));
    navigate("/home");
  };
  const onFinish = (values) => {
    let data = { ...values, video: newVideo ? newVideo[0] : "" };
    let datas = { ...values, video: addNewVideo ? addNewVideo[0] : "" };
    // let data={title:values.title,video: image?image[0]:'',}
    if (path === urls) {
      dispatch(homePageDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(
        homePageDataUpdate(
          singleHomePage[0]?._id ? singleHomePage[0]?._id : id,
          data
        )
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "Home page added successfully!" ||
      message === "Home page updated successfully!"
    ) {
      dispatch(activeManageAction(5));
      navigate("/home");
    }
  }, [message]);
  useEffect(() => {
    let data = singleHomePage[0];
    form.setFieldValue("title", data?.title ? data?.title : "");
    form.setFieldValue("video", data?.video ? data?.video : "");
    let newAry = data?.video;
    setNewVideo([newAry]);
  }, [singleHomePage]);
  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(homePageEditAction(id, "HomePage"));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    form.setFieldValue("title", data?.title ? data?.title : "");
    form.setFieldValue("video", data?.video ? data?.video : "");
    let newAry = data?.video;
    // setNewVideo([newAry])
  }, [editData]);
  const deleteImgHandlerAdd = () => {
    setAddNewVideo("");
  };
  const deleteImgHandler = () => {
    setNewVideo("");
  };
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            name={path === urls ? "home page add" : "home page update"}
            backButtonHandler={backHandler}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your title!",
                },
              ]}
            >
              <Input placeholder="Enter your title" />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
                width: "100%",
              }}
              label="Home Page Video"
              name="video"
            >
              {/* <Upload
                action={trelifVideoUpload}
                listType="picture-card"
                {...propsUpload}
                headers={{
                  Authorization: `Bearer ${getToken()}`,
                }}
              > 
               {video.length <1 && '+ Upload'}
              </Upload> */}
              {path === urls ? (
                <>
                  {addNewVideo ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        {/* <img  src={addNewVideo?`${mainUrl()}/${addNewVideo}` : "-"} alt='edit image'/> */}
                        <video autoPlay muted loop>
                          <source
                            src={
                              addNewVideo ? `${mainUrl()}/${addNewVideo}` : "-"
                            }
                            type="video/mp4"
                          />
                        </video>
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewVideo ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifVideoUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newVideo ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        {/* <img  src={newVideo?`${mainUrl()}/${newVideo}` : "-"} alt='edit image'/> */}
                        <video autoPlay muted loop>
                          <source
                            src={newVideo ? `${mainUrl()}/${newVideo}` : "-"}
                            type="video/mp4"
                          />
                        </video>
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Upload
                    action={trelifVideoUpload}
                    listType="picture-card"
                    showUploadList={false}
                    onPreview={() => {}}
                    {...propsUpload}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newVideo?.length < 1 && "+ Upload"}
                  </Upload>
                </>
              )}
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add home page"
                ) : (
                  "update home page"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default HomePageChange;
