import React,{useState,useEffect} from 'react'
import style from "./settings.module.css"
import Layout from "../../components/layout/Layout"
import SettingsHeader from "../../components/settingsHeader/SettingsHeader"
import Usp from "../usp/index"
import TopHeaderSlider from "../topHeaderSlider/index"
import HomePageBottomSlider from "../homePageBottomSlider/index"
import ProjectSlider from "../projectSlider/index"
import HomePage from "../homePage/index.js"
import { useSelector } from 'react-redux'

const Settings=()=>{
    const activeManageData=useSelector((state)=>state.settings.data)
    const [active,setActive]=useState(1);
    const changeHandlerActive=(val)=>{
        setActive(val)
    }
    useEffect(()=>{
        if(activeManageData){
            setActive(activeManageData)
        }
    },[])
    return(
        <>
        <Layout>
        <div className={style.settings_section}>
        <SettingsHeader label='settings details' changeHandler={changeHandlerActive} active={active}/>
        {active ===0 && <div className={style.settings_panel_show}>please select any panels and show on the screen</div>}
        {active ===1 && <Usp/>}
        {active ===2 && <TopHeaderSlider/>}
        {active ===3 && <HomePageBottomSlider/>}
        {active ===4 && <ProjectSlider/>}
        {active ===5 && <HomePage/>}
        </div>
        </Layout>
        </>
    )
}
export default Settings;