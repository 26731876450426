import React, { useState } from "react"
import style from "./settingsHeader.module.css"

const SettingsHeader =({label,changeHandler,active})=>{
    const settingsLabel=[
        {id:1,name:"USP"},
        {id:2,name:"Top Header Slider"},
        {id:3,name:"Home page bottom slider"},
        {id:4,name:"project slider"},
        {id:5,name:"home page"},
    ]
    return(
        <>
        <div className={style.settings_header_section}>
            <div className={style.settings_header_left}>{label}</div>
            <div className={style.settings_header_right}>
            {settingsLabel.map((item,index)=>{
                return <div key={index} style={{background:item.id===active?'#168C98':"",color:item.id===active?'#fff':""}} className={style.setting_header_button} onClick={()=>changeHandler(item.id)}>{item.name}</div>
            })}
             </div>
        </div>
        </>
    )
}
export default SettingsHeader;