import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const uspList = () => {
  return get('/settings/usp');
};
const uspAdd = (data) => {
  return post('/settings/usp',data);
};
const uspUpdate = (id,data) => {
  return put(`/settings/usp/${id}`,data);
};
const uspDelete=(id)=>{
  return _delete(`/settings/usp/${id}`)
}
const uspEdit=(id,field)=>{
  return get(`/settings/specific-settings/?id=${id}&fieldName=${field}`)
}


export default {
    uspList,
    uspAdd,
    uspUpdate,
    uspDelete,
    uspEdit
};