export const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

export const mainUrl = () => {
  return process.env.REACT_APP_API_URL;
};

export const trelifImageUpload = () => {
  return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_IMAGE_UPLOAD_ENDPOINT}`;
};

export const baseURL = () => {
  return `${process.env.REACT_APP_API_BASE_URL}`;
}

export const trelifVideoUpload = () => {
  return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_VIDEO_UPLOAD_ENDPOINT}`;
};