import React from "react"
import style from "./backButton.module.css"
import BackImg from "../../assets/back.svg"

const BackButton=({backButtonHandler,name})=>{
    return(
        <>
        <div className={style.back_button_section}>
            <div className={style.back_button_left}>{name}</div>
            <div className={style.back_button_right} onClick={backButtonHandler}>
                <img src={BackImg} alt='back button'/>
                <div>back</div>
            </div>
        </div>
        </>
    )
}
export default BackButton;