import React, { useEffect, useState, useRef } from "react";
import Layout from "../layout/Layout";
import style from "./variantPopup.module.css";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import Tables from "../tables/Tables";
import EditImg from "../../assets/editNew.svg";
import { statusChangeAction } from "../../store/slices/productsSlice";
import RevertLoader from "../revertLoader/RevertLoader";
import CloseImg from "../../assets/smallClose.png";
import {
  productsDataUpdate,
  getCategoryData,
  productsEditAction,
  messageBlankActionVariant,
} from "../../store/slices/productsSlice";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
  Space,
  Switch,
} from "antd";
import BackButton from "../backButton/BackButton";
import EditVariant from "../editVariant/EditVariant";
const { TextArea } = Input;

const VariantPopup = ({ backHandler, data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteLoad = useSelector((state) => state.products.deleteLoad);
  const [form] = Form.useForm();
  const message = useSelector((state) => state.products.message);
  const categoryData = useSelector((state) => state.products.category);
  const editData = useSelector((state) => state.products.editData);
  const loading = useSelector((state) => state.products.deleteLoad);
  const [state, setState] = useState();
  const [ids, setIds] = useState();
  const [showEdit, setShowEdit] = useState(false);
  const [image, setImage] = useState([]);
  const [newImage, setNewImage] = useState([]);
  const [updateCategory, setUpdateCategory] = useState();
  const [variantId, setVariantId] = useState();
  const [finalData, setFinalData] = useState();
  const showPagination = false;

  const editRef = useRef(null);

  const scrollToElement = (ref) => {
    const { current } = ref;
    if (current !== null) {
      current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (showEdit) {
      scrollToElement(editRef);
    } else {
      editRef.current = null;
    }
  }, [showEdit, variantId]);

  const columns = [
    {
      title: "Product Name",
      width: 230,
      dataIndex: "name",
      id: "name",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Quantity",
      width: 100,
      dataIndex: "quantity",
      id: "quantity",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Category",
      width: 230,
      dataIndex: "category",
      id: "category",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Price",
      width: 100,
      dataIndex: "price",
      id: "price",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Size",
      width: 100,
      dataIndex: "size",
      id: "size",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Status",
      dataIndex: "status",
      id: "status",
      width: 200,
      render: (status, record, index) => {
        const onToggle = (checked) => {
          setIds(checked);
          dispatch(statusChangeAction(checked));
          const updatedArray = state.map((item) =>
            item._id === checked
              ? { ...item, status: status === 1 ? 0 : status === 0 ? 1 : "" }
              : item
          );
          setState(updatedArray);
        };
        return (
          <Space>
            <Switch
              style={{ background: status === 1 ? "#168C98" : "" }}
              checked={status === 1 ? true : false}
              onChange={() => onToggle(record._id)}
            />
            <div
              className={style.users_switch_btn}
              style={{
                border:
                  status === 1 ? "1px solid #168C98" : "1px solid #3E3636",
              }}
            >
              {status === 1 ? (
                deleteLoad && ids === record._id ? (
                  <RevertLoader />
                ) : (
                  "active"
                )
              ) : deleteLoad && ids === record._id ? (
                <RevertLoader />
              ) : (
                "inactive"
              )}
            </div>
          </Space>
        );
      },
    },
    {
      title: "Description",
      width: 250,
      dataIndex: "description",
      id: "description",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Image",
      dataIndex: "image",
      id: "image",
      width: 200,
      render: (_, { images }) => (
        <div className={style.designers_images_pa}>
          {images &&
            images.map((img, index) => {
              return (
                <div key={index} className={style.designers_images_st}>
                  <img
                    crossOrigin="anonymous"
                    src={img.image ? `${mainUrl()}/${img.image}` : "-"}
                    alt="image"
                  />
                </div>
              );
            })}
        </div>
      ),
    },

    {
      title: "Material",
      dataIndex: "material",
      id: "material",
      width: 200,
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Action",
      id: "action",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandlerVar(record)}
          >
            <img src={EditImg} alt="edit image" />
            <div>edit</div>
          </div>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    setState(data);
  }, [data]);
  const editHandlerVar = (record) => {
    dispatch(messageBlankActionVariant());
    setShowEdit(true);
    dispatch(productsEditAction(record._id));
    setVariantId(record._id);
  };
  const closeEditPopup = () => {
    setShowEdit(false);
  };
  useEffect(() => {
    if (message === "Product updated successfully!") {
      const updatedArray = state.map((item) =>
        item._id === variantId
          ? {
              ...item,
              name: finalData ? finalData.name : "",
              category: finalData ? finalData.category : "",
              size: finalData ? finalData.size : "",
              colour: finalData ? finalData.colour : "",
              price: finalData ? finalData.price : "",
              productType: finalData ? finalData.productType : "",
              quantity: finalData ? finalData.quantity : "",
              material: finalData ? finalData.material : "",
              details: finalData ? finalData.details : "",
              description: finalData ? finalData.description : "",
              specifications: finalData ? finalData.specifications : "",
              discountedPrice: finalData ? finalData.discountedPrice : "",
              discountType: finalData ? finalData.discountType : "",
              discount: finalData ? finalData.discount : "",
              images: finalData ? finalData.images : "",
            }
          : item
      );
      setState(updatedArray);
      //  setInterval(()=>{
      setShowEdit(false);
      //  },1000)
    }
  }, [message]);

  const onFinish = (values) => {
    let categoryId = "";
    const categorySelected = categoryData.find(
      (category) => category.title === values.category
    );

    if (typeof categorySelected !== "undefined") {
      categoryId = categorySelected._id;
    }
    let dataUpdate = {
      name: values.name,
      category: values.category,
      categoryId: categoryId,
      size: values.size,
      colour: values.colour,
      price: values.price,
      productType: values.type,
      quantity: values.quantity,
      material: values.material,
      details: values.details,
      description: values.description,
      specifications: values.specifications,
      discountedPrice: values.discount,
      discountType: values.discountType,
      discount: values.discountAdd,
      images: newImage ? newImage : image,
    };

    if (dataUpdate.discountType === "None") {
      dataUpdate["discount"] = 0;
    }
    setFinalData(dataUpdate);
    dispatch(productsDataUpdate(variantId, dataUpdate));
  };
  const onFinishFailed = (errorInfo) => {};
  function handleEditQuantityChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    form.setFieldsValue({ quantity: sanitizedValue });
  }
  function handleEditPriceChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]|(\.)\1+/g, "$1");

    form.setFieldsValue({ price: sanitizedValue });

    const pri = sanitizedValue;
    const disc = form.getFieldValue("discountAdd");
    const discType = form.getFieldValue("discountType");

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type" &&
      discType !== "None"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    } else if (discType !== "None") {
      discPrice = pri;
    }

    form.setFieldsValue({ discount: discPrice });
  }
  const onChangeSelect = (e) => {
    const pri = form.getFieldValue("price");
    const disc = form.getFieldValue("discountAdd");
    const discType = e;

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type" &&
      discType !== "None"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    } else if (discType === "None") {
      form.setFieldsValue({ discountAdd: "" });
      discPrice = pri;
    }

    form.setFieldsValue({ discount: discPrice });
  };
  const onSearch = (value) => {};
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const discountData = [
    { id: 1, value: "None" },
    { id: 2, value: "Percentage" },
    { id: 3, value: "Flat" },
  ];
  function handleEditDiscountChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]|(\.)\1+/g, "$1");

    form.setFieldsValue({ discountAdd: sanitizedValue });

    const pri = form.getFieldValue("price");
    const disc = sanitizedValue;
    const discType = form.getFieldValue("discountType");

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type" &&
      discType !== "None"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    } else if (discType === "None") {
      form.setFieldsValue({ discountAdd: "" });
      discPrice = pri;
    }

    form.setFieldsValue({ discount: discPrice });
  }
  const materialData = [
    { id: 1, value: "Suede" },
    { id: 2, value: "leatherette" },
    { id: 3, value: "linen" },
    { id: 4, value: "Laminate" },
    { id: 5, value: "Veneer" },
    { id: 6, value: "PVC" },
    { id: 7, value: "Acrylic Sheets" },
    { id: 8, value: "Marble" },
  ];
  // useEffect(() => {
  //   dispatch(getCategoryData());
  // }, []);
  useEffect(() => {
    // setCategory(categoryData);
    const newData = categoryData.map((item) => {
      return { value: item };
    });
    setUpdateCategory(newData);
  }, [categoryData]);
  const deleteImgHandler = (indexd) => {
    let images = [...newImage];
    let updateImages = images.filter((item, index) => index !== indexd);

    setNewImage(updateImages);
  };
  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        const updateImages = [
          ...image,
          {
            image: info?.file?.response?.data?.image.toString(),
          },
        ];
        const updateImagesNew = [
          ...newImage,
          {
            image: info?.file?.response?.data?.image.toString(),
          },
        ];
        setImage(updateImages);
        setNewImage(updateImagesNew);
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };

  useEffect(() => {
    let data = editData;
    form.setFieldValue("name", data?.name ? data?.name : "");
    form.setFieldValue("size", data?.size ? data?.size : "");
    form.setFieldValue(
      "description",
      data?.description ? data?.description : ""
    );
    form.setFieldValue("price", data?.price ? data?.price : "");
    form.setFieldValue("quantity", data?.quantity ? data?.quantity : "");
    form.setFieldValue("type", data?.productType ? data?.productType : "");
    form.setFieldValue("colour", data?.colour ? data?.colour : "");
    form.setFieldValue("details", data?.details ? data?.details : "");
    form.setFieldValue(
      "description",
      data?.description ? data?.description : ""
    );
    form.setFieldValue(
      "specifications",
      data?.specifications ? data?.specifications : ""
    );
    form.setFieldValue(
      "discount",
      data?.discountedPrice ? data?.discountedPrice : ""
    );
    form.setFieldValue("material", data?.material ? data?.material : "");
    form.setFieldValue("category", data?.category ? data?.category : "");
    form.setFieldValue(
      "discountType",
      data?.discountType ? data?.discountType : ""
    );
    form.setFieldValue("discountAdd", data?.discount ? data?.discount : "");
    // form.setFieldsValue({ images: data?.images?data?.images.map((item)=>item.image):''});

    // data?.images?.data?.images?.map(item=>setImage((previousImage)=>[...previousImage,item?.image]))

    setImage(data?.images ? data?.images : "");
    setNewImage(data?.images ? data?.images : "");
  }, [editData]);
  return (
    <>
      <div className={style.variant_data_show_section}>
        <BackButton name="Product Variants" backButtonHandler={backHandler} />

        <div className={style.variant_data_show_container}>
          <Tables
            datas={state}
            columns={columns}
            scrollVal={1700}
            showPagination={showPagination}
          />
          <div style={{ textTransform: "capitalize" }}>
            total variants -{" " + state ? state?.length : ""}
          </div>
          {/* {showEdit? <EditVariant backHandler={closeEditPopup}/>:""} */}
          {showEdit ? (
            <div ref={editRef} className={style.edit_variant_section}>
              <div className={style.variant_edit_update_form}>
                <div>Variants Update</div>
                <img
                  src={CloseImg}
                  alt="close image"
                  onClick={closeEditPopup}
                />
              </div>
              <div className={style.variant_edit_container}>
                <Form
                  className={style.appointments_form}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  layout={"vertical"}
                  form={form}
                  style={{
                    maxWidth: "100%",
                  }}
                >
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Name"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your product name" />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Size"
                    name="size"
                  >
                    <Input placeholder="Enter your product size" />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Quantity"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product quantity!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your product quantity"
                      onChange={handleEditQuantityChange}
                    />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Type"
                    name="type"
                  >
                    <Input placeholder="Enter your product type" />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_color_picker_edit}
                    label="Product Color"
                    name="colour"
                  >
                    <Input
                      style={{ height: "57px", marginTop: "-3px" }}
                      type="color"
                      placeholder="Enter your product color"
                    />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Specifications"
                    name="specifications"
                  >
                    <Input placeholder="Enter your product specifications" />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Price"
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product price!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your product price"
                      // onChange={updateDiscountHanddlerPrice}
                      onChange={handleEditPriceChange}
                    />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Discount Type"
                    name="discountType"
                  >
                    <Select
                      className={style.appointments_form_item}
                      style={{
                        marginTop: "-5px",
                        border: " 1px solid #3E3636",
                        borderRadius: "5px",
                      }}
                      showSearch
                      placeholder="Enter your discount type"
                      optionFilterProp="children"
                      onChange={onChangeSelect}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={discountData}
                    />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="discount"
                    name="discountAdd"
                  >
                    <Input
                      placeholder="Enter your product discount"
                      // onChange={updateDiscountHanddler}
                      onChange={handleEditDiscountChange}
                    />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Discounted Price"
                    name="discount"
                  >
                    <Input placeholder="Enter your product discount" disabled />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Material"
                    name="material"
                  >
                    {/* <Input placeholder="Enter your product material"/> */}
                    <Select
                      className={style.appointments_form_item}
                      style={{
                        marginTop: "-5px",
                        border: " 1px solid #3E3636",
                        borderRadius: "5px",
                      }}
                      showSearch
                      placeholder="Enter your product material"
                      optionFilterProp="children"
                      onChange={onChangeSelect}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={materialData}
                    />
                  </Form.Item>
                  {/* <Form.Item
             className={style.appointments_form_item}
             label="Product Category"
             name="category"
             rules={[
               {
                 required: true,
                 message: "Please input your product category!",
               },
             ]}
           >
           <Input placeholder="Enter your product category"/>
           </Form.Item> */}

                  <Form.Item
                    className={style.appointments_form_item}
                    label="Product Category"
                    name="category"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product Category!",
                      },
                    ]}
                  >
                    <Select
                      className={style.appointments_form_item}
                      style={{
                        marginTop: "-5px",
                        border: " 1px solid #3E3636",
                        borderRadius: "5px",
                      }}
                      showSearch
                      placeholder="Enter your product category"
                      optionFilterProp="children"
                      onChange={onChangeSelect}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={updateCategory}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    style={{ width: "100%" }}
                    label="Details"
                    name="details"
                  >
                    <TextArea rows={4} placeholder="details here...." />
                  </Form.Item>
                  <Form.Item
                    className={style.appointments_form_item}
                    style={{ width: "100%" }}
                    label="Description"
                    name="description"
                  >
                    <TextArea rows={4} placeholder="description here...." />
                  </Form.Item>
                  <div className={style.images_dimensions}>
                    the images dimensions should be (1300px)x(550px).
                  </div>
                  <Form.Item
                    className={style.appointments_form_item_image}
                    style={{
                      background: "#fff",
                      borderRadius: "7px",
                      paddingLeft: "5px",
                      width: "100%",
                    }}
                    label="Image"
                    name="image"
                  >
                    <div className={style.common_edit_image_antd}>
                      {newImage?.length > 0
                        ? newImage &&
                          newImage.map((item, index) => {
                            return (
                              <div
                                className={
                                  style.common_edit_image_antd_container
                                }
                                key={index}
                              >
                                <img
                                  src={
                                    newImage
                                      ? `${mainUrl()}/${item.image}`
                                      : "-"
                                  }
                                  alt="edit image"
                                />
                                <div className={style.common_edit_delt_antd}>
                                  <img
                                    src={DeleteImg}
                                    alt="delete image"
                                    onClick={() => deleteImgHandler(index)}
                                  />
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                    <div>
                      <Upload
                        action={trelifImageUpload}
                        listType="picture-card"
                        showUploadList={false}
                        onPreview={() => {}}
                        {...propsUpload}
                        headers={{
                          Authorization: `Bearer ${getToken()}`,
                        }}
                      >
                        {newImage.length < 10 && "+ Upload"}
                      </Upload>
                    </div>
                  </Form.Item>
                  <Form.Item className={style.appointments_form_submit_all}>
                    <Button
                      className={style.appointments_form_submit}
                      // type="primary"
                      htmlType="submit"
                      style={{ color: "#fff" }}
                    >
                      {loading ? <SmallLoader /> : "update products"}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default VariantPopup;
