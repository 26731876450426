import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../components/layout/Layout";
import style from "./inStore.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstoreData,
  messageBlankAction,
  instoreStatusChangeAction,
  getInstoreSearchData,
} from "../../store/slices/instoreSlice";
import BackImg from "../../assets/back.svg";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { Space, Tag } from "antd";
import { Collapse } from "antd";
import Arrow from "../../assets/down_arrow_icon.svg";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import { useNavigate } from "react-router";
import ManualStatus from "../../components/manualStatus/ManualStatus";
import ActionImg from "../../assets/actionImg.png";
import PeekModal from "../../components/peekModal/PeekModal";

const InStore = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.instore.instoreData);
  const loading = useSelector((state) => state.instore.loading);
  const message = useSelector((state) => state.instore.message);
  const [state, setState] = useState();
  const [show, setShow] = useState(false);
  const [long, setLong] = useState(1);
  const [showStatus, setShowStatus] = useState(false);
  const [ordId, setOrdId] = useState();
  const [statusName, setStatusName] = useState();
  const [searchName, setSearchName] = useState();
  const [openModal, setOpenModal] = useState({ text: "", open: false });
  const showPagination = false;

  const accordionHandler = (item) => {
    setLong(item._id);
    item._id === long ? setShow(!show) : setShow(true);
  };

  function formatAddress(address) {
    return `${address?.streetAddress}, ${address?.city}, ${address?.state}, ${address?.zipCode}`;
  }

  function extractAddresses(order) {
    const billingAddress = order.address.find(addr => addr._id === order.billingAddressId);
    const shippingAddress = order.address.find(addr => addr._id === order.shippingAddressId);

    return {
      billing: billingAddress ? formatAddress(billingAddress) : "N/A",
      shipping: shippingAddress ? formatAddress(shippingAddress) : "N/A"
    };
  }
  function addMailToProducts(order) {
    return order?.customerEmail
  }

  const modifiedOrders = useMemo(() => {
    return data.map((order) => {
      const addresses = extractAddresses(order);
      const customerEmail = addMailToProducts(order);
      const productsWithAddresses = order.products.map((product) => ({
        ...product,
        addresses,
        customerEmail
      }));
      return {
        ...order,
        products: productsWithAddresses,
      };
    });
  }, [data]);


  useEffect(() => {
    dispatch(getInstoreData());
  }, []);
  useEffect(() => {
    setState(modifiedOrders);
  }, [modifiedOrders]);
  const columns = [
    {
      title: "Product Name",
      dataIndex: "productName",
      id: "productName",
      align: "center",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      id: "description",
      align: "center",
      // render: (text) => <a>{text ? text : "NA"}</a>,
      render: (text) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              maxHeight: "4vh",
              maxWidth: "18ch",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text ? text : "NA"}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setOpenModal({ ...openModal, text: text, open: true })
            }
          >
            <img src={ActionImg} alt="Open" />
          </div>
        </div>
      ),
    },
    {
      title: "Addresses",
      dataIndex: "addresses",
      id: "addresses",
      align: "center",
      // render: (text) => <a>{text ? text : "NA"}</a>,
      render: (addresses) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              maxHeight: "4vh",
              maxWidth: "18ch",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {/* {text ? text : "NA"} */}
            {addresses ? (
              <>
                <div>Billing Address: {addresses?.billing}</div>
                <div>Shippping Address: {addresses?.shipping}</div>
              </>
            ) : (
              "NA"
            )}
          </div>
          <div
            style={{ cursor: "pointer" }}

            onClick={() =>
              setOpenModal({
                ...openModal,
                text: `Billing Address: ${addresses?.billing}
              \nShipping Address: ${addresses?.shipping}`,
                open: true
              })
            }
          >
            <img src={ActionImg} alt="Open" />
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      id: "customerEmail",
      align: "center",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Price",
      dataIndex: "price",
      id: "price",
      align: "center",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      id: "quantity",
      align: "center",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Total",
      dataIndex: "totalOfProduct",
      id: "totalOfProduct",
      align: "center",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
  ];
  const addHandler = () => {
    // dispatch(blankDataAction());
    // dispatch(editBlankData());
    navigate("/instore/add-order");
  };
  const statusData = [
    { id: 1, value: "Out for delivery" },
    { id: 2, value: "Delivered" },
  ];
  const manualOrderStatusChange = (item) => {
    setOrdId(item._id);
    setShowStatus(true);
    dispatch(messageBlankAction());
  };
  const closeHandlerStatusChange = () => {
    setShowStatus(false);
    setOrdId();
    setStatusName();
  };
  const onFinish = (values) => {
    setStatusName(values.status);
    dispatch(instoreStatusChangeAction(ordId, values));
  };
  useEffect(() => {
    if (message === "Order status updated successfully!") {
      const updatedArray = state.map((item) =>
        item._id === ordId ? { ...item, status: statusName } : item
      );
      setState(updatedArray);
      closeHandlerStatusChange();
    }
  }, [message]);

  const searchNameHandler = (e) => {
    dispatch(getInstoreSearchData(e.target.value));
  };
  return (
    <>
      <PeekModal openModal={openModal} setOpenModal={setOpenModal} />
      <Layout>
        <div className={style.instore_section}>
          <PageHeader
            name="instore"
            btnName="add order"
            img={AddImg}
            addHandler={addHandler}
          />
          <div className={style.order_id_customer_search}>
            <input
              type="text"
              name="searchName"
              value={searchName}
              onChange={searchNameHandler}
              placeholder="search customer name "
            />
          </div>
          {loading ? (
            <BigLoader />
          ) : (
            <div className={style.ticket_details_accordion}>
              <div className={style.contact_section}>
                <div className={style.contact_container}>
                  {state &&
                    state.map((item, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            // className={
                            //   show && item._id === long
                            //     ? style.contact_accordion_active
                            //     : style.contact_accordion
                            // }
                            className={style.contact_accordion_active}
                          >
                            <div
                              className={style.contact_accordion_head}
                              onClick={() => accordionHandler(item)}
                              style={{
                                // height: show && item._id === long ? "7vh" : "10vh",
                                height: "7vh",
                                borderBottom: "1px solid #8B8FAE",
                              }}
                            >
                              <h3>
                                {" "}
                                ORDER ID - {item.orderID ? item.orderID : "NA"}
                              </h3>
                              <div className={style.order_manual_status}>
                                <div className={style.order_manual_status_name}>
                                  Status - {item.status ? item.status : "NA"}
                                </div>
                                <div
                                  className={style.order_manual_status_change}
                                  onClick={() => manualOrderStatusChange(item)}
                                >
                                  status change
                                </div>
                              </div>
                              {/* <img
                              src={Arrow}
                              alt="icon forword"
                              // style={{
                              //   transform:
                              //     show && item._id === long ? "rotate(180deg)" : "",
                              // }}
                            /> */}
                            </div>
                            <div
                              // className={
                              //   show && item._id === long
                              //     ? style.contact_accordion_desc_active
                              //     : style.contact_accordion_desc
                              // }
                              className={style.contact_accordion_desc_active}
                            >
                              <div className={style.orderNew_label}>
                                Customer Name -{" "}
                                {item.customerName ? item.customerName : "NA"}
                              </div>
                              <Tables
                                datas={item.products ? item.products : ""}
                                columns={columns}
                                showPagination={showPagination}
                              />
                              <div className={style.orderNew_label}>
                                Total Price -{" "}
                                {item.totalPrice ? item.totalPrice : "NA"}
                              </div>
                              <div className={style.orderNew_label}>
                                Discount Code - {item.code ? item.code : "NA"}
                              </div>
                              <div className={style.orderNew_label}>
                                Discount Type -{" "}
                                {item.discountType ? item.discountType : "NA"}
                              </div>
                              <div className={style.orderNew_label}>
                                Discount Value -{" "}
                                {item.discount ? item.discount : "NA"}
                              </div>
                              <div className={style.orderNew_label}>
                                Final Price -{" "}
                                {item.finalPrice ? item.finalPrice : "NA"}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
        {showStatus ? (
          <ManualStatus
            statusData={statusData}
            onFinish={onFinish}
            closeHandler={closeHandlerStatusChange}
            label="Order status change"
          />
        ) : (
          ""
        )}
      </Layout>
    </>
  );
};
export default InStore;
