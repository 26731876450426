import { Component, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';
import style from "./textEditar.module.css"

Quill.register('modules/imageResize', ImageResize);

const TextEditar=({callBack})=>{
  const [state,setState]=useState()
  const  modules  = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
};

const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video'
                ];
 const onChange = (newVal) => {
        setState(newVal);
        callBack(newVal)
 };
  return(
    <ReactQuill
    // theme={this.state.theme}
    onChange={onChange}
    value={state}
    modules={modules}
    formats={formats}
    bounds={'#root'}
    placeholder={'content here....'}
    className={style.quill_container}
  />
  )
}


export default TextEditar;
