import React, { useState, useEffect } from "react";
import style from "./categorysChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  categorysDataAdded,
  categorysDataUpdate,
  categorysEditAction,
} from "../../store/slices/categorysSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
  Typography,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;
const { Text } = Typography;

const CategorysChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.categorys.deleteLoad);
  const message = useSelector((state) => state.categorys.message);
  const singleCategorys = useSelector(
    (state) => state.categorys.singleCategorys
  );
  const editData = useSelector((state) => state.categorys.editData);
  const [form] = Form.useForm();
  const urls = "/categories/add-categories";
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [addNewImage, setAddNewImage] = useState("");
  const [titleNew, setTitleNew] = useState(""); // for Character limit
  const [headingNew, setHeadingNew] = useState(""); // for Character limit
  const [descNew, setDescNew] = useState(""); // for Character limit

  useEffect(() => {
    setAddNewImage("");
  }, []);

  // for Character limit
  useEffect(() => {
    if (editData && editData.title) {
      setTitleNew(editData.title);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.heading) {
      setHeadingNew(editData.heading);
    }
  }, [editData]);
  useEffect(() => {
    if (editData && editData.description) {
      setDescNew(editData.description);
    }
  }, [editData]);

  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(info?.file?.response?.data?.image.toString());
        setNewImage(info?.file?.response?.data?.image.toString());
        setAddNewImage(info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const onChangeSelect = () => {};
  const onSearch = (value) => {};
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const collectionsData = [
    { value: "living room collections" },
    { value: "bed room collections" },
    { value: "dining room collections" },
  ];
  const backHandler = () => {
    navigate("/categories");
  };
  const onFinish = (values) => {
    let data = {
      title: values.title,
      collections: values.collections,
      heading: values.heading,
      description: values.description,
      image: newImage ? newImage : image,
    };
    let datas = {
      title: values.title,
      collections: values.collections,
      heading: values.heading,
      description: values.description,
      image: addNewImage,
    };
    if (path === urls) {
      dispatch(categorysDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(
        categorysDataUpdate(
          singleCategorys[0]?._id ? singleCategorys[0]?._id : id,
          data
        )
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "New category added successfully!" ||
      message === "Category updated successfully!"
    ) {
      navigate("/categories");
    }
  }, [message]);
  useEffect(() => {
    let data = singleCategorys[0];
    // form.setFieldValue("title",data?.title?data?.title:'')
    form.setFieldValue(
      "collections",
      data?.collections ? data?.collections : ""
    );
    // form.setFieldValue("heading",data?.heading?data?.heading:'')
    // form.setFieldValue("description",data?.description?data?.description:'')
    setNewImage(data?.image.toString());
  }, [singleCategorys]);

  // for Character limit
  useEffect(() => {
    form.setFieldValue("title", titleNew);
  }, [titleNew]);
  useEffect(() => {
    form.setFieldValue("heading", headingNew);
  }, [headingNew]);
  useEffect(() => {
    form.setFieldValue("description", descNew);
  }, [descNew]);

  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(categorysEditAction(id));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    // form.setFieldValue("title",data?.title?data?.title:'')
    form.setFieldValue(
      "collections",
      data?.collections ? data?.collections : ""
    );
    // form.setFieldValue("heading",data?.heading?data?.heading:'')
    // form.setFieldValue("description",data?.description?data?.description:'')
    setNewImage(data?.image);
  }, [editData]);
  const deleteImgHandler = () => {
    setNewImage("");
  };
  const deleteImgHandlerAdd = () => {
    setAddNewImage("");
  };
  // for title limit
  const maxTCharacters = 20;
  const handleTitleLimitChange = (e) => {
    const titleNew = e.target.value;
    if (titleNew.length <= maxTCharacters) {
      setTitleNew(titleNew);
    }
  };
  // for heading limit
  const maxHCharacters = 20;
  const handleHeadingLimitChange = (e) => {
    const headingNew = e.target.value;
    if (headingNew.length <= maxHCharacters) {
      setHeadingNew(headingNew);
    }
  };
  // for description limit
  const maxDCharacters = 300;
  const handleDescLimitChange = (e) => {
    const descNew = e.target.value;
    if (descNew.length <= maxDCharacters) {
      setDescNew(descNew);
    }
  };

  return (
    <>
      <Layout>
        <div className={style.categorysChange_section}>
          <BackButton
            name={path === urls ? "categories add" : "categories update"}
            backButtonHandler={backHandler}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your title!",
                },
              ]}
            >
              <Input
                value={titleNew}
                onChange={handleTitleLimitChange}
                placeholder="Enter your title"
              />
              <Text
                type={titleNew.length > maxTCharacters ? "danger" : "secondary"}
              >
                {`${titleNew.length} / ${maxTCharacters} characters`}
              </Text>
              {/* <Input placeholder="Enter your title" /> */}
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Collections"
              name="collections"
              rules={[
                {
                  required: true,
                  message: "Please input your collections!",
                },
              ]}
            >
              {/* <Input placeholder="Enter your collections" /> */}
              <Select
                className={style.appointments_form_item}
                style={{
                  marginTop: "-5px",
                  border: " 1px solid #3E3636",
                  borderRadius: "5px",
                }}
                showSearch
                placeholder="Enter your collections"
                optionFilterProp="children"
                onChange={onChangeSelect}
                onSearch={onSearch}
                filterOption={filterOption}
                options={collectionsData}
              />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Heading"
              name="heading"
              rules={[
                {
                  required: true,
                  message: "Please input heading!",
                },
              ]}
            >
              <TextArea
                value={headingNew}
                onChange={handleHeadingLimitChange}
                rows={2}
                placeholder="heading here...."
              />
              <Text
                type={
                  headingNew.length > maxHCharacters ? "danger" : "secondary"
                }
              >
                {`${headingNew.length} / ${maxHCharacters} characters`}
              </Text>
              {/* <TextArea rows={2} placeholder="heading here...."/> */}
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input description!",
                },
              ]}
            >
              <TextArea
                value={descNew}
                onChange={handleDescLimitChange}
                rows={4}
                placeholder="description here...."
              />
              <Text
                type={descNew.length > maxDCharacters ? "danger" : "secondary"}
              >
                {`${descNew.length} / ${maxDCharacters} characters`}
              </Text>
              {/* <TextArea rows={4} placeholder="description here...."/> */}
            </Form.Item>
            <div className={style.images_dimensions}>
              the image dimensions should be (1640px)x(605px).
            </div>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
                width: "100%",
              }}
              label="Image"
              name="image"
            >
              {path === urls ? (
                <>
                  {addNewImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={
                            addNewImage ? `${mainUrl()}/${addNewImage}` : "-"
                          }
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImage ? (
                    ""
                  ) : (
                    <Upload
                      action={trelifImageUpload}
                      listType="picture-card"
                      onPreview={() => {}}
                      showUploadList={false}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImage ? `${mainUrl()}/${newImage}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Upload
                    action={trelifImageUpload}
                    listType="picture-card"
                    showUploadList={false}
                    onPreview={() => {}}
                    {...propsUpload}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newImage?.length < 1 && "+ Upload"}
                  </Upload>
                </>
              )}
            </Form.Item>
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add categories"
                ) : (
                  "update categories"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default CategorysChange;
