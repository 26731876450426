import React, { useState, useEffect } from "react";
import style from "./editVariant.module.css";
import BackButton from "../backButton/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import SmallLoader from "../smallLoader/SmallLoader";
import {
  productsDataUpdate,
  getCategoryData,
  productsEditAction,
} from "../../store/slices/productsSlice";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
  Space,
  Switch,
} from "antd";
const { TextArea } = Input;
const EditVariant = ({ backHandler }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const categoryData = useSelector((state) => state.products.category);
  const editData = useSelector((state) => state.products.editData);
  const loading = useSelector((state) => state.products.deleteLoad);
  const [image, setImage] = useState([]);
  const [newImage, setNewImage] = useState([]);
  const [updateCategory, setUpdateCategory] = useState();

  const onFinish = (values) => {
    // let idData = path.split("/");
    // let id = idData[idData.length - 1];
    let dataUpdate = {
      name: values.name,
      category: values.category,
      size: values.size,
      colour: values.colour,
      price: values.price,
      productType: values.type,
      quantity: values.quantity,
      material: values.material,
      details: values.details,
      specifications: values.specifications,
      discountedPrice: values.discount,
      discountType: values.discountType,
      discount: values.discountAdd,
      images: newImage ? newImage : image,
    };
    dispatch(productsDataUpdate(dataUpdate));
  };
  const onFinishFailed = (errorInfo) => {};
  function handleEditQuantityChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    form.setFieldsValue({ quantity: sanitizedValue });
  }
  function handleEditPriceChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]|(\.)\1+/g, "$1");

    form.setFieldsValue({ price: sanitizedValue });

    const pri = sanitizedValue;
    const disc = form.getFieldValue("discountAdd");
    const discType = form.getFieldValue("discountType");

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    }

    form.setFieldsValue({ discount: discPrice });
  }
  const onChangeSelect = (e) => {
    const pri = form.getFieldValue("price");
    const disc = form.getFieldValue("discountAdd");
    const discType = e;

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    }

    form.setFieldsValue({ discount: discPrice });
  };
  const onSearch = (value) => {};
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const discountData = [
    { id: 1, value: "Percentage" },
    { id: 2, value: "Flat" },
  ];
  function handleEditDiscountChange(e) {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^0-9.]|(\.)\1+/g, "$1");

    form.setFieldsValue({ discountAdd: sanitizedValue });

    const pri = form.getFieldValue("price");
    const disc = sanitizedValue;
    const discType = form.getFieldValue("discountType");

    let discPrice = "";

    if (
      Number(disc) > 0 &&
      Number(pri) > 0 &&
      discType.length &&
      discType !== "select discount type"
    ) {
      if (discType === "Percentage") {
        discPrice = Number(pri) - Number(pri) * (Number(disc) / 100);
      } else if (discType === "Flat") {
        discPrice = Number(pri) - Number(disc);
      }
    }

    form.setFieldsValue({ discount: discPrice });
  }
  const materialData = [
    { id: 1, value: "Suede" },
    { id: 2, value: "leatherette" },
    { id: 3, value: "linen" },
    { id: 4, value: "Laminate" },
    { id: 5, value: "Veneer" },
    { id: 6, value: "PVC" },
    { id: 7, value: "Acrylic Sheets" },
    { id: 8, value: "Marble" },
  ];
  useEffect(() => {
    dispatch(getCategoryData());
  }, []);
  useEffect(() => {
    // setCategory(categoryData);
    const newData = categoryData.map((item) => {
      return { value: item };
    });
    setUpdateCategory(newData);
  }, [categoryData]);
  const deleteImgHandler = (image) => {
    let images = [...newImage];
    let updateImages = images.filter((item) => item.image !== image);
    setNewImage(updateImages);
  };
  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        const updateImages = [
          ...image,
          {
            image: info?.file?.response?.data?.image.toString(),
          },
        ];
        const updateImagesNew = [
          ...newImage,
          {
            image: info?.file?.response?.data?.image.toString(),
          },
        ];
        setImage(updateImages);
        setNewImage(updateImagesNew);
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  useEffect(() => {
    let data = editData;
    form.setFieldValue("name", data?.name ? data?.name : "");
    form.setFieldValue("size", data?.size ? data?.size : "");
    form.setFieldValue(
      "description",
      data?.description ? data?.description : ""
    );
    form.setFieldValue("price", data?.price ? data?.price : "");
    form.setFieldValue("quantity", data?.quantity ? data?.quantity : "");
    form.setFieldValue("type", data?.productType ? data?.productType : "");
    form.setFieldValue("colour", data?.colour ? data?.colour : "");
    form.setFieldValue("details", data?.details ? data?.details : "");
    form.setFieldValue(
      "specifications",
      data?.specifications ? data?.specifications : ""
    );
    form.setFieldValue(
      "discount",
      data?.discountedPrice ? data?.discountedPrice : ""
    );
    form.setFieldValue("material", data?.material ? data?.material : "");
    form.setFieldValue("category", data?.category ? data?.category : "");
    form.setFieldValue(
      "discountType",
      data?.discountType ? data?.discountType : ""
    );
    form.setFieldValue("discountAdd", data?.discount ? data?.discount : "");
    // form.setFieldsValue({ images: data?.images?data?.images.map((item)=>item.image):''});

    // data?.images?.data?.images?.map(item=>setImage((previousImage)=>[...previousImage,item?.image]))

    setImage(data?.images ? data?.images : "");
    setNewImage(data?.images ? data?.images : "");
  }, [editData]);
  return (
    <div className={style.edit_variant_section}>
      <BackButton name="Variants Update" backButtonHandler={backHandler} />
      <div className={style.variant_edit_container}>
        <Form
          className={style.appointments_form}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
          form={form}
          style={{
            maxWidth: "100%",
          }}
        >
          <Form.Item
            className={style.appointments_form_item}
            label="Product Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your product name!",
              },
            ]}
          >
            <Input placeholder="Enter your product name" />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Product Size"
            name="size"
          >
            <Input placeholder="Enter your product size" />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Product Quantity"
            name="quantity"
            rules={[
              {
                required: true,
                message: "Please input your product quantity!",
              },
            ]}
          >
            <Input
              placeholder="Enter your product quantity"
              onChange={handleEditQuantityChange}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Product Type"
            name="type"
          >
            <Input placeholder="Enter your product type" />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_color_picker_edit}
            label="Product Color"
            name="colour"
          >
            <Input
              style={{ height: "57px", marginTop: "-3px" }}
              type="color"
              placeholder="Enter your product color"
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Product Specifications"
            name="specifications"
          >
            <Input placeholder="Enter your product specifications" />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Product Price"
            name="price"
            rules={[
              {
                required: true,
                message: "Please input your product price!",
              },
            ]}
          >
            <Input
              placeholder="Enter your product price"
              // onChange={updateDiscountHanddlerPrice}
              onChange={handleEditPriceChange}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Discount Type"
            name="discountType"
          >
            <Select
              className={style.appointments_form_item}
              style={{
                marginTop: "-5px",
                border: " 1px solid #3E3636",
                borderRadius: "5px",
              }}
              showSearch
              placeholder="Enter your discount type"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={filterOption}
              options={discountData}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="discount"
            name="discountAdd"
          >
            <Input
              placeholder="Enter your product discount"
              // onChange={updateDiscountHanddler}
              onChange={handleEditDiscountChange}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Discounted Price"
            name="discount"
          >
            <Input placeholder="Enter your product discount" disabled />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            label="Product Material"
            name="material"
          >
            {/* <Input placeholder="Enter your product material"/> */}
            <Select
              className={style.appointments_form_item}
              style={{
                marginTop: "-5px",
                border: " 1px solid #3E3636",
                borderRadius: "5px",
              }}
              showSearch
              placeholder="Enter your product material"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={filterOption}
              options={materialData}
            />
          </Form.Item>
          {/* <Form.Item
            className={style.appointments_form_item}
            label="Product Category"
            name="category"
            rules={[
              {
                required: true,
                message: "Please input your product category!",
              },
            ]}
          >
          <Input placeholder="Enter your product category"/>
          </Form.Item> */}

          <Form.Item
            className={style.appointments_form_item}
            label="Product Category"
            name="category"
            rules={[
              {
                required: true,
                message: "Please input your product Category!",
              },
            ]}
          >
            <Select
              className={style.appointments_form_item}
              style={{
                marginTop: "-5px",
                border: " 1px solid #3E3636",
                borderRadius: "5px",
              }}
              showSearch
              placeholder="Enter your product category"
              optionFilterProp="children"
              onChange={onChangeSelect}
              onSearch={onSearch}
              filterOption={filterOption}
              options={updateCategory}
            />
          </Form.Item>
          <Form.Item
            className={style.appointments_form_item}
            style={{ width: "100%" }}
            label="Details"
            name="details"
          >
            <TextArea rows={4} placeholder="details here...." />
          </Form.Item>
          <div className={style.images_dimensions}>
            the images dimensions should be (1300px)x(550px).
          </div>
          <Form.Item
            className={style.appointments_form_item_image}
            style={{
              background: "#fff",
              borderRadius: "7px",
              paddingLeft: "5px",
              width: "100%",
            }}
            label="Image"
            name="image"
          >
            <div className={style.common_edit_image_antd}>
              {newImage?.length > 0
                ? newImage &&
                  newImage.map((item, index) => {
                    return (
                      <div
                        className={style.common_edit_image_antd_container}
                        key={index}
                      >
                        <img
                          src={newImage ? `${mainUrl()}/${item.image}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={() => deleteImgHandler(item.image)}
                          />
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div>
              <Upload
                action={trelifImageUpload}
                listType="picture-card"
                showUploadList={false}
                onPreview={() => {}}
                {...propsUpload}
                headers={{
                  Authorization: `Bearer ${getToken()}`,
                }}
              >
                {newImage.length < 10 && "+ Upload"}
              </Upload>
            </div>
          </Form.Item>
          <Form.Item className={style.appointments_form_submit_all}>
            <Button
              className={style.appointments_form_submit}
              // type="primary"
              htmlType="submit"
              style={{ color: "#fff" }}
            >
              {loading ? <SmallLoader /> : "update products"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default EditVariant;
