export function countMaterials(productList) {
  const materialsCount = {
    leatherette: 0,
    suede: 0,
    linen: 0,
    laminate: 0,
    veneer: 0,
    pvc: 0,
    acrylicSheets: 0,
    marble: 0,
  };

  // Helper function to update material count
  function updateMaterialCount(materialName) {
    const formattedMaterialName = materialName.trim().toLowerCase();

    switch (formattedMaterialName) {
      case "leatherette":
        materialsCount.leatherette++;
        break;
      case "suede":
        materialsCount.suede++;
        break;
      case "linen":
        materialsCount.linen++;
        break;
      case "laminate":
        materialsCount.laminate++;
        break;
      case "veneer":
        materialsCount.veneer++;
        break;
      case "pvc":
        materialsCount.pvc++;
        break;
      case "acrylic sheets":
        materialsCount.acrylicSheets++;
        break;
      case "marble":
        materialsCount.marble++;
        break;
      default:
        break;
    }
  }

  // Iterate over products and sub-products
  for (const product of productList) {
    updateMaterialCount(product.material);

    if (product.subProducts.length) {
      for (const subProduct of product.subProducts) {
        updateMaterialCount(subProduct.material);
      }
    }
  }

  return materialsCount;
}
