import React from "react";
import { Space, Table, Tag } from "antd";
import style from "./table.module.css";

// const data = [
//   {
//     id: '1',
//     firstName: 'John',
//     email: 'Brown',
//     dob: 32,
//     status: 'New York No. 1 Lake Park',
//     // tags: ['nice', 'developer'],
//   },
//   {
//     id: '2',
//     firstName: 'Jim ',
//     email:'Green',
//     dob: 42,
//     status: 'London No. 1 Lake Park',
//     // tags: ['loser'],
//   },
//   {
//     id: '3',
//     firstName: 'Joe',
//     email:'Black',
//     dob : 32,
//     status: 'Sydney No. 1 Lake Park',
//     // tags: ['cool', 'teacher'],
//   },
// ];

const Tables = ({
  datas,
  columns,
  scrollVal,
  showPagination,
  verticalScrollVal,

}) => {

  const headerStyle = {
    background: "#00A099",
    color: "#fff",
    // textAlign:"center"
  };

  const adjustedColumns = columns.map((column) => ({
    ...column,
    onHeaderCell: (column) => ({
      style: headerStyle,
    }),
  }));

  return (
    <>
      <Table
        dataSource={datas}
        scroll={{ x: scrollVal, y: verticalScrollVal }}
        columns={adjustedColumns}
        pagination={showPagination}
      />
    </>
  );
};
export default Tables;
