import React from "react";
import style from "./dashboardBlock.module.css";

const DashboardBlock = ({ title, img, value, color }) => {
  return (
    <>
      <div className={style.dashboard_block_section}>
        <div className={style.block_section_img_text}>
          <div
            className={style.block_section_img}
            style={{ background: color }}
          >
            <img src={img} alt="images" />
          </div>
          <div
            className={style.block_section_text}
            style={{ color: "#2E384D" }}
          >
            {value ? value : 0}
          </div>
        </div>
        <div className={style.block_section_label}>{title}</div>
      </div>
    </>
  );
};
export default DashboardBlock;
