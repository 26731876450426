import React, { useEffect, useState } from "react"
import style from "./customerOrderPopup.module.css"
import {mainUrl} from "../../utils/common"
import { useDispatch,useSelector } from "react-redux";
import BackImg from "../../assets/back.svg"
import Tables from "../../components/tables/Tables"
import moment from "moment";
import { Space, Tag } from 'antd';
import { Collapse } from 'antd';
import Arrow from "../../assets/down_arrow_icon.svg"

const CustomerOrderPopup=({label,backHandler})=>{
    const dispatch=useDispatch();
    const [state,setState]=useState([])
    const [product,setProduct]=useState()
    const [show, setShow] = useState(false)
    const [long, setLong] = useState(1)

    const data=useSelector((state)=> state.user.customer)
    useEffect(()=>{
      setState(data)
    },[data])

    const accordionHandler = item => {
      setLong(item._id)
      item._id === long ? setShow(!show) : setShow(true)
    }
    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'productName',
        id: 'productName',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Price',
        dataIndex: 'price',
        id: 'price',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        id: 'quantity',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Size',
        dataIndex: 'size',
        id: 'size',
        render: (text) => <a>{text}</a>,
      },
      {
        title: 'Product Images',
        id: 'images',
        dataIndex: 'images',
        width:200,
        render: (_, { images }) => (
        <div className={style.designers_images_pa}>
        {images.map((img,index) => {
         return (
             <div key={index} className={style.designers_images_st}>
                 <img
                  crossOrigin="anonymous"
                  src={img.image ? `${mainUrl()}/${img.image}` : "-"}
                  alt="image"
             /></div>
       );
     })}
   </div>
 ),
    },
    ]
    return (
        <>
        <div className={style.product_details_popup_section}>
          <div className={style.product_details_popup_head}>
            <div className={style.product_details_popup_head_first}>{label}</div>
            <div className={style.product_details_popup_head_second} onClick={backHandler}>
                <img src={BackImg} alt='back page image'/>
                <div>back</div>
            </div>
          </div>

        {state != ''?
        <div className={style.ticket_details_accordion}>
        <div className={style.contact_section}>
        <div className={style.contact_container}>
        {state && state.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className={
                show && item._id === long
                  ? style.contact_accordion_active
                  : style.contact_accordion
              }
            >
              <div
                className={style.contact_accordion_head}
                onClick={() => accordionHandler(item)}
                style={{
                  height: show && item._id === long ? "7vh" : "10vh",
                  borderBottom:
                    show && item._id === long ? "1px solid #8B8FAE" : "",
                }}
              >
                <h3>Order Id - {item.orderId?'TRE/'+ moment(item.estimateDate).year()+'/'+(moment(item.estimateDate).month()+1)+'/'+item.orderId:'NA'}</h3>
                <h3>Total Amount -{item.finalAmount?item.finalAmount:'NA'}</h3>
                <img
                  src={Arrow}
                  alt="icon forword"
                  style={{
                    transform:
                      show && item._id === long ? "rotate(180deg)" : "",
                  }}
                />
              </div>
              <div
                className={
                  show && item._id === long
                    ? style.contact_accordion_desc_active
                    : style.contact_accordion_desc
                }
              >
              <Tables datas={item.products?item.products:''} columns={columns}/>
              </div>
            </div>
          </>
        )
      })}
     </div>
     </div>
     </div>



          :
          <div className={style.product_details_popup_data_not_found}>data not found</div>
          }

          </div>
        </>
    )
}
export default CustomerOrderPopup;