import React from "react"
import style from "./colorLoader.module.css"

const ColorLoader=()=>{
    return(
        <>
        <div className={style.color_custom_loader}></div>
        </>
    )
}
export default ColorLoader;