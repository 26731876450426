import React, { useState, useEffect } from "react";
import style from "./discountsChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  discountsDataAdded,
  discountsDataUpdate,
  discountsEditAction,
} from "../../store/slices/discountsSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;

const DiscountsChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.discounts.deleteLoad);
  const message = useSelector((state) => state.discounts.message);
  const singleDiscounts = useSelector(
    (state) => state.discounts.singleDiscounts
  );
  const editData = useSelector((state) => state.discounts.editData);
  const [valueError, setValueError] = useState();
  const [validFromDate, setValidFromDate] = useState(null); //disable past date
  const [validToDate, setValidToDate] = useState(null); //disable past date
  const [form] = Form.useForm();
  const urls = "/discounts/add-discounts";
  const navigate = useNavigate();

  // const valueChange = () => {
  //   setValueError("");
  // };
  const backHandler = () => {
    navigate("/discounts");
  };
  const onChangeDate = () => {};
  const onFinish = (values) => {
    const updatedData = {};
    if (editData) {
      for (const [keyOriginal, valueOriginal] of Object.entries(editData)) {
        if (values[keyOriginal] !== valueOriginal && values[keyOriginal]) {
          updatedData[keyOriginal] = values[keyOriginal];
        }
      }
    }

    // singleDiscounts?singleDiscounts[0]?.validFrom
    // values["date"] = moment(values.date).format("MM-DD-YYYY");
    values["date"] = dayjs(values.date).format("MM-DD-YYYY");
    let value = Number(values.value);

    if (values.type === "percentage" && value > 100) {
      setValueError(
        "if type percentage and value above 100 so can not add and update"
      );
    } else {
      if (path === urls) {
        dispatch(discountsDataAdded(values));
      } else {
        let idData = path.split("/");
        let id = idData[idData.length - 1];
        dispatch(
          discountsDataUpdate(
            singleDiscounts[0]?._id ? singleDiscounts[0]?._id : id,
            updatedData
          )
        );
      }
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "Discount code added successfully!" ||
      message === "Discount code updated successfully!"
    ) {
      navigate("/discounts");
    }
  }, [message]);
  useEffect(() => {
    let data = singleDiscounts[0];
    form.setFieldValue("code", data?.code ? data?.code : "");
    form.setFieldValue("value", data?.value ? data?.value : "");
    form.setFieldValue("type", data?.type ? data?.type : "");
  }, [singleDiscounts]);
  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(discountsEditAction(id));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    form.setFieldValue("code", data?.code ? data?.code : "");
    form.setFieldValue("value", data?.value ? data?.value : "");
    form.setFieldValue("type", data?.type ? data?.type : "");
  }, [editData]);
  const onChange = (value) => {
    if (value === "flat") {
      setValueError("");
    }
  };
  const onSearch = (value) => {};
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const statusData = [
    { id: 1, value: "percentage" },
    { id: 2, value: "flat" },
  ];

  // Disable past date
  const disabledValidFromDate = (currentDate) => {
    return (
      currentDate && currentDate.valueOf() < new Date().setHours(0, 0, 0, 0)
    );
  };
  const disabledValidToDate = (current) => {
    return validFromDate && current && current < validFromDate;
  };

  function handleValueChange(e) {
    const sanitizedValue = e.target.value.replace(/[^0-9.]|(\.)\1+/g, "$1");
    form.setFieldsValue({ value: sanitizedValue });
    setValueError("");
  }
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            name={path === urls ? "discount add" : "discount update"}
            backButtonHandler={backHandler}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              label="Code"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Please input your code!",
                },
              ]}
            >
              <Input placeholder="Enter your code" />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              // style={{width:"45%"}}
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please input type!",
                },
              ]}
            >
              <Select
                className={style.appointments_form_select}
                // showSearch
                placeholder="Select Type"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={statusData}
              />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Value"
              name="value"
              rules={[
                {
                  required: true,
                  message: "Please input your value!",
                },
              ]}
            >
              <Input
                placeholder="Enter your value"
                onChange={handleValueChange}
              />
            </Form.Item>
            <div className={style.discounts_value_error}>
              <div></div>
              <div>{valueError}</div>
            </div>
            <Form.Item
              className={style.appointments_form_item}
              label="Valid From"
              name="validFrom"
              rules={[
                {
                  required: urls === path ? true : false,
                  // required:true,
                  message: "Please input todays date!",
                },
              ]}
            >
              <DatePicker
                disabledDate={disabledValidFromDate}
                value={validFromDate}
                onChange={(date) => {
                  setValidFromDate(date);
                  form.setFieldsValue({
                    validTo: null,
                  });
                }}
                className={style.appointments_form_item}
                // onChange={onChangeDate}
                size="large"
                style={{
                  width: "100%",
                  border: "1px solid #3E3636",
                  marginTop: "-7px",
                }}
                format={"MM/DD/YYYY"}
                defaultValue={
                  urls === path
                    ? ""
                    : // : moment(
                      //     singleDiscounts ? singleDiscounts[0]?.validFrom : ""
                      //   )
                      dayjs(
                        singleDiscounts ? singleDiscounts[0]?.validFrom : ""
                      )
                }
              />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              label="Valid To"
              name="validTo"
              rules={[
                {
                  required: urls === path ? true : false,
                  message: "Please input last date!",
                },
              ]}
            >
              <DatePicker
                value={validToDate}
                onChange={(date) => setValidToDate(date)}
                disabledDate={disabledValidToDate}
                className={style.appointments_form_item}
                // onChange={onChangeDate}
                size="large"
                style={{
                  width: "100%",
                  border: "1px solid #3E3636",
                  marginTop: "-7px",
                }}
                format={"MM/DD/YYYY"}
                defaultValue={
                  urls === path
                    ? ""
                    : // : moment(singleDiscounts ? singleDiscounts[0]?.validTo : "")
                      dayjs(singleDiscounts ? singleDiscounts[0]?.validTo : "")
                }
              />
            </Form.Item>
            {/* <Form.Item
              className={style.appointments_form_item}
              // style={{width:"45%"}}
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please input type!",
                },
              ]}
            >
              <Select
                className={style.appointments_form_select}
                showSearch
                placeholder="Select Type"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={filterOption}
                options={statusData}
              />
            </Form.Item> */}
            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add discount"
                ) : (
                  "update discount"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default DiscountsChange;
