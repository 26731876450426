import React,{useState,useEffect} from "react"
import style from "./topHeaderSlider.module.css"
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch,useSelector } from "react-redux";
import {getTopHeaderSliderData,topHeaderSliderDataDelete,sigleTopHeaderSliderDataAction,editBlankData} from "../../store/slices/topHeaderSliderSlice"
import { Space, Tag } from 'antd';
import BigLoader from "../../components/bigLoader/BigLoader"
import AddImg from "../../assets/icons8-add-24.png"
import EditImg from "../../assets/editNew.svg"
import DeleteImg from "../../assets/icons8-delete-24 .png"
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import banner1 from "../../assets/images3.png"
import {mainUrl} from "../../utils/common"

const TopHeaderSlider=()=>{
    const navigate=useNavigate()
    const dispatch=useDispatch();
    const data=useSelector((state)=>state.topHeaderSlider.data)
    const loading=useSelector((state)=>state.topHeaderSlider.loading)
    const deleteLoad=useSelector((state)=>state.topHeaderSlider.deleteLoad)
    const [state,setState]=useState();
    const [deleteId,setDeleteId]=useState()

    useEffect(()=>{
        dispatch(getTopHeaderSliderData())
    },[])
    useEffect(()=>{
       setState(data)
    },[data])
   
      const addHandler=()=>{
        dispatch(editBlankData())
        navigate('/home/top-header-slider/add-top-header-slider')
      }
      const editHandler=(record)=>{
        dispatch(sigleTopHeaderSliderDataAction(record._id))
        navigate(`/home/top-header-slider/edit-top-header-slider/${record._id}`)
      }
      const deleteHandler=(record)=>{
          setDeleteId(record._id)
          dispatch(topHeaderSliderDataDelete(record._id))
      }
    return(
        <div className={style.top_header_slider_section}>
        <PageHeader name='top Header Slider details' count={state?state.length:''} total={true} btnName={data?.length == 2?'':'add top Header Slider'} img={AddImg} addHandler={addHandler}/>
        {loading?<BigLoader/>:
        <div className={style.top_header_slider_container}>
          {state && state?.map((item,index)=>{
            return <div className={style.top_header_slider_data} key={index} style={{width:data?.length==2?'45%':'100%'}}>
                    <div className={style.top_header_slider_text}>header slider image</div>
                    <img className={style.top_header_slider_img} crossOrigin="anonymous" src={item ? `${mainUrl()}/${item.image}` : "-"} alt="image"/>
                    <div className={style.top_header_slider_btn}>
                    <div className={style.delete_edit_hand_1} onClick={()=>editHandler(item)}>
                      <img src={EditImg} alt='edit image'/>
                      <div>edit</div>
                      </div>
                      <div className={style.delete_edit_hand} onClick={()=>deleteHandler(item)}>
                     {deleteLoad && item._id=== deleteId ? <ColorLoader/>:
                     <>
                    <img src={DeleteImg} alt='edit image'/>
                    <div>delete</div>
                    </>
                    }
                    </div>
                    </div>
                   </div>
          })}
        </div>
        }
        </div>
    )
}
export default TopHeaderSlider;