import  {post,get,_delete,put,patch,formData, clientId}  from '../TrelifAdmin';

const instoreList = () => {
  return get('/mannualOrder');
};
const instoreCustomerList = () => {
  return get('/mannualOrder/Customer');
};
const instoreDiscountList = () => {
  return get('mannualOrder/discount');
};
const instoreDiscountAdd = (data) => {
  return post('/mannualOrder',data);
};
const instoreStatusChange = (instoreId,data) => {
  return patch(`/mannualOrder/${instoreId}`,data);
};
const instoreSearchList = (name) => {
  return get(`mannualOrder?customerName=${name}`);
};

export default {
    instoreList,
    instoreCustomerList,
    instoreDiscountList,
    instoreDiscountAdd,
    instoreStatusChange,
    instoreSearchList,
};