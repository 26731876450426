import { post, get, formData, clientId } from "../TrelifAdmin";

const userSignup = (data) => {
  return post("/users/signup", data);
};

const userLogin = (data) => {
  return post("/users/adminlogin", data);
};
const userVerify = (data) => {
  return post("/users/otp-verification", data);
};

const adminPasswordChange = (data) => {
  return post("/users/admin-change-password", data);
};

export default {
  userSignup,
  userLogin,
  userVerify,
  adminPasswordChange,
};
