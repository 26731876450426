import  {post,get,_delete,put,formData, clientId, patch}  from '../TrelifAdmin';

const discountsList = () => {
  return get('/discounts');
};
const discountsAdd = (data) => {
  return post('/discounts',data);
};
const discountsUpdate = (id,data) => {
  return put(`/discounts/${id}`,data);
};
const discountsDelete=(id)=>{
  return _delete(`/discounts/${id}`)
}
const discountsStatus=(id)=>{
  return patch(`/discounts/${id}`)
}
const discountsEdit=(id)=>{
  return get(`/discounts/specific-discount/${id}`)
}


export default {
    discountsList,
    discountsAdd,
    discountsUpdate,
    discountsDelete,
    discountsStatus,
    discountsEdit
};