import React, { useState, useEffect } from "react";
import style from "./newslettersChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/icons8-back-24.png";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import { newslettersDataAdded } from "../../store/slices/newslettersSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;

const NewslettersChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.newsletters.deleteLoad);
  const message = useSelector((state) => state.newsletters.message);
  const [form] = Form.useForm();
  const urls = "/newsletters/add-newsletters";
  const navigate = useNavigate();
  const backHandler = () => {
    navigate("/newsletters");
  };
  const onFinish = (values) => {
    dispatch(newslettersDataAdded(values));
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (message === "Newsletter added successfully!") {
      navigate("/newsletters");
    }
  }, [message]);
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton name="news letter add" backButtonHandler={backHandler} />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input placeholder="Enter your name" />
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>

            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? <SmallLoader /> : "add news letter"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default NewslettersChange;
