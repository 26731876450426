import  {post,get,_delete,put,formData, clientId, patch}  from '../TrelifAdmin';

const teamList = () => {
  return get('/team');
};
const teamAdd = (data) => {
  return post('/team',data);
};
const teamUpdate = (id,data) => {
  return put(`/team/${id}`,data);
};
const teamDelete=(id)=>{
  return _delete(`/team/${id}`)
}
const teamStatus=(id)=>{
  return patch(`/team/${id}`)
}
const teamEdit=(id)=>{
  return get(`team/specific-team/${id}`)
}



export default {
    teamList,
    teamAdd,
    teamUpdate,
    teamDelete,
    teamStatus,
    teamEdit
};