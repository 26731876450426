import styles from "./videoLoader.module.css";

const VideoLoader = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.boxes}>
          <div className={styles.box_1}></div>
          <div className={`${styles.box} ${styles.b_1}`}></div>
          <div className={`${styles.box} ${styles.b_2}`}></div>
          <div className={`${styles.box} ${styles.b_3}`}></div>
          <div className={`${styles.box} ${styles.b_4}`}></div>
        </div>
      </div>
    </>
  );
};
export default VideoLoader;
