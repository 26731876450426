import React,{useState,useEffect} from "react"
import Layout from "../../components/layout/Layout";
import style from "./discounts.module.css"
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch,useSelector } from "react-redux";
import {getDiscountsData,discountsDataDelete,sigleDiscountsDataAction,blankDataAction,statusChangeAction,editBlankData} from "../../store/slices/discountsSlice"
import { Space, Tag,Switch } from 'antd';
import BigLoader from "../../components/bigLoader/BigLoader"
import AddImg from "../../assets/icons8-add-24.png"
import EditImg from "../../assets/editNew.svg"
import DeleteImg from "../../assets/icons8-delete-24 .png"
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import RevertLoader from "../../components/revertLoader/RevertLoader"

const Discounts=()=>{
    const navigate=useNavigate()
    const dispatch=useDispatch();
    const data=useSelector((state)=>state.discounts.data)
    const loading=useSelector((state)=>state.discounts.loading)
    const deleteLoad=useSelector((state)=>state.discounts.deleteLoad)
    const [state,setState]=useState();
    const [deleteId,setDeleteId]=useState()
    const [ids,setIds]=useState()

    useEffect(()=>{
        dispatch(getDiscountsData())
    },[])
    useEffect(()=>{
       setState(data)
    },[data])
    const columns = [
        {
          title: 'Code',
          dataIndex: 'code',
          id: 'code',
          render: (text) => <a>{text}</a>,
        },
        {
          title: 'Type',
          dataIndex: 'type',
          id: 'type',
          render: (text) => <a>{text}</a>,
        },
        {
            title: 'Value',
            dataIndex: 'value',
            id: 'value',
            render: (text) => <a>{text}</a>,
          },
        {
          title: 'Valid From',
          dataIndex: 'validFrom',
          id: 'validFrom',
          render: (date) => <a>{moment(date).format('DD/MM/YYYY')}</a>,
        },
        {
            title: 'Valid To',
            dataIndex: 'validTo',
            id: 'validTo',
            render: (date) => <a>{moment(date).format('DD/MM/YYYY')}</a>,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            id: 'status',
            width:200,
            render: (status, record, index) => {
              const onToggle = (checked) => {
                setIds(checked)
                dispatch(statusChangeAction(checked))
              };
              return (
                <Space>
                  <Switch style={{background:status===1?'#168C98':''}} checked={status===1?true:false} onChange={()=>onToggle(record._id)} />
                  <div className={style.users_switch_btn} style={{border:status===1?'1px solid #168C98':'1px solid #3E3636'}}>{status===1?deleteLoad && ids===record._id?<RevertLoader/>:'active':deleteLoad && ids===record._id?<RevertLoader/>:'inactive'}</div>
                </Space>
              );
            },
          },
        {
          title: 'Action',
          id: 'action',
          render: (_, record) => (
            <Space size="middle">
              <div className={style.delete_edit_hand_1} onClick={()=>editHandler(record)}>
               <img src={EditImg} alt='edit image'/>
               <div>edit</div>
              </div>
              {/* <div className={style.delete_edit_hand} onClick={()=>deleteHandler(record)}>
              {deleteLoad && record._id=== deleteId ? <ColorLoader/>:
              <>
              <img src={DeleteImg} alt='edit image'/>
               <div>delete</div>
               </>
               }
              </div> */}
            </Space>
          ),
        },
      ];
      const addHandler=()=>{
        dispatch(blankDataAction())
        dispatch(editBlankData())
        navigate('/discounts/add-discounts')
      }
      const editHandler=(record)=>{
        dispatch(sigleDiscountsDataAction(record._id))
        navigate(`/discounts/edit-discounts/${record._id}`)
      }
      const deleteHandler=(record)=>{
          setDeleteId(record._id)
          dispatch(discountsDataDelete(record._id))
      }
    return(
        <Layout>
        <div className={style.blog_section}>
        <PageHeader name='discounts details' btnName='add discount' img={AddImg} addHandler={addHandler}/>
        {loading?<BigLoader/>:
        <>
        <Tables datas={state} columns={columns}/>
        <div style={{textTransform:'capitalize'}}>total discounts{" "}-{" "+state?state?.length:''}</div>
        </>}
        </div>
        </Layout>
    )
}
export default Discounts;