import React, { useState, useRef, useEffect } from "react";
import style from "./enquires.module.css";
import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/pageHeader/PageHeader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/icons8-edit-24.png";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import RevertImg from "../../assets/revert.png";
import ActionImg from "../../assets/actionImg.png";
import Tables from "../../components/tables/Tables";
import { useDispatch, useSelector } from "react-redux";
import {
  getEnquiresData,
  enquiresDataRevert,
  enquiresStatusChangeAction,
  messageBlankAction,
} from "../../store/slices/enquiresSlice";
import moment from "moment";
import { useNavigate } from "react-router";
import { Modal, Space, Button, Input, Tag } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import RevertLoader from "../../components/revertLoader/RevertLoader";
import orderStatus from "../../assets/statusBlack.png";
import EnquiresStatusPopup from "../../components/enquiresStatusPopup/EnquiresStatusPopup";
import PeekModal from "../../components/peekModal/PeekModal";

const Enquires = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.enquires.data);
  const loading = useSelector((state) => state.enquires.loading);
  const message = useSelector((state) => state.enquires.message);
  const [state, setState] = useState();
  const [revertId, setRevertId] = useState();
  const [show, setShow] = useState(false);
  const [pop, setPop] = useState();
  const [openModal, setOpenModal] = useState({ text: "", open: false });
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    confirm({
      closeDropdown: false,
    });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters &&
              handleReset(clearFilters, selectedKeys, confirm, dataIndex)
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  useEffect(() => {
    dispatch(getEnquiresData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const statusData = [
    { id: 1, value: "Pending" },
    { id: 2, value: "In Progress" },
    { id: 3, value: "Completed" },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      id: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      id: "email",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      id: "phone",
    },
    {
      title: "Message",
      dataIndex: "message",
      id: "message",
      render: (text) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              maxHeight: "4vh",
              maxWidth: "18ch",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {text ? text : "NA"}
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              setOpenModal({ ...openModal, text: text, open: true })
            }
          >
            <img src={ActionImg} alt="Open" />
          </div>
        </div>
      ),
      // render: (text) => <a style={{ overflow: 'hidden'}}>{text?text:'NA'}</a>,
    },
    {
      title: "Status",
      dataIndex: "enquiryStatus",
      id: "enquiryStatus",
      render: (text) => <a>{text ? text : "NA"}</a>,
    },
    {
      title: "Enquires Status Change",
      id: "changeStatusEnquires",
      width: 250,
      render: (_, record) => (
        <Space size="middle">
          <div
            className={style.delete_edit_hand_1}
            onClick={() => editHandler(record)}
            style={{
              marginBottom: data?.length == 5 ? "5px" : "0px",
              width: data?.length == 5 ? "100%" : "",
            }}
          >
            <img src={orderStatus} alt="edit image" />
            <div>Enquires status change</div>
          </div>
          {show ? (
            <EnquiresStatusPopup
              statusData={statusData}
              onFinish={onFinish}
              closeHandler={closeHandler}
              label="Enquires status change"
            />
          ) : (
            ""
          )}
        </Space>
      ),
    },
    // {
    //   title: 'Action',
    //   id: 'action',
    //   fixed: "right",
    //   width: 150,
    //   render: (_, record) => (
    //     <Space size="middle">
    //       <div className={style.delete_edit_hand} style={{background:record.status==0?"grey":"",cursor:record.status==0?"not-allowed":""}}>
    //       <img src={RevertImg} alt='edit image'/>
    //        <div>revert</div>
    //       </div>
    //     </Space>
    //   ),
    // },
  ];
  const deleteHandler = (record) => {
    setRevertId(record._id);
    dispatch(enquiresDataRevert(record._id));
  };
  const editHandler = (record) => {
    setShow(true);
    setPop(record);
    dispatch(messageBlankAction());
  };
  const closeHandler = () => {
    setShow(false);
    setPop();
  };
  const onFinish = (values) => {
    dispatch(enquiresStatusChangeAction(pop._id, values));
  };
  useEffect(() => {
    if (message === "Enquiries status updated successfully!") {
      closeHandler();
    }
  }, [message]);

  return (
    <>
      <PeekModal openModal={openModal} setOpenModal={setOpenModal} />
      <Layout>
        <div className={style.enquires_section}>
          <PageHeader name="enquires details" />
          {loading ? (
            <BigLoader />
          ) : (
            <>
              <Tables datas={state} columns={columns} scrollVal={1050} />
              <div style={{ textTransform: "capitalize" }}>
                total enquires -{" " + state ? state?.length : ""}
              </div>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Enquires;
