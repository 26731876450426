import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api";
const {
  productsList,
  productsAdd,
  productsUpdate,
  productsDelete,
  categoryList,
  categoryStatus,
  productsEdit,
  editAddVariant,
} = API;

const initialState = {
  data: [],
  singleData: [],
  singleProducts: [],
  category: [],
  error: null,
  loading: false,
  message: "",
  deleteLoad: false,
  editData: [],
  loader: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setCategory(state, action) {
      state.category = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDeleteLoad(state, action) {
      state.deleteLoad = action.payload;
    },
    setAfterDeleteProducts(state, action) {
      state.data = state.data.filter((item) => item._id !== action.payload);
    },
    setProductsAdd(state, action) {
      state.data = [action.payload, ...state.data];
    },
    setEditProducts(state, action) {
      state.singleProducts = state.data.filter(
        (item) => item._id === action.payload
      );
    },
    setBlankSingleData(state, action) {
      state.singleBlog = [];
    },
    setChnageStatus(state, action) {
      state.singleData = state.data.filter(
        (item) => item._id == action.payload
      );
      state.singleData[0].status =
        state.singleData[0].status === 1
          ? 0
          : state.singleData[0].status === 0
          ? 1
          : "";
    },
    setEditData(state, action) {
      state.editData = action.payload;
    },
    setLoader(state, action) {
      state.loader = action.payload;
    },
  },
});

export const {
  setEditData,
  setChnageStatus,
  setCategory,
  setLoading,
  setMessage,
  setError,
  setData,
  setDeleteLoad,
  setProductsAdd,
  setAfterDeleteProducts,
  setEditProducts,
  setBlankSingleData,
  setLoader,
} = productsSlice.actions;
export default productsSlice.reducer;

export const getProductsData = (msg) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await productsList();
    if (res.data.success) {
      dispatch(setData(res.data.data));
      dispatch(setLoading(false));
      dispatch(setMessage(res.data.message));
      if (msg === "msg") {
      } else {
        // message.success(res.data?.message);
      }
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.message));
    message.error(e.message);
  }
};

export const getCategoryData = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const res = await categoryList();
    if (res.data.success) {
      dispatch(setCategory(res.data.data));
      dispatch(setLoading(false));
      dispatch(setMessage(res.data.message));
    } else {
      dispatch(setLoading(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setLoading(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const productsDataAdded = (data) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await productsAdd(data);
    if (res.data.success) {
      dispatch(setProductsAdd(data));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const sigleProductsDataAction = (id) => (dispatch) => {
  dispatch(setEditProducts(id));
};

export const messageBlankActionVariant = () => (dispatch) => {
  dispatch(setMessage());
};

export const productsDataUpdate = (id, data) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await productsUpdate(id, data);
    if (res.data.success) {
      dispatch(setBlankSingleData());
      dispatch(setEditData());
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};
export const productsEditAction = (id) => async (dispatch) => {
  try {
    const res = await productsEdit(id);
    if (res.data.success) {
      dispatch(setEditData(res.data.data));
    } else {
      message.error(res.data.message);
    }
  } catch (e) {
    message.error(e.response.data.message);
  }
};
export const editBlankData = () => (dispatch) => {
  dispatch(setEditData());
};
export const productsDataDelete = (id) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await productsDelete(id);
    if (res.data.success) {
      dispatch(setAfterDeleteProducts(id));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const statusChangeAction = (id) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await categoryStatus(id);
    if (res.data.success) {
      // dispatch(setChnageStatus(id));
      dispatch(setDeleteLoad(false));
      dispatch(getProductsData());
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};

export const postEditAddVariant = (data, setEditFormVariantsAddForm) => async (
  dispatch
) => {
  dispatch(setLoader(true));
  try {
    const res = await editAddVariant(data);
    if (res.data.success) {
      // dispatch(setChnageStatus(id));
      dispatch(setLoader(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
      setEditFormVariantsAddForm([]);
    } else {
      dispatch(setLoader(false));
      dispatch(setError(res.data.message));
    }
  } catch (e) {
    dispatch(setLoader(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};
