import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Auth from "./pages/auth/index";
import Dashboard from "./pages/dashboard/index";
import Users from "./pages/users/index";
import Blogs from "./pages/blogs/index"
import BlogChange from "./components/blogChange/BlogChange";
import Appointments from "./pages/appointments/index"
import Testimonials from "./pages/testimonials/index";
import TestimonialsChange from "./components/testimonialsChange/TestimonialsChange";
import Team from "./pages/team";
import TeamChange from "./components/teamChange/TeamChange"
import Designers from "./pages/designers/index"
import DesignersChange from "./components/designersChange/DesignersChange";
import Enquires from "./pages/enquires/index"
import Collections from "./pages/collections/index"
import Categorys from "./pages/categorys/index"
import CategorysChange from "./components/categorysChange/CategorysChange"
import Products from "./pages/products/index"
import ProductsChange from "./components/productsChange/ProductsChange"
import Newsletters from "./pages/newsletters/index"
import NewslettersChange from "./components/newslettersChange/NewslettersChange"
import Discounts from "./pages/discounts/index"
import DiscountsChange from "./components/discountsChange/DiscountsChange"
import Settings from "./pages/settings/index";
import Usp from "./pages/usp/index"
import UspChange from "./components/uspChange/UspChange"
import TopHeaderSlider from "./pages/topHeaderSlider/index"
import TopHeaderSliderChange from "./components/topHeaderSliderChange/TopHeaderSliderChange"
import HomePageBottomSlider from "./pages/homePageBottomSlider/index"
import HomePageBottomSliderChange from "./components/homePageBottomSliderChange/HomePageBottomSliderChange"
import ProjectSlider from "./pages/projectSlider/index"
import ProjectSliderChange from "./components/projectSliderChange/ProjectSliderChange"
import HomePage from "./pages/homePage/index"
import HomePageChange from "./components/homePageChange/HomePageChange"
import Orders from "./pages/orders/index"
import ProtectedRoute from "./utils/ProtectedRoute"
import InStore from "./pages/inStore/index";
import OrderChange from "./components/orderChange/OrderChange";
import NotFound from "./components/notFound/NotFound"

function App() {
  console.log("app is running fine.");
  return (
    <Router>
            <Routes>
              <Route path="/" element={<Auth />} />
              <Route path="*" element={<NotFound/>} />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/customers" element={<ProtectedRoute><Users /></ProtectedRoute>} />
              <Route path="/blogs" element={<ProtectedRoute><Blogs /></ProtectedRoute>}/>
              <Route path="/blogs/add-blog" element={<ProtectedRoute><BlogChange/></ProtectedRoute>} />
              <Route path="/blogs/edit-blog/:id" element={<ProtectedRoute><BlogChange/></ProtectedRoute>} />
              <Route path="/appointments" element={<ProtectedRoute><Appointments/></ProtectedRoute>} />
              <Route path="/testimonials" element={<ProtectedRoute><Testimonials /></ProtectedRoute>}/>
              <Route path="/testimonials/add-testimonials" element={<ProtectedRoute><TestimonialsChange /></ProtectedRoute>}/>
              <Route path="/testimonials/edit-testimonials/:id" element={<ProtectedRoute><TestimonialsChange /></ProtectedRoute>}/>
              <Route path="/team" element={<ProtectedRoute><Team/></ProtectedRoute>}/>
              <Route path="/team/add-team" element={<ProtectedRoute><TeamChange /></ProtectedRoute>}/>
              <Route path="/team/edit-team/:id" element={<ProtectedRoute><TeamChange /></ProtectedRoute>}/>
              <Route path="/designers" element={<ProtectedRoute><Designers/></ProtectedRoute>}/>
              <Route path="/designers/add-designers" element={<ProtectedRoute><DesignersChange /></ProtectedRoute>}/>
              <Route path="/designers/edit-designers/:id" element={<ProtectedRoute><DesignersChange /></ProtectedRoute>}/>
              <Route path="/enquires" element={<ProtectedRoute><Enquires/></ProtectedRoute>} />
              <Route path="/collections" element={<ProtectedRoute><Collections/></ProtectedRoute>} />
              <Route path="/categories" element={<ProtectedRoute><Categorys/></ProtectedRoute>} />
              <Route path="/categories/add-categories" element={<ProtectedRoute><CategorysChange /></ProtectedRoute>}/>
              <Route path="/categories/edit-categories/:id" element={<ProtectedRoute><CategorysChange /></ProtectedRoute>}/>
              <Route path="/products" element={<ProtectedRoute><Products/></ProtectedRoute>}/>
              <Route path="/products/add-products" element={<ProtectedRoute><ProductsChange /></ProtectedRoute>}/>
              <Route path="/products/edit-products/:id" element={<ProtectedRoute><ProductsChange /></ProtectedRoute>}/>
              <Route path="/newsletters" element={<ProtectedRoute><Newsletters/></ProtectedRoute>}/>
              <Route path="/newsletters/add-newsletters" element={<ProtectedRoute><NewslettersChange /></ProtectedRoute>}/>
              <Route path="/discounts" element={<ProtectedRoute><Discounts/></ProtectedRoute>}/>
              <Route path="/discounts/add-discounts" element={<ProtectedRoute><DiscountsChange /></ProtectedRoute>}/>
              <Route path="/discounts/edit-discounts/:id" element={<ProtectedRoute><DiscountsChange /></ProtectedRoute>}/>
              <Route path="/home" element={<ProtectedRoute><Settings/></ProtectedRoute>}/>
              {/* <Route path="/settings/usp" element={<Usp/>}/> */}
              <Route path="/home/usp/add-usp" element={<ProtectedRoute><UspChange /></ProtectedRoute>}/>
              <Route path="/home/usp/edit-usp/:id" element={<ProtectedRoute><UspChange /></ProtectedRoute>}/>
              {/* <Route path="/settings/top-header-slider" element={<TopHeaderSlider/>}/> */}
              <Route path="/home/top-header-slider/add-top-header-slider" element={<ProtectedRoute><TopHeaderSliderChange /></ProtectedRoute>}/>
              <Route path="/home/top-header-slider/edit-top-header-slider/:id" element={<ProtectedRoute><TopHeaderSliderChange /></ProtectedRoute>}/>
              {/* <Route path="/settings/home-page-bottom-slider" element={<HomePageBottomSlider/>}/> */}
              <Route path="/home/home-page-bottom-slider/add-home-page-bottom-slider" element={<ProtectedRoute><HomePageBottomSliderChange /></ProtectedRoute>}/>
              <Route path="/home/home-page-bottom-slider/edit-home-page-bottom-slider/:id" element={<ProtectedRoute><HomePageBottomSliderChange /></ProtectedRoute>}/>
              {/* <Route path="/settings/project-slider" element={<ProjectSlider/>}/> */}
              <Route path="/home/project-slider/add-project-slider" element={<ProtectedRoute><ProjectSliderChange /></ProtectedRoute>}/>
              <Route path="/home/project-slider/edit-project-slider/:id" element={<ProtectedRoute><ProjectSliderChange /></ProtectedRoute>}/>
              {/* <Route path="/settings/home-page" element={<HomePage/>}/> */}
              <Route path="/home/home-page/add-home-page" element={<ProtectedRoute><HomePageChange /></ProtectedRoute>}/>
              <Route path="/home/home-page/edit-home-page/:id" element={<ProtectedRoute><HomePageChange /></ProtectedRoute>}/>
              <Route path="/orders" element={<ProtectedRoute><Orders/></ProtectedRoute>}/>
              <Route path="/instore" element={<ProtectedRoute><InStore/></ProtectedRoute>}/>
              <Route path="/instore/add-order" element={<ProtectedRoute><OrderChange/></ProtectedRoute>} />
            </Routes>
    </Router>
  );
}

export default App;
