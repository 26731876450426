import React from "react"
import style from './navbar.module.css'
import Navitem from "./Navitem";

const Navbar=({active})=>{
    return (
        <>
        <div className={active?style.navbar_section_inactive:style.navbar_section}>
            <div className={style.navbar_item_container}>
            <Navitem active={active}/>
            </div>
        </div>
        </>
    )
}
export default Navbar;