import React, { useState, useEffect } from "react";
import style from "./usp.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import Tables from "../../components/tables/Tables";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getUspData,
  uspDataDelete,
  sigleUspDataAction,
  blankDataAction,
  editBlankData,
} from "../../store/slices/uspSlice";
import { Space, Tag } from "antd";
import BigLoader from "../../components/bigLoader/BigLoader";
import AddImg from "../../assets/icons8-add-24.png";
import EditImg from "../../assets/editNew.svg";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import { useNavigate } from "react-router";
import ColorLoader from "../../components/colorLoader/ColorLoader";
import banner1 from "../../assets/images3.png";
import banner2 from "../../assets/images2.png";
import { mainUrl } from "../../utils/common";

const Usp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.usp.data);
  const loading = useSelector((state) => state.usp.loading);
  const deleteLoad = useSelector((state) => state.usp.deleteLoad);
  const [state, setState] = useState();
  const [deleteId, setDeleteId] = useState();

  useEffect(() => {
    dispatch(getUspData());
  }, []);
  useEffect(() => {
    setState(data);
  }, [data]);
  const addHandler = () => {
    dispatch(blankDataAction());
    dispatch(editBlankData());
    navigate("/home/usp/add-usp");
  };
  const editHandler = (record) => {
    dispatch(sigleUspDataAction(record._id));
    navigate(`/home/usp/edit-usp/${record._id}`);
  };
  const deleteHandler = (record) => {
    setDeleteId(record._id);
    dispatch(uspDataDelete(record._id));
  };
  return (
    <div className={style.usp_section}>
      <PageHeader
        name="usp details"
        count={state ? state.length : ""}
        total={true}
        btnName={data?.length === 4 ? "" : "add usp"}
        img={AddImg}
        addHandler={addHandler}
      />
      {loading ? (
        <BigLoader />
      ) : (
        <div className={style.usp_container}>
          {state &&
            state.map((item, index) => {
              return (
                <div key={index} className={style.usp_data_box}>
                  <div className={style.usp_data_header}>
                    <div className={style.usp_data_header_img}>
                      <img
                        crossOrigin="anonymous"
                        src={item.icon ? `${mainUrl()}/${item.icon}` : "-"}
                        alt="image"
                      />
                    </div>
                    <div className={style.usp_data_header_text}>
                      {item.heading ? item.heading : "NA"}
                    </div>
                  </div>
                  <div className={style.usp_data_main_image}>
                    <img
                      crossOrigin="anonymous"
                      src={
                        item.contentImage
                          ? `${mainUrl()}/${item.contentImage}`
                          : "-"
                      }
                      alt="image"
                    />
                  </div>
                  <div className={style.usp_data_title}>
                    {item.title ? item.title : "NA"}
                  </div>
                  <div className={style.usp_data_message}>
                    {item.message ? item.message : "NA"}
                  </div>
                  <div className={style.usp_data_footer}>
                    <div
                      className={style.delete_edit_hand_1}
                      onClick={() => editHandler(item)}
                      style={{
                        marginBottom: data?.length == 5 ? "5px" : "0px",
                        width: data?.length == 5 ? "100%" : "",
                      }}
                    >
                      <img src={EditImg} alt="edit image" />
                      <div>edit</div>
                    </div>
                    <div
                      className={style.delete_edit_hand}
                      onClick={() => deleteHandler(item)}
                      style={{ width: data?.length == 5 ? "100%" : "" }}
                    >
                      {deleteLoad && item._id === deleteId ? (
                        <ColorLoader />
                      ) : (
                        <>
                          <img src={DeleteImg} alt="edit image" />
                          <div>delete</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
export default Usp;
