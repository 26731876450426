import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {blogsList,blogsAdd,blogsUpdate,blogDelete,blogStatus,blogEdit}=API

const initialState = { 
    data:[],
    singleData:[],
    singleBlog:[],
    error: null, 
    loading:false,
    message:'',
    deleteLoad:false,
    editData:[]
}

const blogsSlice = createSlice({
    name: "blogs",
    initialState,
    reducers: {
      setData(state,action){
          state.data=action.payload;
      },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
    },
      setDeleteLoad(state,action){
          state.deleteLoad=action.payload
      },
      setAfterDeleteBlog(state,action){
          state.data=state.data.filter((item)=>item._id !== action.payload)
      },
      setBlogAdd(state,action){
          state.data=[action.payload,...state.data]
      },
      setEditBlog(state,action){
          state.singleBlog=state.data.filter((item)=>item._id === action.payload)
      },
      setBlankSingleData(state,action){
        state.singleBlog=[]
      },
      setChnageStatus(state,action){
        state.singleData=state.data.filter((item)=>item._id == action.payload)
        state.singleData[0].status=state.singleData[0].status===1?0:state.singleData[0].status===0?1:''
      },
      setEditData(state,action){
          state.editData=action.payload
      }
    },
  });
  
  export const {setEditData,setChnageStatus, setLoading, setMessage,setError,setData,setDeleteLoad,setBlogAdd,setAfterDeleteBlog,setEditBlog,setBlankSingleData} = blogsSlice.actions;
  export default blogsSlice.reducer;

  export const getBlogsData = () => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await blogsList()
      if (res.data.success) {
          dispatch(setData(res.data.data))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };
  export const blogDataAdded=(data) => async (dispatch)=>{
    dispatch(setDeleteLoad(true));
    try{
      const res= await blogsAdd(data)
      if(res.data.success) {
         dispatch(setBlogAdd(data))
         dispatch(setDeleteLoad(false))
         dispatch(setMessage(res.data.message))
         message.success(res.data?.message);
      } else {
        dispatch(setDeleteLoad(false));
        dispatch(setError(res.data.message));
        message.error(res.data.message);
    }
    } catch(e){
      dispatch(setDeleteLoad(false));
      dispatch(setError(e.response.data.message));
      message.error(e.response.data.message);
    }
}

export const sigleBlogDataAction=(id) =>(dispatch)=>{
              dispatch(setEditBlog(id))
}
export const blankDataAction=() =>(dispatch)=>{
             dispatch(setBlankSingleData())
}
export const blogDataUpdate=(id,data) => async (dispatch)=>{
  dispatch(setDeleteLoad(true));
  try{
    const res= await blogsUpdate(id,data)
    if(res.data.success) {
       dispatch(setBlankSingleData())
       dispatch(setEditData())
       dispatch(setDeleteLoad(false))
       dispatch(setMessage(res.data.message))
       message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
  }
  } catch(e){
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
}
export const blogEditAction=(id) => async (dispatch)=>{
  try{
    const res= await blogEdit(id)
    if(res.data.success) {
       dispatch(setEditData(res.data.data))
    } else {
      message.error(res.data.message);
  }
  } catch(e){
    message.error(e.response.data.message);
  }
}
export const editBlankData=() =>(dispatch)=>{
        dispatch(setEditData())
}
  export const blogDataDelete=(id) => async (dispatch)=>{
          dispatch(setDeleteLoad(true));
          try{
            const res= await blogDelete(id)
            if(res.data.success) {
               dispatch(setAfterDeleteBlog(id))
               dispatch(setDeleteLoad(false))
               dispatch(setMessage(res.data.message))
               message.success(res.data?.message);
            } else {
              dispatch(setDeleteLoad(false));
              dispatch(setError(res.data.message));
              message.error(res.data.message);
          }
          } catch(e){
            dispatch(setDeleteLoad(false));
            dispatch(setError(e.response.data.message));
            message.error(e.response.data.message);
          }
  }

export const statusChangeAction = (id) => async (dispatch) => {
    dispatch(setDeleteLoad(true));
   try {
   const res= await blogStatus(id)
    if (res.data.success) {
     dispatch(setChnageStatus(id))
     dispatch(setDeleteLoad(false));
     dispatch(setMessage(res.data.message))
     message.success(res.data?.message);
} else {
   dispatch(setDeleteLoad(false));
   dispatch(setError(res.data.message));
}
} catch (e){
dispatch(setDeleteLoad(false));
dispatch(setError(e.response.data.message));
message.error(e.response.data.message);
}
};