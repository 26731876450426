import React, { useState, useEffect } from "react";
import style from "./blogChange.module.css";
import PageHeader from "../pageHeader/PageHeader";
import Layout from "../layout/Layout";
import { useNavigate } from "react-router";
import BackImg from "../../assets/back.svg";
import UploadImage from "../uploadImage/UploadImage";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ImgCrop from "antd-img-crop";
import {
  blogDataAdded,
  blogDataUpdate,
  blogEditAction,
} from "../../store/slices/blogsSlice";
import { uploadImageDataAction } from "../../store/slices/uploadSlice";
import SmallLoader from "../smallLoader/SmallLoader";
import { getToken, trelifImageUpload, mainUrl } from "../../utils/common";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import TextEditar from "../textEditar/TextEditar";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
  Typography,
} from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;
const { Text } = Typography;

const BlogChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.blog.deleteLoad);
  const message = useSelector((state) => state.blog.message);
  const singleBlog = useSelector((state) => state.blog.singleBlog);
  const editData = useSelector((state) => state.blog.editData);
  const [form] = Form.useForm();
  const urls = "/blogs/add-blog";
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [newImage, setNewImage] = useState("");
  const [addNewImg, setAddNewImg] = useState("");
  const [images, setImages] = useState("");
  const [newImages, setNewImages] = useState("");
  const [addNewImage, setAddNewImage] = useState("");
  const [rich, setRich] = useState("");
  const [titleNew, setTitleNew] = useState(""); // for Character limit
  const [error, setError] = useState("");
  const richChnage = (e) => {};

  useEffect(() => {
    setAddNewImage("");
    setAddNewImg("");
  }, []);

  // for Character limit
  useEffect(() => {
    if (editData && editData.title) {
      setTitleNew(editData.title);
    }
  }, [editData]);

  const backHandler = () => {
    navigate("/blogs");
  };
  const onChangeDate = () => {};
  const onFinish = (values) => {
    values["date"] = moment(values.date).format("MM-DD-YYYY");
    let data = {
      title: values.title,
      content: values.content,
      image: newImage ? newImage : image,
      optionalImage: newImages ? newImages : images,
    };
    let datas = {
      title: values.title,
      content: values.content,
      image: addNewImg,
      optionalImage: addNewImage,
    };
    if (path === urls) {
      dispatch(blogDataAdded(datas));
    } else {
      let idData = path.split("/");
      let id = idData[idData.length - 1];
      dispatch(
        blogDataUpdate(singleBlog[0]?._id ? singleBlog[0]?._id : id, data)
      );
    }
  };
  const onFinishFailed = (errorInfo) => {};
  useEffect(() => {
    if (
      message === "Blog added successfully!" ||
      message === "Blog updated successfully!"
    ) {
      navigate("/blogs");
    }
  }, [message]);
  useEffect(() => {
    let data = singleBlog[0];
    // form.setFieldValue("title",data?.title?data?.title:'')
    form.setFieldValue("content", data?.content ? data?.content : "");
    setNewImage(data?.image?.toString());
    setNewImages(data?.optionalImage?.toString());
  }, [singleBlog]);

  // for Character limit
  useEffect(() => {
    form.setFieldValue("title", titleNew);
  }, [titleNew]);

  useEffect(() => {
    if (path === urls) {
    } else {
      let data = path.split("/");
      let id = data[data.length - 1];
      dispatch(blogEditAction(id));
    }
  }, []);
  useEffect(() => {
    let data = editData;
    // form.setFieldValue("title",data?.title?data?.title:'')
    form.setFieldValue("content", data?.content ? data?.content : "");
    setNewImage(data?.image);
    setNewImages(data?.optionalImage);
  }, [editData]);
  const deleteImgHandler = () => {
    setNewImage("");
  };
  const deleteImgHandlers = () => {
    setNewImages("");
  };
  // for title limit
  // const maxTCharacters = 30;
  const handleTitleLimitChange = (e) => {
    const titleNew = e.target.value;
    setTitleNew(titleNew);
    // if (titleNew.length <= maxTCharacters) {
    // }
  };
  const beforeUpload = (file) => {
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
      ];
      if (!allowedTypes.includes(file.type)) {
        setError("Invalid file type. Please select an image.");
        return;
      } else {
        setError("");
      }
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            const minWidth = 1600; // Example minimum width
            const minHeight = 1024; // Example minimum height

            if (width >= minWidth && height >= minHeight) {
              setError("");
            } else {
              setError(
                "Invalid dimensions for image. Please select an image with a width of 1600 pixels and a height of 1024 pixels."
              );
            }
          };
        };

        reader.readAsDataURL(file);
      }

      // yha set images
    }
  };
  const propsUpload = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImage(error ? "" : info?.file?.response?.data?.image.toString());
        setNewImage(error ? "" : info?.file?.response?.data?.image.toString());
        setAddNewImg(error ? "" : info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const propsUploads = {
    name: "image",
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        setImages(error ? "" : info?.file?.response?.data?.image.toString());
        setAddNewImage(
          error ? "" : info?.file?.response?.data?.image.toString()
        );
        setNewImages(error ? "" : info?.file?.response?.data?.image.toString());
        //message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === "error") {
        //message.error(`${info.file.name} file upload failed.`)
      }
    },
  };
  const deleteImgHandlerAdd = (image) => {
    setAddNewImg("");
  };
  const deleteImageHandlerAdd = (image) => {
    setAddNewImage("");
  };
  return (
    <>
      <Layout>
        <div className={style.blogChange_section}>
          <BackButton
            backButtonHandler={backHandler}
            name={path === urls ? "blog add" : "blog update"}
          />
          <Form
            className={style.appointments_form}
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"vertical"}
            form={form}
            style={{
              maxWidth: "100%",
            }}
          >
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Title (max 110 characters)"
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please input your title!",
                },
              ]}
            >
              <Input
                value={titleNew}
                onChange={handleTitleLimitChange}
                placeholder="Enter your title"
                maxLength={110}
              />
              <Text
                // type={titleNew.length > maxTCharacters ? "danger" : "secondary"}
              >
                {/* {`${titleNew.length} / ${maxTCharacters} characters`} */}
              </Text>
              {/* <Input placeholder="Enter your title" /> */}
            </Form.Item>
            <div className={style.images_dimensions}>
              the images dimensions should be (1600px)x(1024px).
            </div>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
              }}
              label="Display Image"
              name="image"
            >
              {path === urls ? (
                <>
                  {addNewImg ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={addNewImg ? `${mainUrl()}/${addNewImg}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImg ? (
                    ""
                  ) : (
                    <Upload
                      beforeUpload={beforeUpload}
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImage ? `${mainUrl()}/${newImage}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandler}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {newImage ? (
                    ""
                  ) : (
                    <Upload
                      beforeUpload={beforeUpload}
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUpload}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      {newImage?.length < 1 && "+ Upload"}
                    </Upload>
                  )}
                </>
              )}
            </Form.Item>
            <Form.Item
              className={style.appointments_form_item_img}
              style={{
                background: "#fff",
                borderRadius: "7px",
                paddingLeft: "5px",
              }}
              label="Content Image"
              name="optionalImage"
            >
              {path === urls ? (
                <>
                  {addNewImage ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={
                            addNewImage ? `${mainUrl()}/${addNewImage}` : "-"
                          }
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImageHandlerAdd}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {addNewImage ? (
                    ""
                  ) : (
                    <Upload
                      beforeUpload={beforeUpload}
                      action={trelifImageUpload}
                      listType="picture-card"
                      showUploadList={false}
                      onPreview={() => {}}
                      {...propsUploads}
                      headers={{
                        Authorization: `Bearer ${getToken()}`,
                      }}
                    >
                      + Upload
                    </Upload>
                  )}
                </>
              ) : (
                <>
                  {newImages ? (
                    <div className={style.common_edit_image_antd}>
                      <div className={style.common_edit_image_antd_container}>
                        <img
                          src={newImages ? `${mainUrl()}/${newImages}` : "-"}
                          alt="edit image"
                        />
                        <div className={style.common_edit_delt_antd}>
                          <img
                            src={DeleteImg}
                            alt="delete image"
                            onClick={deleteImgHandlers}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <Upload
                    beforeUpload={beforeUpload}
                    action={trelifImageUpload}
                    listType="picture-card"
                    onPreview={() => {}}
                    showUploadList={false}
                    {...propsUploads}
                    headers={{
                      Authorization: `Bearer ${getToken()}`,
                    }}
                  >
                    {newImages?.length < 1 && "+ Upload"}
                  </Upload>
                </>
              )}
            </Form.Item>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <Form.Item
              className={style.appointments_form_item}
              style={{ width: "100%" }}
              label="Content"
              name="content"
              rules={[
                {
                  required: true,
                  message: "Please input your content!",
                },
              ]}
            >
              <ReactQuill
                theme="snow"
                placeholder="content here...."
                className={style.quill_container}
                value={rich}
                onChange={richChnage}
              />
              {/* <TextEditar/> */}
            </Form.Item>

            <Form.Item className={style.appointments_form_submit_all}>
              <Button
                className={style.appointments_form_submit}
                // type="primary"
                htmlType="submit"
                style={{ color: "#fff" }}
              >
                {loading ? (
                  <SmallLoader />
                ) : path === urls ? (
                  "add blog"
                ) : (
                  "update blog"
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </>
  );
};
export default BlogChange;
