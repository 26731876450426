import React, { useState, useEffect } from "react";
import style from "./orderChange.module.css";
import Layout from "../layout/Layout";
import DeleteImg from "../../assets/icons8-delete-24 .png";
import SmallLoader from "../smallLoader/SmallLoader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BackImg from "../../assets/back.svg";
import PageHeader from "../pageHeader/PageHeader";
import AddImg from "../../assets/icons8-add-24.png";
import {
  getInstoreCustomer,
  getInstoreDiscount,
  instoreDataAddAction,
} from "../../store/slices/instoreSlice";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  Divider,
  TimePicker,
  Typography,
} from "antd";
import { message as antdMessage } from "antd";
import BackButton from "../backButton/BackButton";
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const OrderChange = () => {
  const path = window.location.pathname;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const urls = "/instore/add-order";
  const navigate = useNavigate();
  const message = useSelector((state) => state.instore.message);
  const loading = useSelector((state) => state.instore.loading);
  const customerData = useSelector((state) => state.instore.instoreCustomer);
  const discountData = useSelector((state) => state.instore.instoreDiscount);
  const [customerList, setCustomerList] = useState();
  const [discountList, setDiscountList] = useState();
  const [customer, setCustomer] = useState();
  const [addForm, setAddForm] = useState([
    {
      productName: "",
      price: "",
      description: "",
      quantity: "",
      totalOfProduct: "",
    },
  ]);
  const [discount, setDiscount] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [finalPrice, setFinalPrice] = useState();
  const [price, setPrice] = useState();
  const [quantity, setQuantity] = useState();
  const [perc, setPerc] = useState();
  const [er, setEr] = useState();
  const [err, setErr] = useState();
  const [errr, setErrr] = useState();
  const [errrr, setErrrr] = useState();
  const [billingError, setBillingError] = useState("");
  const [shippingError, setShippingError] = useState("");
  const [billingAddressId, setBillingAddressId] = useState(null);
  const [shippingAddressId, setShippingAddressId] = useState(null);
  useEffect(() => {
    dispatch(getInstoreCustomer());
    dispatch(getInstoreDiscount());
  }, []);
  useEffect(() => {
    setCustomerList(customerData);
  }, [customerData]);
  useEffect(() => {
    setDiscountList(discountData);
  }, [discountData]);

  useEffect(() => {
    if (customer && customerList) {
      const selectedCustomer = customerList.find((ele) => ele._id === customer);
      if (!selectedCustomer.address.length) {
        antdMessage.error(
          "No address found for the selected customer. Please enter the address of the customer."
        );
      }
    }
  }, [customer]);

  const backHandler = () => {
    navigate("/instore");
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const onSearch = (value) => { };
  const onChangeCustomer = (value) => {
    setErrrr("");
    setCustomer(value);
    setBillingAddressId(null);
    setShippingAddressId(null);
  };

  // const getAddressOptions = (customerId, type) => {
  //   const selectedCustomer = customerList.find((cust) => cust._id === customerId);
  //   if (!selectedCustomer) return [];
  //   return selectedCustomer.address.filter((addr) => addr.addressType === type);
  // };

  const handleBillingAddressChange = (value) => {
    setBillingAddressId(value);
    setBillingError("");
  };

  const handleShippingAddressChange = (value) => {
    setShippingAddressId(value);
    setShippingError("");
  };

  const onChangeDiscount = (value) => {
    setDiscount(value);
    const data = discountData.filter((item) => {
      return item._id === value;
    });
    setPerc(data);
    let discountTypes = data ? data[0].type : "";
    let discounts = data ? data[0].value : "";
    if (discountTypes === "percentage") {
      var discountedPrice =
        Number(totalPrice) - Number(totalPrice) * (Number(discounts) / 100);
    } else {
      var discountedPrice = Number(totalPrice) - Number(discounts);
    }
    setFinalPrice(discountedPrice);
  };
  const onChangeTotalPrice = () => { };
  const onChangeFinalPrice = () => { };

  useEffect(() => {
    if (message === "Manual order added successfully!") {
      navigate("/instore");
    }
  }, [message]);
  const productAryChange = (e, index) => {
    const newArray = addForm.map((item, i) => {
      if (index === i) {
        if (e.target.name === "description") {
          const description = e.target.value;
          if (description.length <= 300) {
            return { ...item, [e.target.name]: description };
          } else {
            const sliced = description.slice(0, 300);
            return {
              ...item,
              [e.target.name]: sliced,
            };
          }
        } else {
          return { ...item, [e.target.name]: e.target.value };
        }
        // return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setAddForm(newArray);

    if (e.target.name === "productName") {
      setEr("");
    }
    if (e.target.name === "price") {
      setPrice(e.target.value);
      setErr("");
    }
    if (e.target.name === "quantity") {
      setQuantity(e.target.value);
      setErrr("");
    }
    if (e.target.name === "price") {
      let prices = Number(e.target.value);
      let quantitys = Number(quantity);
      let total = prices * quantitys;
      const updatedData = addForm.map((item, i) => {
        return index == i
          ? { ...item, totalOfProduct: total, price: e.target.value }
          : item;
      });
      setAddForm(updatedData);

      const data = [...updatedData];
      const newData = data.map((item) => {
        return item.totalOfProduct;
      });
      const totalValue = newData.reduce(
        (acc, currentValue) => acc + currentValue,
        0
      );
      setTotalPrice(totalValue);

      let discountTypes = perc ? perc[0].type : "";
      let discounts = perc ? perc[0].value : "";
      if (discountTypes === "percentage") {
        var discountedPrice =
          Number(totalValue) - Number(totalValue) * (Number(discounts) / 100);
      } else {
        var discountedPrice = Number(totalValue) - Number(discounts);
      }
      setFinalPrice(discountedPrice);
    } else if (e.target.name == "quantity") {
      let quantitys = Number(e.target.value);
      let prices = Number(price);
      let total = prices * quantitys;
      const updatedData = addForm.map((item, i) => {
        return index == i
          ? { ...item, totalOfProduct: total, quantity: e.target.value }
          : item;
      });
      setAddForm(updatedData);

      const data = [...updatedData];
      const newData = data.map((item) => {
        return item.totalOfProduct;
      });
      const totalValue = newData.reduce(
        (acc, currentValue) => acc + currentValue,
        0
      );
      setTotalPrice(totalValue);

      let discountTypes = perc ? perc[0].type : "";
      let discounts = perc ? perc[0].value : "";
      if (discountTypes === "percentage") {
        var discountedPrice =
          Number(totalValue) - Number(totalValue) * (Number(discounts) / 100);
      } else {
        var discountedPrice = Number(totalValue) - Number(discounts);
      }
      setFinalPrice(discountedPrice);
    }
  };

  const addFormHnadler = () => {
    setPrice("");
    setQuantity("");
    const newItem = {
      productName: "",
      price: "",
      description: "",
      quantity: "",
      totalOfProduct: "",
    };
    setAddForm((existingItems) => [...existingItems, newItem]);
  };
  const variantDelete = (indexs) => {
    setPrice("");
    setQuantity("");
    let data = [...addForm];
    let updateData = data.filter((item, index) => index !== indexs);
    setAddForm(updateData);

    const datas = data.filter((item, index) => {
      return index === indexs;
    });
    let val = datas ? datas[0].totalOfProduct : "";
    let totalPrices = Number(totalPrice) - Number(val);
    let discountTypes = perc ? perc[0].type : "";
    let discounts = perc ? perc[0].value : "";
    if (discountTypes === "percentage") {
      var discountedPrice =
        Number(totalPrices) - Number(totalPrices) * (Number(discounts) / 100);
    } else {
      var discountedPrice = Number(totalPrices) - Number(discounts);
    }
    setFinalPrice(discountedPrice);
    setTotalPrice(totalPrices);
  };
  const addDataProduct = () => {
    for (let a = 0; a < addForm.length; a++) {
      var name = addForm[a]?.productName;
      var prices = addForm[a]?.price;
      var quantitys = addForm[a]?.quantity;
    }

    if (!name && !prices && !quantitys && !customer) {
      setEr("Please input your product name!");
      setErr("Please input your price!");
      setErrr("Please input your quantity!");
      setErrrr("Please select customer name");
    } else if (!name) {
      setEr("Please input your product name!");
    } else if (!prices) {
      setErr("Please input your price!");
    } else if (!quantitys) {
      setErrr("Please input your quantity!");
    } else if (!customer) {
      setErrrr("Please select customer name");
    } else if (!billingAddressId && !shippingAddressId) {
      setBillingError("Please select a billing address.");
      setShippingError("Please select a shipping address.");
    } else if (!billingAddressId) {
      setBillingError("Please select a billing address.");
    } else if (!shippingAddressId) {
      setShippingError("Please select a shipping address.");
    } else {
      const data = {
        products: addForm,
        discountID: discount,
        customerID: customer,
        billingAddressId: billingAddressId,
        shippingAddressId: shippingAddressId,
      };
      dispatch(instoreDataAddAction(data));
    }
  };
  const removeDiscountHandler = () => {
    setDiscount();
    setPerc();
    setFinalPrice(totalPrice);
  };
  return (
    <Layout>
      <div className={style.blogChange_section}>
        <BackButton backButtonHandler={backHandler} name="add order" />

        <div style={{ width: "100%" }}>
          <div className={style.appointments_form_custom_select}>
            <label htmlFor="customer">
              <span>*</span>Customer
            </label>
            <br />
            <Select
              showSearch
              className={style.custom_dropdown}
              placeholder="please search and select customer name"
              optionFilterProp="children"
              onChange={onChangeCustomer}
              value={customer}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {customerList &&
                customerList.map((item, index) => {
                  return item.lastName ? (
                    <Option key={index} value={item._id}>
                      {item.firstName +
                        " " +
                        item.lastName +
                        " " +
                        " " +
                        "(" +
                        item.email +
                        ")"}
                    </Option>
                  ) : (
                    <Option key={index} value={item._id}>
                      {item.firstName + " " + " " + "(" + item.email + ")"}
                    </Option>
                  );
                })}
            </Select>
            <p className={style.instore_error_sectionrt}>{errrr}</p>
          </div>
        </div>
        <div>
          {addForm &&
            addForm.map((item, index) => {
              return (
                <div key={index} className={style.appointments_form}>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    className={style.appointments_form_item}
                    label="Product Name"
                    name="productName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your product name"
                      name="productName"
                      value={addForm[index].productName}
                      onChange={(e) => productAryChange(e, index)}
                    />
                    <p className={style.instore_error_section}>{er}</p>
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    className={style.appointments_form_item}
                    label="Price"
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: "Please input your price!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your price"
                      name="price"
                      value={addForm[index].price}
                      onChange={(e) => productAryChange(e, index)}
                      onKeyDown={(e) => {
                        const key = e.key;
                        if (
                          (key < "0" || key > "9") &&
                          key !== "Backspace" &&
                          key !== "Delete" &&
                          key !== "Tab"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <p className={style.instore_error_section}>{err}</p>
                  </Form.Item>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    className={style.appointments_form_item}
                    label="Quantity"
                    name="quantity"
                    rules={[
                      {
                        required: true,
                        message: "Please input your quantity!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your quantity"
                      name="quantity"
                      value={addForm[index].quantity}
                      onChange={(e) => productAryChange(e, index)}
                      onKeyDown={(e) => {
                        const key = e.key;
                        if (
                          (key < "0" || key > "9") &&
                          key !== "Backspace" &&
                          key !== "Delete" &&
                          key !== "Tab"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <p className={style.instore_error_section}>{errr}</p>
                  </Form.Item>
                  <div className={style.appointments_form_custom_select}>
                    <label htmlFor="total">Total</label>
                    <br />
                    <input
                      type="number"
                      disabled
                      placeholder=" your total amount"
                      name="totalOfProduct"
                      value={addForm[index].totalOfProduct}
                      onChange={(e) => productAryChange(e, index)}
                    />
                  </div>
                  <Form.Item
                    labelCol={{ span: 24 }}
                    className={style.appointments_form_item}
                    style={{ width: "100%" }}
                    label="Description"
                  // name="description"
                  >
                    <TextArea
                      rows={4}
                      placeholder="description here...."
                      name="description"
                      value={addForm[index].description}
                      onChange={(e) => productAryChange(e, index)}
                    />
                    <Text
                      type={
                        addForm[index].description.length > 300
                          ? "danger"
                          : "secondary"
                      }
                    >
                      {`${addForm[index].description.length
                        } / ${300} characters`}
                    </Text>
                  </Form.Item>
                  {index === 0 ? (
                    ""
                  ) : (
                    <div
                      className={style.appointments_form_add_delete}
                      onClick={() => variantDelete(index)}
                    >
                      <div
                        className={style.delete_edit_hand}
                        style={{ marginLeft: "5px" }}
                      >
                        <>
                          <img src={DeleteImg} alt="edit image" />
                          <div>delete</div>
                        </>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          <div className={style.appointments_form_add_delete}>
            <div className={style.delete_edit_hand_1} onClick={addFormHnadler}>
              <img src={AddImg} alt="edit image" />
              <div>add product</div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", }}>
          <div className={style.appointments_form_custom_select}>
            {customer && (
              <Form.Item
                labelCol={{ span: 24 }}
                className={style.appointments_form_item}
                style={{ width: "100%" }}
                name="billingAddress"
                label="Billing Address"
                rules={[{ required: true, message: "Please select a billing address" }]}
              >
                <Select
                  className={style.custom_dropdown}
                  placeholder="Select a billing address"
                  onChange={handleBillingAddressChange}
                  value={billingAddressId}
                >
                  {customerList.find((cust) => cust._id === customer)?.address.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {`${item.streetAddress}, ${item.city}, ${item.state}, ${item.zipCode}`}
                    </Option>
                  ))}
                </Select>
                <p className={style.instore_error_section}>{billingError}</p>
              </Form.Item>
            )}
          </div>
          <div className={style.appointments_form_custom_select}>
            {customer && (
              <Form.Item
                labelCol={{ span: 24 }}
                className={style.appointments_form_item}
                style={{ width: "100%" }}
                name="shippingAddress"
                label="Shipping Address"
                rules={[{ required: true, message: "Please select a shipping address" }]}
              >
                <Select
                  className={style.custom_dropdown}
                  placeholder="Select a shipping address"
                  onChange={handleShippingAddressChange}
                  value={shippingAddressId}
                >
                  {customerList.find((cust) => cust._id === customer)?.address.map((item) => (
                    <Option key={item._id} value={item._id}>
                      {`${item.streetAddress}, ${item.city}, ${item.state}, ${item.zipCode}`}
                    </Option>
                  ))}
                </Select>
                <p className={style.instore_error_section}>{shippingError}</p>
              </Form.Item>
            )}
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={style.appointments_form_custom_select}>
            <label htmlFor="totalPrice">Total Price</label>
            <br />
            <input
              type="number"
              disabled
              placeholder=" your total price"
              name="totalPrice"
              value={totalPrice}
              onChange={(e) => onChangeTotalPrice(e)}
            />
          </div>

          <div className={style.appointments_form_custom_select}>
            <label htmlFor="discount">Discount</label>
            <br />
            <Select
              // showSearch
              className={style.custom_dropdown}
              placeholder="please select discount code"
              optionFilterProp="children"
              onChange={onChangeDiscount}
              value={discount}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {discountList &&
                discountList.map((item, index) => {
                  return (
                    <Option key={index} value={item._id}>
                      {item.code +
                        "" +
                        " " +
                        " " +
                        "(" +
                        item.type +
                        "-" +
                        item.value +
                        ")"}
                    </Option>
                  );
                })}
            </Select>
            <div
              onClick={removeDiscountHandler}
              className={style.remove_discount}
            >
              remove discount
            </div>
          </div>
        </div>

        <div className={style.appointments_form_custom_select}>
          <label htmlFor="finalPrice">Final Price</label>
          <br />
          <input
            type="number"
            disabled
            placeholder=" your final price"
            name="finalPrice"
            value={finalPrice}
            onChange={(e) => onChangeFinalPrice(e)}
          />
        </div>
        <div className={style.appointments_form_submit_all}>
          <div
            className={style.appointments_form_submit}
            onClick={addDataProduct}
          >
            <div>{loading ? <SmallLoader /> : "add order"}</div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default OrderChange;
