import styles from "./InputError.module.css";

const width = 1300;
const height = 550;

const errors = {
  name: "Please Enter Product Name",
  size: "Please Enter Product Size",
  quantity: "Please Enter Product Quantity",
  productType: "Please Enter Product Type",
  colour: "Please Enter Product Color",
  specifications: "Please Enter Product Specifications",
  price: "Please Enter Product Price",
  material: "Please Enter Product Material",
  category: "Please Enter Product Category",
  details: "Please Enter Product Details",
  description: "Please Enter Description",
  images: "Please Enter Image",
};

const imageFileValidationErrors = {
  type: "Invalid file type. Please select an Image",
  size: `'Invalid dimensions. Please select an image with a width of ${width} pixels and a height of ${height} pixels.'`,
};

export default function InputError({
  showInputErrors,
  inputErrors,
  index,
  field,
}) {
  return (
    <div className={styles.errorContainer}>
      {showInputErrors ? (
        typeof inputErrors[field].find((ele) => ele === index) !==
        "undefined" ? (
          <p className={styles.errorText}>{errors[field]}</p>
        ) : null
      ) : null}
    </div>
  );
}

// export function ImageFileValidationError() {
//   return (
//     <div className={styles.errorContainer}>
//       {showInputErrors ? (
//         typeof inputErrors[field].find((ele) => ele === index) !==
//         "undefined" ? (
//           <p className={styles.errorText}>{errors[field]}</p>
//         ) : null
//       ) : null}
//     </div>
//   );
// }
