import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import style from "./dashboard.module.css";
import PageHeader from "../../components/pageHeader/PageHeader";
import DashboardBlock from "../../components/dashboardBlock/DashboardBlock";
import userImg from "../../assets/custNew.png";
import productImg from "../../assets/prcNew.png";
import blogImg from "../../assets/blgNew.png";
import orderImg from "../../assets/ordNew.png";
import appoImg from "../../assets/appNew.png";
import enqImg from "../../assets/enqNew.png";
import { useDispatch, useSelector } from "react-redux";
import { getDetailsData } from "../../store/slices/dashboardSlice";
import { getOrdersData } from "../../store/slices/ordersSlice";
import { getAppointmentsData } from "../../store/slices/appointmentsSlice";
import { getTestimonialsData } from "../../store/slices/testimonialsSlice";
import { getProductsData } from "../../store/slices/productsSlice";
import BigLoader from "../../components/bigLoader/BigLoader";
import { getToken } from "../../utils/common";
import { useNavigate } from "react-router";
import { PieChart } from "react-minimal-pie-chart";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { countMaterials } from "../../utils/countMaterials";
Chart.register(...registerables);
const Dashboard = () => {
  const [state, setState] = useState();
  const [order, setOrder] = useState();
  const [appointment, setAppointment] = useState();
  const [testimonial, setTestimonial] = useState();
  // const [product, setProduct] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const detailsData = useSelector((state) => state.dashboard.detailsData);
  const loading = useSelector((state) => state.dashboard.loading);
  const orders = useSelector((state) => state.orders.data);
  const appointments = useSelector((state) => state.appointments.data);
  const testimonials = useSelector((state) => state.testimonials.data);
  const products = useSelector((state) => state.products.data);
  // const [leatherette, setLeatherette] = useState();
  // const [suede, setSuede] = useState();
  // const [linen, setLinen] = useState();
  // const [laminate, setLaminate] = useState();
  // const [veneer, setVeneer] = useState();
  // const [pvc, setPvc] = useState();
  // const [acrylicsheets, setAcrylicsheets] = useState();
  // const [marble, setMarble] = useState();
  const [materialsCount, setMaterialsCount] = useState({
    leatherette: 0,
    suede: 0,
    linen: 0,
    laminate: 0,
    veneer: 0,
    pvc: 0,
    acrylicSheets: 0,
    marble: 0,
  });

  useEffect(() => {
    dispatch(getDetailsData());
    dispatch(getOrdersData("msg"));
    dispatch(getAppointmentsData("msg"));
    dispatch(getTestimonialsData("msg"));
    dispatch(getProductsData("msg"));
    const loginData = getToken();
    if (!loginData) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    setState(detailsData);
  }, [detailsData]);
  useEffect(() => {
    setOrder(orders);
  }, [orders]);
  useEffect(() => {
    setAppointment(appointments);
  }, [appointments]);
  useEffect(() => {
    setTestimonial(testimonials);
  }, [testimonials]);
  useEffect(() => {
    // setProduct(products);
    if (products.length) {
      const count = countMaterials(products);
      setMaterialsCount(count);
    }
    // let leatheretteValue = products.filter((item) => {
    //   return item.material === "leatherette";
    // });
    // setLeatherette(leatheretteValue);

    // let suedeValue = products.filter((item) => {
    //   return item.material === "Suede";
    // });
    // setSuede(suedeValue);

    // let linenValue = products.filter((item) => {
    //   return item.material === "linen";
    // });
    // setLinen(linenValue);

    // let laminateValue = products.filter((item) => {
    //   return item.material === "Laminate";
    // });
    // setLaminate(laminateValue);

    // let veneerValue = products.filter((item) => {
    //   return item.material === "Veneer";
    // });
    // setVeneer(veneerValue);

    // let pvcValue = products.filter((item) => {
    //   return item.material === "PVC";
    // });
    // setPvc(pvcValue);

    // let acrylicsheetsValue = products.filter((item) => {
    //   return item.material === "Acrylic Sheets";
    // });
    // setAcrylicsheets(acrylicsheetsValue);

    // let marbleValue = products.filter((item) => {
    //   return item.material === "Marble";
    // });
    // setMarble(marbleValue);
  }, [products]);

  const dataChart = {
    labels: [
      "Suede",
      "Leatherette",
      "Linen",
      "Laminate",
      "Veneer",
      "PVC",
      "Acrylic Sheets",
      "Marble",
    ],
    // datasets: [
    //   {
    //     data: [
    //       suede ? suede.length : 0,
    //       leatherette ? leatherette.length : 0,
    //       linen ? linen.length : 0,
    //       laminate ? laminate.length : 0,
    //       veneer ? veneer.length : 0,
    //       pvc ? pvc.length : 0,
    //       acrylicsheets ? acrylicsheets.length : 0,
    //       marble ? marble.length : 0,
    //     ],
    //     backgroundColor: [
    //       "#168C98",
    //       "#0FA368",
    //       "#F58320",
    //       "#F30F10",
    //       "#3BD755",
    //       "#3F45C8",
    //     ],
    //     hoverBackgroundColor: [
    //       "#168C98",
    //       "#0FA368",
    //       "#F58320",
    //       "#F30F10",
    //       "#3BD755",
    //       "#3F45C8",
    //     ],
    //   },
    // ],

    datasets: [
      {
        data: [
          materialsCount.suede,
          materialsCount.leatherette,
          materialsCount.linen,
          materialsCount.laminate,
          materialsCount.veneer,
          materialsCount.pvc,
          materialsCount.acrylicSheets,
          materialsCount.marble,
        ],
        backgroundColor: [
          "#168C98",
          "#0FA368",
          "#F58320",
          "#F30F10",
          "#3BD755",
          "#3F45C8",
        ],
        hoverBackgroundColor: [
          "#168C98",
          "#0FA368",
          "#F58320",
          "#F30F10",
          "#3BD755",
          "#3F45C8",
        ],
      },
    ],

    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2,
      },
    },
    text: "23%",
  };
  return (
    <Layout>
      <div className={style.dashboard_section}>
        <PageHeader name="dashboard" />
        {loading ? (
          <BigLoader />
        ) : (
          <>
            <div className={style.dashboard_header}>
              <div className={style.dashboard_header_hed}>Statistics</div>
              <div className={style.dashboard_header_block}>
                <DashboardBlock
                  title="total customers"
                  img={userImg}
                  value={state ? state[0]?.totalUser : "NA"}
                  color="#168C98"
                />
                <DashboardBlock
                  title="total products"
                  img={productImg}
                  value={state ? state[0]?.totalProduct : "NA"}
                  color="#3BD755"
                />
                <DashboardBlock
                  title="total blogs"
                  img={blogImg}
                  value={state ? state[0]?.totalBlog : "NA"}
                  color="#3F45C8"
                />
                <DashboardBlock
                  title="team"
                  img={orderImg}
                  value={state ? state[0]?.teamCount : "NA"}
                  color="#F58320"
                />
                <DashboardBlock
                  title="newsletter"
                  img={appoImg}
                  value={state ? state[0]?.newsletterCount : "NA"}
                  color="#F30F10"
                />
                <DashboardBlock
                  title="enquiries"
                  img={enqImg}
                  value={state ? state[0]?.enquiriesCount : "NA"}
                  color="#3BD755"
                />
              </div>
            </div>
            <div className={style.dashboard_chart}>
              <div className={style.dashboard_piechart}>
                <div
                  className={style.dashboard_barchart_head}
                  style={{ marginBottom: "15px" }}
                >
                  Product Material Type
                </div>
                <Doughnut
                  data={dataChart}
                  options={{
                    elements: {
                      center: {
                        legend: { display: true, position: "left" },
                        text: "Red is 2/3 the total numbers",
                        color: "#FF6384", // Default is #000000
                        fontStyle: "Arial", // Default is Arial
                        sidePadding: 20, // Default is 20 (as a percentage)
                        minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
                        lineHeight: 25, // Default is 25 (in px), used for when text wraps
                      },
                    },
                  }}
                />
              </div>
              <div className={style.dashboard_barchart}>
                <div className={style.dashboard_barchart_head}>
                  Orders and Appointments, Testimonials Summary
                </div>
                <Bar
                  height={"250px"}
                  data={{
                    labels: [
                      "Total Orders",
                      "Total Appointments",
                      "Total Testimonials",
                    ],
                    datasets: [
                      {
                        label: "DATA",
                        backgroundColor: ["#F58320", "#FFB07B", "#FFC59E"],
                        data: [
                          order ? order.length : "NA",
                          appointment ? appointment.length : "NA",
                          testimonial ? testimonial.length : "NA",
                        ],
                      },
                    ],
                  }}
                  //   options={{
                  //     legend: { display: false },
                  //     title: { display: true, text: `Current state in 234234` }
                  //   }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};
export default Dashboard;
