import React,{useState} from "react"
import style from "./otp.module.css"
import Close from "../../assets/smallClose.png"
import Button from "../button/Button"
import SmallLoader from "../smallLoader/SmallLoader"

const Otp =({otpEmail,loading,chnageOtpHnadler,closeOtpPopup,first,second,thired,four,five,resendHnadler,otpSendHandler})=>{

    return(
        <>
        <div className={style.otp_section_modl}>
         <div className={style.otp_section_modl_container}>
          <div className={style.otp_section_modl_close}>
            <img src={Close} alt='close image' onClick={closeOtpPopup}/>
          </div>
          <div className={style.otp_section_modl_title}>Enter Verification Code</div>
          <div className={style.otp_section_modl_email}>We’ve send a code to <span>{otpEmail}</span></div>
          <div className={style.otp_section_modl_input}>
            <input type='text' name='first' value={first} onChange={chnageOtpHnadler} style={{border:first?'1px solid #000':''}}/>
            <input type='text' name='second' value={second} onChange={chnageOtpHnadler} style={{border:second?'1px solid #000':''}}/>
            <input type='text' name='thired' value={thired} onChange={chnageOtpHnadler} style={{border:thired?'1px solid #000':''}}/>
            <input type='text' name='four' value={four} onChange={chnageOtpHnadler} style={{border:four?'1px solid #000':''}}/>
            <input type='text' name='five' value={five} onChange={chnageOtpHnadler} style={{border:five?'1px solid #000':''}}/>
          </div>
          <div className={style.otp_section_modl_resend}>Didn’t get a Code? <span onClick={resendHnadler}>Click to resend.</span></div>
          <Button submitHandler={otpSendHandler} name={loading?<SmallLoader/>:'Verify'} id={style.buttonId_otp}/>
         </div>
        </div>
        </>
    )
}
export default Otp;