import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {newslettersList,newslettersAdd,newslettersDelete,newslettersStatusChange,newslettersSendMail}=API

const initialState = { 
    data:[],
    singleData:[],
    error: null, 
    loading:false,
    message:'',
    deleteLoad:false,
    statusLoader:false
}

const newslettersSlice = createSlice({
    name: "newsletters",
    initialState,
    reducers: {
      setData(state,action){
          state.data=action.payload;
      },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
    },
      setDeleteLoad(state,action){
          state.deleteLoad=action.payload
      },
      setAfterDeleteNewsletters(state,action){
          state.data=state.data.filter((item)=>item._id !== action.payload)
      },
      setNewslettersAdd(state,action){
          state.data=[action.payload,...state.data]
      },
      setStatusLoading(state,action){
        state.statusLoader=action.payload
    },
    setChnageStatus(state,action){
      state.singleData=state.data.filter((item)=>item._id == action.payload)
      state.singleData[0].status=state.singleData[0].status===1?0:state.singleData[0].status===0?1:''
    }
    },
  });
  
  export const {setStatusLoading,setChnageStatus, setLoading, setMessage,setError,setData,setDeleteLoad,setNewslettersAdd,setAfterDeleteNewsletters,} = newslettersSlice.actions;
  export default newslettersSlice.reducer;

  export const getNewslettersData = () => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await newslettersList()
      if (res.data.success) {
          dispatch(setData(res.data.data))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };
  export const newslettersDataAdded=(data) => async (dispatch)=>{
    dispatch(setDeleteLoad(true));
    try{
      const res= await newslettersAdd(data)
      if(res.data.success) {
         dispatch(setNewslettersAdd(data))
         dispatch(setDeleteLoad(false))
         dispatch(setMessage(res.data.message))
         message.success(res.data?.message);
      } else {
        dispatch(setDeleteLoad(false));
        dispatch(setError(res.data.message));
        message.error(res.data.message);
    }
    } catch(e){
      dispatch(setDeleteLoad(false));
      dispatch(setError(e.response.data.message));
      message.error(e.response.data.message);
    }
}
export const newslettersMailSendAction=(data) => async (dispatch)=>{
  dispatch(setDeleteLoad(true));
  try{
    const res= await newslettersSendMail(data)
    if(res.data.success) {
       dispatch(setDeleteLoad(false))
       dispatch(setMessage(res.data.message))
       message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
  }
  } catch(e){
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
}
export const messageBlankAction=()=>(dispatch)=>{
  dispatch(setMessage())
}
 export const newslettersDataDelete=(id) => async (dispatch)=>{
          dispatch(setDeleteLoad(true));
          try{
            const res= await newslettersDelete(id)
            if(res.data.success) {
               dispatch(setAfterDeleteNewsletters(id))
               dispatch(setDeleteLoad(false))
               dispatch(setMessage(res.data.message))
               message.success(res.data?.message);
            } else {
              dispatch(setDeleteLoad(false));
              dispatch(setError(res.data.message));
              message.error(res.data.message);
          }
          } catch(e){
            dispatch(setDeleteLoad(false));
            dispatch(setError(e.response.data.message));
            message.error(e.response.data.message);
          }
  }

  export const statusChangeAction = (id) => async (dispatch) => {
    dispatch(setStatusLoading(true));
   try {
   const res= await newslettersStatusChange(id)
    if (res.data.success) {
     dispatch(setChnageStatus(id))
     dispatch(setStatusLoading(false));
     dispatch(setMessage(res.data.message))
     message.success(res.data?.message);
} else {
   dispatch(setStatusLoading(false));
   dispatch(setError(res.data.message));
}
} catch (e){
dispatch(setStatusLoading(false));
dispatch(setError(e.response.data.message));
message.error(e.response.data.message);
}
};