import React, { useState, useEffect } from "react";
import style from "./auth.module.css";
import close from "../../assets/icons8-close-24.png";
import TrelifLogo from "../../assets/normalheaderlogo.png";
// import Input from '../../components/inputSection/InputSection'
import uncheck from "../../assets/check-box-d.svg";
import checkbox from "../../assets/check-box-s.svg";
import Buttons from "../../components/button/Button";
import Border from "../../components/border/Border";
import {
  authSignup,
  authUserVerify,
  authLogin,
} from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import SmallLoader from "../../components/smallLoader/SmallLoader";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import BackgroundImages from "../../assets/authbackground.webp";
import LoginImage from "../../assets/loginPageImage.jpg";
// import Logo from "../../assets/logoNew.png"
import Logo from "../../assets/trelif-logo-black.svg";
import {
  Form,
  Input,
  Radio,
  Select,
  DatePicker,
  Row,
  Spin,
  Upload,
  Col,
  Button,
  AutoComplete,
  InputNumber,
  message,
  Divider,
  TimePicker,
} from "antd";
import Otp from "../../components/otp/Otp";

const Auth = () => {
  const token = localStorage.getItem("token");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Error = useSelector((state) => state.auth.error);
  const loading = useSelector((state) => state.auth.loading);
  const message = useSelector((state) => state.auth.message);
  const userId = useSelector((state) => state.auth.userId);

  const [state, setState] = useState({
    first: "",
    second: "",
    thired: "",
    four: "",
    five: "",
  });
  const [userIds, setUserIds] = useState();
  const [change, setChange] = useState(true);
  const [check, setCheck] = useState(false);
  const [pop, setPop] = useState(false);
  const [otpEmail, setOtpEmail] = useState();

  const changeComp = () => {
    setChange(!change);
  };
  const onChangeDate = () => {};
  const submitHandler = () => {};
  const googleHandler = () => {};
  const onFinish = (values) => {
    values["date"] = moment(values.date).format("MM-DD-YYYY");
    setOtpEmail(values.email);
    if (change) {
      dispatch(authLogin({ email: values.email, password: values.password }));
    } else {
      dispatch(authSignup(values));
    }
  };
  useEffect(() => {
    if (message === "Your Trelif Sign-up OTP Has Been Send To Your Email!") {
      return setPop(true);
    }
  }, [message]);
  useEffect(() => {
    if (message === "User register successfully!") {
      setPop(false);
      form.resetFields();
    }
  }, [message]);
  //  useEffect(()=>{
  // if(message==='login successful'){
  //   navigate("/dashboard");
  // }
  //  },[message])
  useEffect(() => {
    setUserIds(userId);
  }, [userId]);
  const onFinishFailed = (errorInfo) => {};

  const closeOtpPopup = () => {
    setPop(false);
  };
  const resendHnadler = () => {};
  const otpSendHandler = () => {
    let otpData = [
      state.first,
      state.second,
      state.thired,
      state.four,
      state.five,
    ];
    const data = { otp: otpData.join(""), userId: userIds };
    dispatch(authUserVerify(data));
  };
  const chnageOtpHnadler = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setState({ ...state, [name]: value });
  };
  // Disable future dates
  const disabledDate = (currentDate) => {
    return currentDate > new Date();
  };

  const filterNonAlphabetical = (inputValue) => {
    return inputValue.replace(/[^a-zA-Z]/g, "");
  };
  const handleFirstNameChange = (e) => {
    const filteredValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ firstName: filteredValue });
  };

  const handleLastNameChange = (e) => {
    const filteredValue = filterNonAlphabetical(e.target.value);
    form.setFieldsValue({ lastName: filteredValue });
  };

  return token ? (
    navigate("/dashboard")
  ) : (
    <>
      <div className={style.auths_section}>
        <div className={style.auths_left_section}>
          <img src={LoginImage} alt="login image" />
          <div className={style.auths_left_section_text}>
            Welcome to <div>Trelif</div>
          </div>
        </div>
        <div
          className={style.auth_section}
          style={{ height: change ? "550px" : "" }}
        >
          <div className={style.auth_trelif_logo}>
            <img src={Logo} alt="close image" />
          </div>
          <div className={style.auth_text_top}>
            <h3>
              <span>Welcome</span> to Trelif !
            </h3>
            <p>Create an account to unlock the world of luxury furniture</p>
          </div>
          <div
            className={style.auth_input_field}
            style={{ height: change ? "250px" : "" }}
          >
            <Form
              className={style.admin_auth_form}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout={"vertical"}
              form={form}
              style={{
                maxWidth: "100%",
              }}
            >
              {change ? (
                ""
              ) : (
                <div className={style.auth_section_start_tw}>
                  <Form.Item
                    className={style.admin_auth_input}
                    style={{ width: "48%" }}
                    // label="Email"
                    label={<label style={{ color: "#fff" }}>First Name</label>}
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your first name"
                      onChange={handleFirstNameChange}
                    />
                  </Form.Item>
                  <Form.Item
                    className={style.admin_auth_input}
                    style={{ width: "48%" }}
                    // label="Email"
                    label={<label style={{ color: "#fff" }}>Last Name</label>}
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your last name"
                      onChange={handleLastNameChange}
                    />
                  </Form.Item>
                </div>
              )}
              {change ? (
                ""
              ) : (
                <div className={style.auth_section_start_tw}>
                  <Form.Item
                    className={style.admin_auth_input}
                    style={{ width: "48%" }}
                    // label="Email"
                    label={
                      <label style={{ color: "#fff" }}>Mobile Number</label>
                    }
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input your mobile number!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your mobile number" />
                  </Form.Item>
                  <Form.Item
                    className={style.admin_auth_input}
                    style={{ width: "48%" }}
                    // label="Email"
                    label={
                      <label style={{ color: "#fff" }}>Date of Birth</label>
                    }
                    name="dob"
                    rules={[
                      {
                        required: true,
                        message: "Please input your date of birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      onChange={onChangeDate}
                      disabledDate={disabledDate}
                      size="large"
                      style={{
                        width: "100%",
                        border: "1px solid #3E3636",
                        padding: "11px 7px",
                        marginTop: "-0px",
                      }}
                      format={"MM/DD/YYYY"}
                    />
                  </Form.Item>
                </div>
              )}
              <Form.Item
                className={style.admin_auth_input}
                style={{ width: "100%", color: "white" }}
                // label="Email"
                label={<label style={{ color: "#000" }}>Email</label>}
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
              <Form.Item
                className={style.admin_auth_input}
                style={{ width: "100%" }}
                label={<label style={{ color: "#000" }}>Password</label>}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input type="password" placeholder="Enter your password" />
              </Form.Item>
              {change ? (
                ""
              ) : (
                <Form.Item
                  className={style.admin_auth_input}
                  style={{ width: "100%" }}
                  label={
                    <label style={{ color: "#fff" }}>Confirm Password</label>
                  }
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your confirm password!",
                    },
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Enter your confirm password"
                  />
                </Form.Item>
              )}

              <Form.Item className={style.button_sections_ant}>
                <Button
                  // type="primary"
                  htmlType="submit"
                  disabled={loading ? "disabled" : ""}
                >
                  {loading ? (
                    <SmallLoader />
                  ) : change ? (
                    "sign in"
                  ) : loading ? (
                    <SmallLoader />
                  ) : (
                    "sign up"
                  )}
                </Button>
              </Form.Item>
            </Form>
            {pop ? (
              <Otp
                otpEmail={otpEmail}
                loading={loading}
                otpSendHandler={otpSendHandler}
                resendHnadler={resendHnadler}
                chnageOtpHnadler={chnageOtpHnadler}
                closeOtpPopup={closeOtpPopup}
                first={state.first}
                second={state.second}
                thired={state.thired}
                four={state.four}
                five={state.five}
              />
            ) : (
              ""
            )}
          </div>

          {/* <div className={style.auth_checkbox} style={{justifyContent:change?'flex-end':''}}>
         {change?'':
         check?
         <img src={checkbox} alt='form checkbox image' onClick={()=>setCheck(!check)}/>
         :
         <img src={uncheck} alt='form checkbox image' onClick={()=>setCheck(!check)}/>
         }
        {change?<p className={style.forgot_pass}>forgot password?</p>:<p>I agree to the Terms & Conditions<span>*</span></p>}
        {change?'':<p>I agree to the Terms & Conditions<span>*</span></p>}
    </div> */}

          {/* <Border name={change ? "Or Sign In with" : "Or Sign Up with"} />
          <div className={style.button_sections}>
            <Buttons
              img="true"
              id={style.buttonId_google}
              submitHandler={googleHandler}
              name="google"
            />
          </div> */}
          {/* <div className={style.login_footer}>
            {change ? "Don’t have an account?" : "Already have an account?"}{" "}
            <span onClick={changeComp}>{change ? "sign up" : "sign in"}</span>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default Auth;
