import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {dashboardTotalDetails}=API

const initialState = { 
    detailsData:[],
    error: null, 
    loading:false,
    message:''
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
      setDetailsData(state,action){
          state.detailsData=action.payload;
      },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
    }
    },
  });
  
  export const { setLoading, setMessage,setError,setDetailsData} = dashboardSlice.actions;
  export default dashboardSlice.reducer;

  export const getDetailsData = () => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await dashboardTotalDetails()
      if (res.data.success) {
          dispatch(setDetailsData(res.data.data))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };