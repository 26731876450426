import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  data: "",
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setData } = settingsSlice.actions;
export default settingsSlice.reducer;

export const activeManageAction = (val) => (dispatch) => {
  dispatch(setData(val));
};
