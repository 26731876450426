import  {post,get,_delete,put,formData, clientId}  from '../TrelifAdmin';

const projectSliderList = () => {
  return get('/settings/projectslider');
};
const projectSliderAdd = (data) => {
  return post('/settings/projectslider',data);
};
const projectSliderUpdate = (id,data) => {
  return put(`/settings/projectslider/${id}`,data);
};
const projectSliderDelete=(id)=>{
  return _delete(`/settings/projectslider/${id}`)
}
const projectSliderEdit=(id,field)=>{
  return get(`/settings/specific-settings/?id=${id}&fieldName=${field}`)
}

export default {
    projectSliderList,
    projectSliderAdd,
    projectSliderUpdate,
    projectSliderDelete,
    projectSliderEdit
};