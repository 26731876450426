import React, { useEffect } from "react";
import style from "./header.module.css";
// import Logo from "../../assets/logoNew.png";
import Logo from "../../assets/trelif-logo-black.svg";
import Hamburger from "../../assets/icons8-hamburger-50.png";
import Input from "../input/Input";
import Logout from "../../assets/logout.svg";
import { authLogout } from "../../store/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import ChangelLoader from "../changelLoader/ChangelLoader";
import { useNavigate } from "react-router-dom";

const Header = ({ hammerHnadler, value, changeHandler }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const logoutHandler = () => {
    dispatch(authLogout());
  };
  useEffect(() => {
    if (!token) {
      return navigate("/");
    }
  }, [token]);
  return (
    <>
      <div className={style.header_section}>
        <div className={style.header_left}>
          <div className={style.logoImageContainer}>
            <img src={Logo} alt="logo image" />
          </div>
          <img
            onClick={hammerHnadler}
            src={Hamburger}
            alt="Hamburger image"
            style={{ height: "25px", marginTop: "5px", cursor: "pointer" }}
          />
        </div>
        <div className={style.header_right}>
          {/* <div className={style.header_search}>
                <Input access={false} type='text' name='search' value={value} changeHandler={changeHandler} placeholder='Please Search Here...'/>
                <div className={style.header_search_text}>search</div>
            </div> */}
          <div className={style.header_profile} onClick={logoutHandler}>
            {loading ? (
              <ChangelLoader />
            ) : (
              <>
                <img src={Logout} alt="logout image" />
                <div>Sign out</div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
