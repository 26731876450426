import React,{useState} from "react"
import style from "./pageHeader.module.css"
import WhiteAdd from "../../assets/whiteAdd.png"

const PageHeader=({name,btnName,img,addHandler,id,addcustomerHandler,image,nameBtn,nextButton})=>{
   const [add,setAdd]=useState(false)
   const [addd,setAddd]=useState(false)
   const enterHandler=()=>{
         setAdd(true)
   }
   const leaveHandler=()=>{
         setAdd(false)
   }
   const enterHandlerd=()=>{
    setAddd(true)
}
const leaveHandlerd=()=>{
    setAddd(false)
}
    return(
        <>
        <div className={style.PageHeader_section}>
            <div>{name}</div>
            {btnName?
            <div className={style.page_header_newbtn}>
            {nextButton?
            <div className={style.customer_add_new} onClick={addcustomerHandler} onMouseEnter={enterHandler} onMouseLeave={leaveHandler}>
             <img src={add?image:WhiteAdd} alt="customer add image"/>
              <div>{nameBtn}</div>
             </div>
             :''}
            <div className={style.add_section_btn} onClick={addHandler} id={id} onMouseEnter={enterHandlerd} onMouseLeave={leaveHandlerd}>
                <img src={addd?img:WhiteAdd}  alt='add icon image'/>
                <div>{btnName}</div>
            </div>
            </div>
            :""}
        </div>
        </>
    )
}
export default PageHeader;