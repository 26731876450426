import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api"
const {uspList,uspAdd,uspUpdate,uspDelete,uspEdit}=API

const initialState = { 
    data:[],
    singleUsp:[],
    error: null, 
    loading:false,
    message:'',
    deleteLoad:false,
    editData:[]
}

const uspSlice = createSlice({
    name: "usp",
    initialState,
    reducers: {
      setData(state,action){
          state.data=action.payload;
      },
      setLoading(state, action) {
          state.loading = action.payload;
        },
      setMessage(state,action){
          state.message=action.payload;
      },
      setError(state,action){
          state.error=action.payload;
    },
      setDeleteLoad(state,action){
          state.deleteLoad=action.payload
      },
      setAfterDeleteUsp(state,action){
          state.data=state.data.filter((item)=>item._id !== action.payload)
      },
      setUspAdd(state,action){
          state.data=[action.payload,...state.data]
      },
      setEditUsp(state,action){
          state.singleUsp=state.data.filter((item)=>item._id === action.payload)
      },
      setBlankSingleData(state,action){
        state.singleUsp=[]
      },
      setEditData(state,action){
          state.editData=action.payload
      }
    },
  });
  
  export const {setEditData, setLoading, setMessage,setError,setData,setDeleteLoad,setUspAdd,setAfterDeleteUsp,setEditUsp,setBlankSingleData} = uspSlice.actions;
  export default uspSlice.reducer;

  export const getUspData = () => async (dispatch) => {
         dispatch(setLoading(true));
    try {
      const res= await uspList()
      if (res.data.success) {
          dispatch(setData(res.data.data[0]?.fieldvalue))
          dispatch(setLoading(false));
          dispatch(setMessage(res.data.message))
      } else {
          dispatch(setLoading(false));
          dispatch(setError(res.data.message));
      }
    } catch (e){
      dispatch(setLoading(false));
      dispatch(setError(e.message));
      message.error(e.message);
    }
  };
  export const uspDataAdded=(data) => async (dispatch)=>{
    dispatch(setDeleteLoad(true));
    try{
      const res= await uspAdd(data)
      if(res.data.success) {
         dispatch(setUspAdd(data))
         dispatch(setDeleteLoad(false))
         dispatch(setMessage(res.data.message))
         message.success(res.data?.message);
      } else {
        dispatch(setDeleteLoad(false));
        dispatch(setError(res.data.message));
        message.error(res.data.message);
    }
    } catch(e){
      dispatch(setDeleteLoad(false));
      dispatch(setError(e.response.data.message));
      message.error(e.response.data.message);
    }
}

export const sigleUspDataAction=(id) =>(dispatch)=>{
              dispatch(setEditUsp(id))
}
export const blankDataAction=() =>(dispatch)=>{
             dispatch(setBlankSingleData())
}
export const uspDataUpdate=(id,data) => async (dispatch)=>{
  dispatch(setDeleteLoad(true));
  try{
    const res= await uspUpdate(id,data)
    if(res.data.success) {
       dispatch(setBlankSingleData())
       dispatch(setEditData())
       dispatch(setDeleteLoad(false))
       dispatch(setMessage(res.data.message))
       message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
  }
  } catch(e){
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
}
export const uspEditAction=(id,field) => async (dispatch)=>{
  try{
    const res= await uspEdit(id,field)
    if(res.data.success) {
       dispatch(setEditData(res.data.data))
    } else {
      message.error(res.data.message);
  }
  } catch(e){
    message.error(e.response.data.message);
  }
}
export const editBlankData=() =>(dispatch)=>{
        dispatch(setEditData())
}
  export const uspDataDelete=(id) => async (dispatch)=>{
          dispatch(setDeleteLoad(true));
          try{
            const res= await uspDelete(id)
            if(res.data.success) {
               dispatch(setAfterDeleteUsp(id))
               dispatch(setDeleteLoad(false))
               dispatch(setMessage(res.data.message))
               message.success(res.data?.message);
            } else {
              dispatch(setDeleteLoad(false));
              dispatch(setError(res.data.message));
              message.error(res.data.message);
          }
          } catch(e){
            dispatch(setDeleteLoad(false));
            dispatch(setError(e.response.data.message));
            message.error(e.response.data.message);
          }
  }