import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import API from "../../api";
const { uploadImage, uploadVideo } = API;

const initialState = {
  data: [],
  error: null,
  message: "",
  deleteLoad: false,
  temporaryData: [],
};

const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setDeleteLoad(state, action) {
      state.deleteLoad = action.payload;
    },
    setTemporaryData(state, action) {
      state.temporaryData = action.payload;
    },
  },
});

export const {
  setMessage,
  setError,
  setData,
  setDeleteLoad,
  setTemporaryData,
} = uploadSlice.actions;
export default uploadSlice.reducer;

export const imageUploadDataAction = (data) => (dispatch) => {
  dispatch(setTemporaryData(data));
};

export const uploadVideoDataAction = (data) => async (dispatch) => {
  dispatch(setDeleteLoad(true));
  try {
    const res = await uploadVideo(data);
    if (res.data.success) {
      dispatch(setData(res.data.data));
      dispatch(setDeleteLoad(false));
      dispatch(setMessage(res.data.message));
      message.success(res.data?.message);
    } else {
      dispatch(setDeleteLoad(false));
      dispatch(setError(res.data.message));
      message.error(res.data.message);
    }
  } catch (e) {
    dispatch(setDeleteLoad(false));
    dispatch(setError(e.response.data.message));
    message.error(e.response.data.message);
  }
};
